import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Patient {
  id: string;
  phone_number?: string;
  first_name?: string;
  gender?: string;
  dob?: string;
}

interface PatientsState {
  isPatientAdded: boolean | null;
  isLoading: boolean | null;
  patients: Patient[] | null;
  searchedPatients: Patient[] | null;
  selectedPatientId: string | null;
  mobileNumber: string | null;
  firstName: string | null;
  gender: string | null;
  dob: string | null;
}

// Initial state with type annotations
const initialState: PatientsState = {
  isPatientAdded: null,
  isLoading: null,
  patients: null,
  searchedPatients: null,
  selectedPatientId: null,
  mobileNumber: null,
  firstName: null,
  gender: null,
  dob: null,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setSelectedPatient: (state, action: PayloadAction<Patient>) => {
      state.selectedPatientId = action.payload?.id || "";
      state.mobileNumber = action.payload?.phone_number || "";
      state.firstName = action.payload?.first_name || "";
      state.gender = action.payload?.gender || "";
      state.dob = action.payload?.dob || "";
    },
  },
});

export const patientSelector = (state: { patient: PatientsState }) => state.patient;
export const { setSelectedPatient } = patientSlice.actions;
export default patientSlice.reducer;
