import { useEffect ,useState} from "react";
import { Outlet } from "react-router-dom";
import FixedFooter from "../../structure/FixedFooter";
import FixedHeader from "../../structure/FixedHeader";
import { MainBody, ParentContainer } from "./style.components";
import SideNav from "../../common/SideNavBar/SideNavBar";

const PatientRoot = () => {
  return (
    <>
      <FixedHeader />
      <ParentContainer>
        <SideNav />
        <MainBody>
          <Outlet />
        </MainBody>
      </ParentContainer>
    </>
  );
};

export default PatientRoot;
