import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { VerticalSep } from "../components/styled/Gaps";
import EmailIcons from "../assets/email.svg";
import PhoneIcons from "../assets/phone.svg";
import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";

const HelpHeader = styled.div`
  margin-bottom: 26px;
  font-size: 24px;
  color: #0099cc;
  font-weight: 600;
`;

const HelpContainer = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 48px;
`;

const HelpSubHeader = styled.div`
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 48px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const CardData = styled.div`
  padding: 20px 24px 40px;
`;
const CardTitle = styled.div`
  background-color: #0099cc;
  color: #fff;
  padding: 12px 24px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 12px 12px 0px 0px;
`;

const ContactInfo = styled.div`
  border-radius: 12px;
  background-color: #ebf7fb;
  width: 624px;
  height: 224px;
`;

const ContactName = styled.div`
  font-size: 20px;
  color: #0099cc;
  margin-bottom: 20px;
`;

const ContactEmail = styled.div`
  font-size: 16px;
  color: #808080;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    margin-right: 10px;
    width: 16px;
  }
`;

const ContactNumber = styled.div`
  font-size: 16px;
  color: #808080;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 16px;
  }
`;

const Help = () => {
  return (
    <>
      <HelpHeader>Help</HelpHeader>
      <HelpContainer>
        <HelpSubHeader>
          For any enquiries or feedback, please do reach us.
        </HelpSubHeader>
        <ContentContainer>
          <ContactInfo>
            <CardTitle>Gujarat Region</CardTitle>
            <CardData>
              <ContactName>Mr. Sameer Dhanani</ContactName>
              <ContactEmail>
                <img src={EmailIcons} />
                sameer.dhanani@hemehealth.io
              </ContactEmail>
              <ContactNumber>
                <img src={PhoneIcons} />
                +91 9016318821
              </ContactNumber>
            </CardData>
          </ContactInfo>
          {/* <ContactInfo>
                    <CardTitle>Maharashtra & Karnataka region</CardTitle>
                   <CardData>
                   <ContactName>Mr. Shivam Rathi</ContactName>
                    <ContactEmail><img src={EmailIcons}/>shivam@hemehealth.io</ContactEmail>
                    <ContactNumber><img src={PhoneIcons}/>+91 1234567890</ContactNumber>
                   </CardData>
                </ContactInfo> */}
          <ContactInfo>
            <CardTitle>Mumbai Region</CardTitle>
            <CardData>
              <ContactName>Mr. Tejas Kulkarni</ContactName>
              <ContactEmail>
                <img src={EmailIcons} />
                tejas.kulkarni@hemehealth.io
              </ContactEmail>
              <ContactNumber>
                <img src={PhoneIcons} />
                +91 9920783648
              </ContactNumber>
            </CardData>
          </ContactInfo>
          <ContactInfo>
            <CardTitle>Rajasthan Region</CardTitle>
            <CardData>
              <ContactName>Devraj Singh Bhati</ContactName>
              <ContactEmail>
                <img src={EmailIcons} />
                devraj.bhati@hemehealth.io
              </ContactEmail>
              <ContactNumber>
                <img src={PhoneIcons} />
                +91 9588074132
              </ContactNumber>
            </CardData>
          </ContactInfo>
          <ContactInfo>
            <CardTitle>Delhi-NCR Region</CardTitle>
            <div
              style={{
                display: "flex",
              }}
            >
              <CardData>
                <ContactName>Pranav Prakash</ContactName>
                <ContactEmail>
                  <img src={EmailIcons} />
                  pranav.prakash@hemehealth.io
                </ContactEmail>
                <ContactNumber>
                  <img src={PhoneIcons} />
                  +91 8527909098
                </ContactNumber>
              </CardData>
              <CardData>
                <ContactName>Raghav Sharma</ContactName>
                <ContactEmail>
                  <img src={EmailIcons} />
                  raghav.sharma@hemehealth.io
                </ContactEmail>
                <ContactNumber>
                  <img src={PhoneIcons} />
                  +91 8881688205
                </ContactNumber>
              </CardData>
            </div>
          </ContactInfo>



          <ContactInfo>
            <CardTitle> Tamil Nadu Region</CardTitle>
            <div
              style={{
                display: "flex",
              }}
            >
              <CardData>
                <ContactName>Manikandan Lakshmanan</ContactName>
                <ContactEmail>
                  <img src={EmailIcons} />
manikandan.l@hemehealth.io
                </ContactEmail>
                <ContactNumber>
                  <img src={PhoneIcons} />
                  +91-9043433831
                </ContactNumber>
              </CardData>
             
            </div>
          </ContactInfo>

          <ContactInfo>
            <CardTitle> Madhya Pradesh Region</CardTitle>
            <div
              style={{
                display: "flex",
              }}
            >
              <CardData>
                <ContactName>Madhumangal Joshi</ContactName>
                <ContactEmail>
                  <img src={EmailIcons} />
                  madhumangal.joshi@hemehealth.io
                </ContactEmail>
                <ContactNumber>
                  <img src={PhoneIcons} />+91-9691806405
                </ContactNumber>
              </CardData>
             
            </div>
          </ContactInfo>

          <ContactInfo>
            <CardTitle> West Bengal Region</CardTitle>
            <div
              style={{
                display: "flex",
              }}
            >
              <CardData>
                <ContactName>Anupam Banerjee</ContactName>
                <ContactEmail>
                  <img src={EmailIcons} />
                  anupam.banerjee@hemehealth.io
                </ContactEmail>
                <ContactNumber>
                  <img src={PhoneIcons} />+91-8240767807
                </ContactNumber>
              </CardData>
             
            </div>
          </ContactInfo>
        </ContentContainer>


        <ContentContainer></ContentContainer>
      </HelpContainer>
    </>
  );
};

export default Help;
