import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import NoData from "../components/common/UserMessages/NoData";
import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";
import ISearch from "../assets/search-icon.svg";
import { ShimmerTable } from "react-shimmer-effects";
import IProfileImageSmall from "../assets/profile-image-small.svg";

const Container = styled.div`
  flex-grow: 1;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
  gap: 188px;
  justify-content: flex-start;
`;
const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
`;
const SearchInput = styled.input`
  font-size: 16px;
  outline: none;
  flex: 1;
  background: transparent;
  color: #808080;
  border: none;
`;
const HeaderLeft = styled.div`
`;
const HeaderRight = styled.div`
  display: flex;
  gap: 100px;
  justify-content: space-between;
  align-items: center;
`;
const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
const SearchImage = styled.img`
  width: 25px;
  height: 25px;
`;
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #C5DDE0;
  padding: 10px 20px;
  width: 658px;
  height: 22px;
`;
const DoctorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;
const DoctorCard = styled.div`
  flex: 1 1 calc(20% - 16px);
  max-width: 284px;
  height: 57px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
`;
const PhoneNumber = styled.div`
  font-size: 14px !important;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px
`;

const EmailText = styled.div`
  font-size: 14px !important;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px
`;


const Name = styled.div`
  font-size: 16px;
  color: #0099CC;
  margin-bottom: 4px;
 overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    white-space: nowrap;
`;
const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;
`;
export const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
`;
const EmptyListMessage = styled.div`
  margin-top: 100px;
  color: red;
  display: flex;
  justify-content: center;
`;
export const DoctorInfo = styled.div`
`;

const Doctors = () => {
  const [cookies] = useCookies();
  const [doctorsData, setDoctorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const { patients, searchedPatient } = useSelector();

  useEffect(() => {
    fetchDoctorsData();
  }, []);

  const fetchDoctorsData = () => {
    setIsLoading(true)

    const token = cookies["access_token"];
    const url = `${API_URL}/search/practitioner`;

    const payload = {};
    axios
      .post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.result 
        ) {
          setDoctorsData(response?.data?.result);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Container>
        <HeaderWrapper>
          <HeaderLeft>
            <Header>{`Doctors Directory ${doctorsData?.length ? "("+doctorsData?.length+")":''}`}</Header>
          </HeaderLeft>
          {/* <HeaderRight>
            <SearchWrapper>
              <SearchInput
                placeholder="Search doctor by name or mobile number"
              />
              <SearchButton>
                <SearchImage alt="search-image" src={ISearch} />
              </SearchButton>
            </SearchWrapper>
          </HeaderRight> */}
        </HeaderWrapper>
        {isLoading ? (
        <ShimmerTable row={5} />
      ) : (
        <>
        {doctorsData?.length > 0 ? (
          <DoctorContainer>
            {doctorsData.map(doctor => {
              const { id, name, phone,email} = doctor;
              return (
                <DoctorCard key={id}>
                  <ProfileSection>
                    <ProfileImage src={IProfileImageSmall} alt="ProfileImage" />
                    <DoctorInfo>
                      <Name>{name}</Name>
                      <PhoneNumber>{phone}</PhoneNumber>
                      <EmailText>{email}</EmailText>

                    </DoctorInfo>
                  </ProfileSection>
                </DoctorCard>
              );
            })}
          </DoctorContainer>
        ) : (
          <EmptyListMessage>No doctors data found</EmptyListMessage>
        )}
      </>
      
      )}
      </Container>
    </>
  );
};

export default Doctors;
