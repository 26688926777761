import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
  count: 0
}


const reportsReloadSlice = createSlice({
  name: 'reportsReload',
  initialState,
  reducers: {
    incrementCount: (state) => {
      state.count = state.count + 1;
    },
  },
});

export const { incrementCount } = reportsReloadSlice.actions;

export default reportsReloadSlice.reducer;
