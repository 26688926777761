import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL, COLOR_LINK } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import SimpleLoader from "../Loaders/SimpleLoader";
import { useDispatch } from "react-redux";
import { incrementCount } from "../../../store/slice/reportsReloadSlice";
import { setShowStatus } from "../../../store/slice/statusSlice";
import redCrossIcon from "../../../assets/red-cross-icon.svg";
import viewIcon from "../../../assets/view-icon.svg";
import { useSearchParams } from "react-router-dom";

export interface ReplaceReportProps {
  open: boolean;
  reportId: string | null;
  closeHandler: () => void;
}

const ReplaceReport = ({
  open,
  closeHandler,
  reportId,
}: ReplaceReportProps) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [cookies] = useCookies();
  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("search", searchParams.get("search"));
    console.log("page", searchParams.get("page"));

    return () => {};
  }, [searchParams]);

  const handleReplaceFile = () => {
    if (!reportId) {
      return;
    }
    if (!filesSelected) {
      return;
    }
    setFilesSelected([]);
    setIsLoading(true);
    var formData = new FormData();
    formData.append("file_id", reportId);
    // formData.append("file", URL.createObjectURL(filesSelected[0]));
    formData.append("file", filesSelected[0]);

    axios
      .post(`${API_URL}/lab_portal/reports/replace`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);

        dispatch(
          setShowStatus({
            message: "Report replaced successfully",
            severity: "success",
          })
        );
        closeHandler();

        if (!searchParams.get("page") && !searchParams.get("search")) {
          dispatch(incrementCount());
        } else if (
          searchParams.get("page") === "1" &&
          !searchParams.get("search")
        ) {
          dispatch(incrementCount());
        } else {
          setSearchParams((searchParams) => ({ page: "1", search: "" }));
        }
      })

      .catch((err) => {
        dispatch(
          setShowStatus({
            message: "Report replace failed",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnPreview = () => {
    searchParams.append("viewReportLocal", "true");
    searchParams.append("reportBlobUrl", URL.createObjectURL(filesSelected[0]));
    searchParams.append("reportName", filesSelected[0].name);
    setSearchParams(searchParams);

    // window.open(
    //   `/home?viewReportLocal=true&reportBlobUrl=${URL.createObjectURL(
    //     filesSelected[0]
    //   )}&reportName=${filesSelected[0].name}`
    // );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle style={{ justifyContent: "flex-start", color: "#0099CC" }}>
        Replace Report
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <div style={{ height: "150px" }}>
          {!isLoading ? (
            filesSelected.length == 0 ? (
              <UploadFiles
                prefix={"Replace_"}
                displayText="Drag report to replace (.pdf only)"
                singleFile={true}
                filesSelected={filesSelected}
                setFilesSelected={setFilesSelected}
              />
            ) : (
              <div
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      wordBreak: "break-word",
                      color: COLOR_LINK,
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="pointer-cursor custom-link"
                    onClick={() => handleOnPreview()}
                  >
                    {filesSelected.length != 0 ? filesSelected[0]?.name : ""}{" "}
                  </Typography>

                  <img
                    src={viewIcon}
                    style={{ marginLeft: "5px" }}
                    className="pointer-cursor"
                    onClick={() => handleOnPreview()}
                    alt="Girl in a jacket"
                    width="17"
                    height="17"
                  />
                </div>
                <img
                  src={redCrossIcon}
                  className="pointer-cursor"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setFilesSelected([]);
                  }}
                />
              </div>
            )
          ) : (
            <SimpleLoader
              text={"Replacing your report"}
              height="100%"
              size={20}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "flex-start", padding: "0px 20px 20px 20px" }}
      >
        <Button
          onClick={handleReplaceFile}
          disabled={filesSelected.length == 0}
          sx={{ color: "#FFF" }}
          variant="contained"
        >
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplaceReport;
