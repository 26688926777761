import React, { ChangeEvent, FC } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface BreadcrumbLink {
  label: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface BreadcrumbProps {
  links: BreadcrumbLink[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ links }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {links.map((link, index) => {
        const isLast = index === links.length - 1;
        return isLast ? (
          <Typography
            key={`breadcrumb-link-${index}`}
            sx={{ fontSize: "13px" }}
            color="textPrimary"
          >
            {link.label}
          </Typography>
        ) : (
          <Typography
            key={`breadcrumb-link-${index}`}
            color="info.main"
            // href={link?.href}
            onClick={link?.onClick}
            sx={{ textDecoration: "none", fontSize: "13px", cursor: "pointer",marginBottom:'2px'}}
          >
            {link.label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
