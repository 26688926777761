import { FormControl, IconButton, Input, InputAdornment } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import SearchIcon from '@mui/icons-material/Search';
import { DEVICE } from "../../../utilities/constants";
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBoxProps {
  searchHandler: any; // this will the handler function;
  placeholderText?: string;
  searchParamText?: string;
}
const SearchBox: FunctionComponent<SearchBoxProps> = ({
  searchHandler,
  placeholderText,
  searchParamText,
}) => {
  const [searchTerm, setSearchTerm] = useState(searchParamText || "");

  useEffect(() => {
    setSearchTerm(searchParamText ? searchParamText : "");
  }, [searchParamText]);

  return (
    <FormControl sx={{ width: "100%", border: "none" }}>
      <Input
        sx={{
          height: "50px",
          width: "100%",
          backgroundColor: "#FFFFFF",
          paddingLeft: "15px",
          borderRadius: "5px",
          border: "1px solid rgb(197, 221, 224)",
          fontSize: "16px",
          [`@media ${DEVICE.mobileL}`]: {
            fontSize: "13px",
            paddingLeft: "10px",
          },
        }}
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
        disableUnderline={true}
        value={searchTerm}
        placeholder={placeholderText ? placeholderText : "Search"}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            if (searchTerm) {
              searchHandler(searchTerm);
            } else {
              searchHandler("");
            }
            ev.preventDefault();
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            {/* {showClearButton && ( */}
            {searchTerm && <IconButton
              onClick={() => {
                setSearchTerm("");
                searchHandler("");
              }}
              edge="end"
            >
              <ClearIcon />
            </IconButton>}
            {/* )} */}
            <IconButton
              onClick={() => {
                if (searchTerm) {
                  searchHandler(searchTerm);
                } else {
                  searchHandler("");
                }
              }}
            >
              <SearchIcon sx={{ color: "#7aba57", cursor: "pointer" }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchBox;
