import React, { FunctionComponent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

interface CustomDialogLocalProps {
  closeDialog: any;
  itemName: any;
  itemBlobUrl?: any;
}

const CustomDialogLocal: FunctionComponent<CustomDialogLocalProps> = ({
  closeDialog,
  itemName,
  itemBlobUrl,
}) => {
  const [open, setOpen] = useState(true);
  const [fileBlobUrl, setFileBlobUrl] = useState(null);

  const tempNavigator: any = window.navigator;

  useEffect(() => {
    setFileBlobUrl(itemBlobUrl);
  }, []);

  const handleClose = () => {
    closeDialog();
    setOpen(false);
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      console.log("... inside CustomDialogLocal");
      // handleClose();
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={true}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBack
              onClick={handleClose}
              style={{ marginRight: "10px", cursor: "pointer" }}
            />{" "}
            {itemName}
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            {fileBlobUrl ? (
              tempNavigator && tempNavigator.pdfViewerEnabled ? (
                <embed src={fileBlobUrl} width="100%" height="1500px" />
              ) : (
                <iframe src={fileBlobUrl} height="1000px"></iframe>
              )
            ) : (
              "Something went wrong..."
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CustomDialogLocal;
