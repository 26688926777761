import { Box } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import { removeSpecialCharacters } from "../../../utilities/helper";
interface PatientInfoProps {
  recordInfo: any;
}

const InfoName = styled.div`
  font-weight: 600;
`;

const PatientInfo: FC<PatientInfoProps> = ({ recordInfo }) => {
  return (
    <>
      <Box>
        <div>Owner / Patient Name</div>
        <InfoName>{removeSpecialCharacters(recordInfo?.patient_name) || "-"}</InfoName>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box>
          <div>Gender</div>
          <InfoName>{recordInfo?.patient_gender || "-"}</InfoName>
        </Box>
        <Box>
          <div>Age</div>
          <InfoName>{recordInfo?.patient_age || "-"}</InfoName>
        </Box>
      </Box>
    </>
  );
};

export default PatientInfo;
