import { Box } from "@mui/material";
import { FC } from "react";
import { formatTimestamp } from "../../../utilities/helper";
import styled from "styled-components";

interface LabInfoProps {
  recordInfo: any;
}

const InfoName = styled.div`
  font-weight: 600;
`;

const LabInfo: FC<LabInfoProps> = ({ recordInfo }) => {
  return (
    <>
      <Box mt={1}>
        <div>Lab Name / Added by</div>
        <InfoName>{recordInfo?.performer_details?.name || "-"}</InfoName>
      </Box>
      <Box mt={1}>
        <div>Date & Time</div>
        <InfoName>{formatTimestamp(recordInfo?.study_info?.study_date) || "-"}</InfoName>
      </Box>
    </>
  );
};

export default LabInfo;
