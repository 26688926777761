import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useDispatch } from 'react-redux';

import reportsReloadReducer from "./slice/reportsReloadSlice";
import statusReducer from "./slice/statusSlice";
import recordsSlice from "./slice/records.slice";
import patientSlice from "./slice/patient.slice";
import thumbnailsSlice from "./slice/thumbanils.slice";
import autoSendReducer from "./slice/autosendSlice";
import PatientProfileReducer from "./slice/patient-profile.slice";

// Combine reducers
const rootReducer = combineReducers({
  reportsReload: reportsReloadReducer,
  status: statusReducer,
  record: recordsSlice,
  patient: patientSlice,
  thumbnail: thumbnailsSlice,
  autosend: autoSendReducer,
  patientProfile: PatientProfileReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["status"],
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with typed reducers
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;

const useAppDispatch = () => useDispatch<typeof store.dispatch>();

const persistor = persistStore(store);

export { persistor, store, useAppDispatch };
