import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  testName?: string | null | undefined;
  testPrice?: string | null | undefined;
  closeHandler: () => void;
}

const DeleteSure = ({
  open,
  closeHandler,
  testId,
  testName,
  testPrice,
}: EditTestProps) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testNameValue, setTestNameValue] = useState<string>(
    testName ? testName : ""
  );

  const handleSave = () => {
    return;
    if (!testId) {
      return;
    }
    setIsLoading(true);
    var formData = new FormData();
    if (testNameValue) {
      formData.append("test_name", testNameValue);
    }

    axios
      .post(`${API_URL}/lab_portal/reports/replace`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        // setErrorMessage("Something went wrong. Please try again.");
        // setMobileNumber("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ padding: "100px" }}
    >
      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "0px 30px",
        }}
      >
        {testName}
      </DialogTitle>
      <DialogContent
        style={{ justifyContent: "flex-start", margin: "0px 30px" }}
      >
        <div style={{}}>
          {!isLoading ? (
            "Are you sure you would like to delete this test?"
          ) : (
            <SimpleLoader text={"Deleting the test"} height="100%" size={20} />
          )}
        </div>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "0px 30px",
        }}
      >
        <Button onClick={handleSave} sx={{ color: "#FFF" }} variant="contained">
          Delete
        </Button>
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default DeleteSure;
