import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getUploadRecords = async (assembly_id) => {
  try {
    const url = `${API_ENDPOINT.GET_UPLOAD_RECORDS}file_details?assembly_id=${assembly_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const pollRecords = async (assembly_id) => {
  try {
    const url = `${API_ENDPOINT.POLL_RECORDS}?assembly_id=${assembly_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const splitPdf = async ( payload ) => {
  try {
    const url = `${API_ENDPOINT.SPLIT_PDF}`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const sendRecords = async ({ type, payload }) => {
  try {
    let url;
    if(type === 'report') {
      url = `${API_ENDPOINT.SEND_REPORT}`;
    }
    else if(type === 'scans'){
      url = `${API_ENDPOINT.SEND_SCANS}`;
    }
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getConfigs = async () => {
  try {
    const url = `${API_ENDPOINT.CONFIGURATION_LIST}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
  getUploadRecords,
  pollRecords,
  splitPdf,
  sendRecords,
  getConfigs
};
