import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Waypoint } from "react-waypoint";

import {
  FONT_FAMILY_GENERAL,
  COLOR_VARIANT,
  COLOR_PRIMARY_LIGHT,
  COLOR_PRIMARY,
} from "../../../utilities/constants";
import replaceIcon from "../../../assets/replace-icon.png";
import infoIcon from "../../../assets/info.png";
import arrowBtn from "../../../assets/arrow-button.svg";
import viewIcon from "../../../assets/view-icon.svg";
import checkIcon from "../../../assets/check.svg";
import editIcon from "../../../assets/edit-icon.svg";
import deleteIcon from "../../../assets/delete-icon.svg";
import whatsappIcon from "../../../assets/Whatsapp-button-icon.svg";
import doctorIcon from "../../../assets/doctor-icon.svg";
import patientProfileIcon from "../../../assets/patient-profile-image.svg";
import doctorProfileIcon from "../../../assets/doctors-profile-image.svg";
import patientReportIcon from "../../../assets/patient-report-icon-blue.svg";

import SimpleLoader from "../Loaders/SimpleLoader";
import { capitalizeFirstLetter } from "../../../utilities/helper";
import { useEffect } from "react";

export interface CustomTableProps {
  tableData: any[];
  tableColumns: TableColumn[];
  styles?: object;
  scrollHandler?: any; // This will be taking the function
  isInnerLoading?: boolean;
  hasNext?: boolean;
}
export interface TableColumn {
  type?: string;
  key?: string;
  align?: any; // alignment values
  prefix?: string;
  subType?: any; // replace | view
}

const truncateString = (inputString: string, numChars: number) => {
  let outputString = "";
  if (inputString && inputString.length) {
    outputString =
      numChars && inputString.length > numChars
        ? inputString.slice(0, numChars - 1) + "..."
        : inputString;
  }

  return outputString;
};

const CustomTable = ({
  tableData,
  tableColumns,
  styles,
  scrollHandler,
  isInnerLoading,
  hasNext,
}: CustomTableProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const prepTableCell = (column: any, row: any) => {
    if (column.type == "text") {
      if (column.key == "file_name") {
        return (
          <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
            onClick={() => {
              if (!searchParams.has("viewReport")) {
                searchParams.append("viewReport", "true");
                searchParams.append("reportId", row["key"]);
                searchParams.append("reportName", row["fileName"]);
                setSearchParams(searchParams);
              }
            }}
          >
            <img height={60} width={50} src={"https://heme-content-files.s3.ap-south-1.amazonaws.com/feature_images/report-image.png"} style={{ marginRight: 15, top: 5 }} />
            <span
              style={{
                fontSize: "14px",
                padding: "2px 2px",
              }}
            >
              {"" + row["fileName"]}
            </span>
          </div>
        );
      }
      if (column.key == "patientCount") {
        if (column?.styles) {
          // for smaller screens
          return (
            <span
              style={{
                fontSize: "12px",
                color: "#808080"
              }}
            >
              <span
                style={
                  {
                    fontSize: "12px",
                    backgroundColor: COLOR_PRIMARY_LIGHT,
                    padding: "2px 10px",
                    marginLeft: "5px",
                    color: "#808080"
                  }
                }
              >
                {row["patientCount"]}
              </span> Patient
            </span>
          )
        }
        // for bigger screens
        else return (
          <span>
            <span
              style={
                {
                  fontSize: "14px",
                  backgroundColor: COLOR_PRIMARY_LIGHT,
                  padding: "2px 10px",
                  marginLeft: "5px",
                }
              }
            >
              {row["patientCount"]}
            </span> Patient
          </span>
        );
      }
      if (column.key == "doctorCount") {
        if (column?.styles) {
          // for smaller screens
          return (
            <span
              style={{
                fontSize: "12px",
                color: "#808080"
              }}
            >
              <span
                style={
                  {
                    fontSize: "12px",
                    // backgroundColor: COLOR_PRIMARY_LIGHT,
                    backgroundColor: "#EFF7EA",
                    padding: "2px 10px",
                    marginLeft: "5px",
                    color: "#808080"
                  }
                }
              >
                {row["doctorCount"]}
              </span> Doctors
            </span>
          )
        }
        // for bigger screens
        else return (
          <span>
            <span
              style={
                {
                  fontSize: "14px",
                  backgroundColor: "#EFF7EA",
                  padding: "2px 10px",
                  marginLeft: "5px",
                }
              }
            >
              {row["doctorCount"]}
            </span> Doctors
          </span>
        );
      }
      if (column.key == "uploadDate") {
        if (column?.styles) {
          // for smaller screens
          return (
            <span
              style={{
                fontSize: "12px",
                color: "#808080"
              }}
            >
              <span
                style={
                  {
                    fontSize: "12px",
                    padding: "2px 10px",
                    marginLeft: "5px",
                    color: "#808080"
                  }
                }
              >
                {row["uploadDate"]}
              </span> uploadDate
            </span>
          )
        }
        // for bigger screens
        else return (
          <span>
            <span
              style={
                {
                  fontSize: "14px",
                  padding: "2px 0px",
                  marginLeft: "5px",
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }
              }
            >
              {row["uploadDate"]}
            </span>
          </span>
        );
      }
      if (column.key == "gender") {
        return (
          <span
            style={{
              fontSize: "14px",
              padding: "2px 2px",
              color: '#808080'
            }}
          >
            {row.gender == null ? "" : capitalizeFirstLetter(row.gender)}
          </span>
        );
      }
      if (column.key == "dob") {
        return (
          <span
            style={{
              fontSize: "14px",
              padding: "2px 2px",
            }}
          >
            {row.dob == null ? "" : `DOB: `}
            {row.dob == null ? "" : <span style={{ color: '#808080' }}>{row.dob}</span>}
          </span>
        );
      }
      } else if (column.type == "actionText") {
      if (column.key == "appUserPhone") {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              row?.hasOwnProperty("doctor_fhir_id") ?
                <img height={40} width={40} src={doctorProfileIcon} style={{ marginRight: 5, top: 5 }} />
                :
                <img height={40} width={40} src={patientProfileIcon} style={{ marginRight: 5, top: 5 }} />
            }
            <span
              onClick={() => {
                row?.hasOwnProperty("doctor_fhir_id") ?
                  column.handler("doctorId", navigate, row["doctor_fhir_id"]) :
                  column.handler("patientId", navigate, row["patient_fhir_id"])
              }
              }
              style={{
                color: COLOR_PRIMARY,
                fontWeight: 'bold',
                fontSize: "14px",
                padding: "2px 2px",
                marginLeft: "5px",
                cursor: "pointer"
              }}
            >
              {"" + capitalizeFirstLetter(row["appUserName"])}
            </span>{" "}
            <span
              style={{
                fontSize: "14px",
                padding: "2px 2px",
                marginLeft: "20px",
              }}
            >
              {"" + row["appUserPhone"]}
            </span>
          </div>
        )
      }
      return (
        <div style={{ justifyContent: 'center' }}>
          <span
            onClick={() => { }
            }
            style={
              column.styles
                ? column.styles
                : {
                  color: "#0099CC", fontSize: "16px",
                }
            }
          >
            {row["appUserPhone"]}
          </span>
        </div>
      );
    } else if (column.type == "actionButton") {
      if (column.subType == "replace") {
        if (column.small) {
          return (
            <Button
              sx={{ color: "#FFF", maxWidth: "30px", minWidth: "30px" }}
              variant={"contained"}
              onClick={() => {
                if (!searchParams.has("replace")) {
                  searchParams.append("replace", "true");
                  searchParams.append("reportId", row["key"]);
                  setSearchParams(searchParams);
                }
              }}
            >
              <img height={24} width={24} src={infoIcon} />
            </Button>
          );
        }
        return (
          <Button
            sx={{ color: "#FFF" }}
            variant={"contained"}
            onClick={() => {
              if (!searchParams.has("replace")) {
                searchParams.append("replace", "true");
                searchParams.append("reportId", row["key"]);
                setSearchParams(searchParams);
              }
            }}
          >
            <img height={24} width={24} src={infoIcon} style={{ marginRight: "5px" }} />
            Replace
          </Button>
        );
      }
      else if (column.subType == "singleReportDetails") {
        if (column.small) {
          return (
            <Button
              sx={{ color: "#FFF", maxWidth: "30px", minWidth: "30px" }}
              variant={"contained"}
              onClick={() => {
                if (!searchParams.has("singleReportDetails")) {
                  searchParams.append("singleReportDetails", "true");
                  searchParams.append("reportId", row["key"]);
                  setSearchParams(searchParams);
                }
              }}
            >
              <img height={24} width={24} src={infoIcon} />
            </Button>
          );
        }
        return (
          <Button
            sx={{ color: "#FFF" }}
            variant={"contained"}
            onClick={() => {
              if (!searchParams.has("singleReportDetails")) {
                searchParams.append("singleReportDetails", "true");
                searchParams.append("reportId", row["key"]);
                setSearchParams(searchParams);
              }
            }}
          >
            Details
          </Button>
        );
      }

      else if (column.subType == "view") {
        if (column.small) {
          return (
            <Button
              sx={{ color: "#FFF", maxWidth: "30px", minWidth: "30px" }}
              onClick={() => {
                if (!searchParams.has("replace")) {
                  searchParams.append("viewReport", "true");
                  searchParams.append("reportId", row["key"]);
                  searchParams.append("reportName", row["fileName"]);
                  setSearchParams(searchParams);
                }
              }}
            >
              <img src={viewIcon} />
            </Button>
          );
        }
        return (
          <Button
            onClick={() => {
              if (row?.hasOwnProperty("doctor_fhir_id")) {
                column.handler("doctorId", navigate, row["doctor_fhir_id"])
              }
              else if (row?.hasOwnProperty("patient_fhir_id")) {
                column.handler("patientId", navigate, row["patient_fhir_id"])
              }
              else {
                if (!searchParams.has("viewReport")) {
                  searchParams.append("viewReport", "true");
                  searchParams.append("reportId", row["key"]);
                  searchParams.append("reportName", row["fileName"]);
                  setSearchParams(searchParams);
                }
              }
            }}
          >
            View
            <img src={viewIcon} style={{ marginLeft: "5px" }} />
          </Button>
        );
      } else if (column.subType == "edit") {
        return (
          <Button
            onClick={() => {
              if (!searchParams.has("replace")) {
                searchParams.delete("addEditPatient");
                searchParams.delete("patient_fhir_id");
                searchParams.append("addEditPatient", "true");
                searchParams.append("patient_fhir_id", row["patient_fhir_id"]);
                setSearchParams(searchParams);
              }
            }}
          >
            <img src={editIcon} style={{ marginRight: "5px" }} />
            Edit
          </Button>
        );
      } else if (column.subType == "delete") {
        return (
          <Button
            sx={{ color: "#CC8552" }}
            onClick={() => {
              if (!searchParams.has("deleteTest")) {
                searchParams.append("deleteTest", "true");
                searchParams.append("testId", row["key"]);
                searchParams.append("testName", row["testName"]);
                setSearchParams(searchParams);
              }
            }}
          >
            <img src={deleteIcon} style={{ marginRight: "5px" }} />
            Delete
          </Button>
        );
      } else if (column.subType == "whatsapp") {
        if (column.small) {
          return (
            <Button
              sx={{ color: "#FFF", maxWidth: "30px", minWidth: "30px" }}
              variant={"contained"}
              onClick={() => {
                if (!searchParams.has("whatsapp")) {
                  searchParams.append("whatsapp", "true");
                  searchParams.append("reportId", row["key"]);
                  setSearchParams(searchParams);
                }
              }}
            >
              <img src={whatsappIcon} />
            </Button>
          );
        }
        return (
          <Button
            sx={{ color: "#FFF", width: "120px" }}
            variant={"contained"}
            onClick={() => {
              if (!searchParams.has("whatsapp")) {
                searchParams.append("whatsapp", "true");
                searchParams.append("reportId", row["key"]);
                if (column.user) {
                  if (column.user == "patient") {
                    searchParams.append("sendType", "patient");
                  } else if (column.user == "doctor") {
                    searchParams.append("sendType", "doctor");
                  }
                } else if (row["appUserPhone"] && row["doctorPhone"]) {
                  searchParams.append("sendType", "both");
                } else if (row["appUserPhone"]) {
                  searchParams.append("sendType", "patient");
                } else if (row["doctorPhone"]) {
                  searchParams.append("sendType", "doctor");
                }

                setSearchParams(searchParams);
              }
            }}
          >
            <img src={whatsappIcon} style={{ marginRight: "5px" }} />
            Send PDF
          </Button>
        );
      }
    } else if (column.type == "icon") {
      // return <img style={{ height: 30 }} src={row[column.key]} alt={"img"} />;
    } else if (column.type == "bool") {
      if (row[column.key]) {
        if (column.small) {
          return (
            <div
              style={{
                color: COLOR_VARIANT,
                display: "flex",
                alignItems: "center",
                border: `1px solid ${COLOR_VARIANT}`,
                justifyContent: "center",
                borderRadius: "5px",
                width: "15px",
                padding: "5px 5px",
              }}
            >
              <img style={{}} src={checkIcon} alt={"img"} />
            </div>
          );
        }
        return (
          <div
            style={{
              color: COLOR_VARIANT,
              display: "flex",
              alignItems: "center",
              border: `1px solid ${COLOR_VARIANT}`,
              justifyContent: "center",
              borderRadius: "5px",
              padding: "5px 5px",
            }}
          >
            <img style={{ marginRight: "5px" }} src={checkIcon} alt={"img"} />
            Acquired
          </div>
        );
      } else {
        return "";
      }
    } else if (column.type == "stack") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {column.columns.map((stackColumn: any, k: number) =>
            prepTableCell(stackColumn, row)
          )}
        </div>
      );
    } else if (column.type == "special-stack") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {row[column.patient.number] ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={patientReportIcon} height="16px" />{" "}
              <div
                style={{
                  color: "#0099CC",
                  fontSize: "14px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/patients/${row[column.patient.number]}?name=${row[column.patient.name]
                    }`
                  )
                }
              >
                {row[column.patient.number]}
              </div>
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                {row[column.patient.name]}
              </div>
            </div>
          ) : null}

          {row[column.doctor.number] && row[column.patient.number] ? (
            <div style={{ height: "5px" }}></div>
          ) : null}

          {row[column.doctor.number] ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={doctorIcon} height="16px" />{" "}
              <div
                style={{
                  color: "#7ABA56",
                  fontSize: "14px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/doctors/${row[column.doctor.number]}?name=${row[column.doctor.name]
                    }`
                  )
                }
              >
                {row[column.doctor.number]}
              </div>
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                {row[column.doctor.name]}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <TableContainer style={styles}>
      <Table sx={{ fontFamily: FONT_FAMILY_GENERAL }} aria-label="simple table">
        <TableBody>
          {tableData &&
            tableData.map((row, i) => (
              <>
                <TableRow
                  key={row["key"]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tableColumns.map((column, j) => {
                    if (column.type == "key") {
                      return null;
                    }
                    return (
                      <TableCell
                        sx={{ padding: "10px" }}
                        key={row["key"] + "_" + j}
                        align={column.align ? column.align : "left"}
                        component="th"
                        scope="row"
                      >
                        {prepTableCell(column, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {i == tableData.length - 2 && hasNext ? (
                  <Waypoint
                    key={"waypoint_" + row["key"]}
                    onEnter={scrollHandler}
                  />
                ) : null}
                {i == tableData.length - 1 && isInnerLoading ? (
                  <TableRow key={"loader_row"}>
                    <TableCell
                      key={"loader_cell_" + row["key"]}
                      colSpan={tableColumns.length}
                      component="th"
                      scope="row"
                    >
                      <SimpleLoader size={25} height={"30px"} />
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
