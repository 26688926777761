import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// import { SnackBar } from "../../components/shared";
import IBackArrow from "../../../assets/back-arrow.svg";

import {
TimeFeild, Priority,
Container, HeaderWrapper, Header, HeaderLeft, HeaderRight, SearchWrapper,
AddButton, ProfileImage,
PatientContainer, PatientCard, ProfileSection, PatientInfo, PhoneNumber,
Name, ArrowImage, DetailSection, DetailItem, EmailSection, Email, EmailIcon, EmptyListMessage,
GenderImage, GenderText, DobImage, DobText, DobDate, DobYears, AddIcon, AddText, AddButtonMobile,
InnerContainer, EmailText, EmailItem, EmailImage, Tabs, TabButton, UserName,
ClearsearchBtn
} from "./style.components";
import moment from 'moment';
import { TextField, InputAdornment, Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, TablePagination, TableContainer, Card, CardHeader, Skeleton, CardContent, FormControl, MenuItem, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { KeyboardArrowRight } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import ShortingArrow from "../../../assets/shortingArrow";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import { ArrowBackImage } from "../../common/SideNavBar/style.components";
import { API_URL } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// const TableData = [
//   {
//     "time": "3 min",
//     "patient_name": "Abhishek Gupta",
//     "patient_contact": "+91 9876543210",
//     "modality": "XA",
//     "lab_name": "Health First Labs",
//     "priority": "High",
//     "requested_at": "20-Oct-2024 2:45 pm",
//     "comments": "Brain MRI Tumor detection",
//     "status": "Pending"
//   },
//   {
//     "time": "10 min",
//     "patient_name": "Vaishali Sengupta",
//     "patient_contact": "+91 9123456789",
//     "modality": "PET, CT",
//     "lab_name": "ABC Imaging",
//     "priority": "High",
//     "requested_at": "30-Sep-2024 1:36 am",
//     "comments": "Chest CT pulmonary embolism",
//     "status": "Cancelled"
//   },
//   {
//     "time": "50 min",
//     "patient_name": "Shriniwas Rao",
//     "patient_contact": "+91 9876543210",
//     "modality": "MRI",
//     "lab_name": "XYZ Care Centre",
//     "priority": "High",
//     "requested_at": "5-Aug-2024 9:21 am",
//     "comments": "Spine MRI for Disk herniation",
//     "status": "Cancelled"
//   },
//   {
//     "time": "1 hr",
//     "patient_name": "A. V. Deshpande",
//     "patient_contact": "+91 9123456789",
//     "modality": "KO",
//     "lab_name": "Health First Labs",
//     "priority": "Medium",
//     "requested_at": "11-Jul-2024 4:00 pm",
//     "comments": "CT angiography for coronary",
//     "status": "Pending"
//   },
//   {
//     "time": "12 hr",
//     "patient_name": "Miss. Nandini",
//     "patient_contact": "+91 9123456789",
//     "modality": "XA",
//     "lab_name": "ABC Imaging",
//     "priority": "Low",
//     "requested_at": "2-Jun-2024 11:30 am",
//     "comments": "Chest X-ray for lung nodules",
//     "status": "Completed"
//   },
//   {
//     "time": "5 days",
//     "patient_name": "Shriniwas Rao",
//     "patient_contact": "+91 9876543210",
//     "modality": "XA",
//     "lab_name": "XYZ Care Centre",
//     "priority": "Low",
//     "requested_at": "31-May-2024 9:44 am",
//     "comments": "MR Angiography for the Brain",
//     "status": "Completed"
//   },
//   {
//     "time": "10 days",
//     "patient_name": "Abhishek Gupta",
//     "patient_contact": "+91 9876543210",
//     "modality": "PET",
//     "lab_name": "Health First Labs",
//     "priority": "Medium",
//     "requested_at": "19-Apr-2024 8:43 pm",
//     "comments": "Brain MRI Tumor detection",
//     "status": "Cancelled"
//   },
//   {
//     "time": "20 days",
//     "patient_name": "Vaishali Sengupta",
//     "patient_contact": "+91 9123456789",
//     "modality": "CT, XA",
//     "lab_name": "ABC Imaging",
//     "priority": "High",
//     "requested_at": "5-Mar-2024 4:12 pm",
//     "comments": "Chest CT pulmonary embolism",
//     "status": "Completed"
//   },
//   {
//     "time": "32 days",
//     "patient_name": "Shriniwas Rao",
//     "patient_contact": "+91 9876543210",
//     "modality": "KO",
//     "lab_name": "XYZ Care Centre",
//     "priority": "Low",
//     "requested_at": "3-Feb-2024 10:29 am",
//     "comments": "Spine MRI for Disk herniation",
//     "status": "Completed"
//   }
// ]

const dropdownOptions = [
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "revoked" },
  { label: "Pending", value: "draft" },
];
const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const TeleRediology = () => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState([]);
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileWidth, setMobileWidth] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [patientsSortedData, setPatientsSortedData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [patientsFilteredData, setPatientsFilteredData] = useState([]);



  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setMobileWidth(true);
      } else {
        setMobileWidth(false);
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    fetchAllRecords()
  }, []);

  const fetchAllRecords = useCallback(async () => {
    const token = cookies["access_token"];
    const reportUrl = `${API_URL}/servicerequests/organization`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setIsLoading(true)
    axios
      .get(reportUrl, config)
      .then((response) => {
        if (response && response.data && response.data.result) {
          setPatientData(response.data.result)
          setIsLoading(false)

        } else {
          setIsLoading(false)
          alert("Something went wrong!");

        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  }, []);


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  const headCells = [
    // {
    //   id: "time",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Time ",
    //   isSorting: true,
    //  type: "select", options: ["High", "Medium", "Low"]
    // },
    {
      id: "patient_name",
      numeric: false,
      disablePadding: false,
      label: "Patient Name",
      type: "text",
      isSorting: true,

    },
    // {
    //   id: "phone_number",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Patient Number",
    //   type: "text",
    // },
    // {
    //   id: "modility",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Modility",
    //   isSorting: true,
    //   type: "select", options: ["High", "Medium", "Low"]

    // },
    // {
    //   id: "requester_name",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Lab Name",
    //   isSorting: true,
    //   type: "text"

    // },
    {
      id: "urgency",
      numeric: false,
      disablePadding: false,
      label: "Priority",
      isSorting: false,
      type: "select", options: ["urgent", "routine"]
    },
    {
      id: "request_date",
      numeric: false,
      disablePadding: false,
      label: "Requested at",
      isSorting: true,
      type: "date",
    },

    {
      id: "performer_info",
      numeric: false,
      disablePadding: false,
      label: "Doctor Name",
      isSorting: true,
      type: "text",
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comments",
      isSorting: false,
      type: "text",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      isSorting: false,
      type: "select", options: ["pending", "completed", "rejected"]
    }

  ];


  const [filters, setFilters] = useState(
    headCells.reduce((acc, cell) => ({ ...acc, [cell.id]: "" }), {})
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterChange = (id, value) => {
    setFilters({ ...filters, [id]: value });
  };


  const onStatusUpdate = (e) => {
    console.log("status", e);

  }


  useEffect(() => {
    if (
      filters.patient_name ||
      filters.performer_info ||
      filters.phone_number ||
      filters.urgency ||
      filters.request_date ||
      filters.comments ||
      filters.status
    ) {
      let data = [];
      data = patientData.filter((x) => {
        let filterQuery = true
        if (filters.patient_name) {
          filterQuery = x.patient_name && x.patient_name.toLowerCase().includes(filters.patient_name.toLowerCase())
        }
        // if (filterValue.patientAttached) {
        //   filterQuery =
        //     filterQuery && x.is_sent.toString() == filterValue.patientAttached;
        // }
        if (filters.urgency) {
          filterQuery =
            filterQuery && (x.urgency == filters.urgency);

        }

        if (filters.status) {
          if (filters.status == 'pending') {
            filterQuery =
              filterQuery && (x.status == "draft");
          }else if (filters.status == 'rejected') {
            filterQuery =
              filterQuery && (x.status == "revoked");
          } else {
            filterQuery =
              filterQuery && (x.status == filters.status);
          }


        }
        if (filters.phone_number) {
          console.log("x.phone_number", x.phone_number);

          filterQuery =
            filterQuery && x.phone_number && x.phone_number.toString().toLowerCase()
              .includes(filters.phone_number.toLowerCase());
        }
        if (filters.comments) {
          filterQuery =
            filterQuery && x.comments && x.comments.toString().toLowerCase()
              .includes(filters.comments.toLowerCase());
        }
        if (filters.performer_info) {
          filterQuery =
            filterQuery && x.performer_info && x.performer_info.toString().toLowerCase()
              .includes(filters.performer_info.toLowerCase());;
        }

        if (filters.request_date) {
          let filterData = formattedDate(filters.request_date);
          let studyDate = formattedDate(x.request_date);
          filterQuery = filterQuery && studyDate == filterData;
        }
        return filterQuery;
      });
      if (data) {
        setPatientsFilteredData(data)
      }
    } else {
      setPatientsFilteredData([]);
    }
  }, [filters])




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patientData.length) : 0;

  const formattedDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    return moment(new Date(dateString)).format("DD-MMM-YYYY");
  };

  useEffect(() => {
    const isFilter = filters.patient_name ||
      filters.performer_info ||
      filters.phone_number ||
      filters.urgency ||
      filters.request_date ||
      filters.comments ||
      filters.status
    let visibleRows = []
    if (patientData && patientData.length) {
      visibleRows = ((isFilter || patientsFilteredData?.length) ? patientsFilteredData : [...patientData]).sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    setPatientsSortedData(visibleRows || []);
  }, [order, orderBy, page, rowsPerPage, patientData, patientsFilteredData])


  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property) => (event) => {
        onRequestSort(event, property);
      };


    const handleSort = (property) => (event) => {
      onRequestSort(event, property);
    };


    return (<>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {headCell.isSorting ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={ShortingArrow}
                >
                  <span style={{ minWidth: '90px' }}>{headCell.label} &nbsp; </span>
                  {orderBy === headCell.id ? (<>
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  </>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{headCell.label}</span>
              )}

            </TableCell>
          ))}
          <TableCell sx={{ verticalAlign: "bottom" }} align="right">
          </TableCell>

        </TableRow>
      </TableHead>



    </>
    );
  }



  const PatienTableRow = ({ patientsData }) => {
    return patientsData?.length ? patientsData.map((row, i) => {

      const {
        time,
        patient_name,
        phone_number,
        modality,
        performer_info,
        requester_name,
        urgency,
        request_date,
        comments,
        status,
        study_url
      } = row;


      return (
        <TableRow
          key={i}
          sx={{
            "& > *": {
              borderBottom: "unset",
              padding: "16px 16px",
            },
            "&:hover": {
              backgroundColor: "#DDEBED !important"
            }
          }}
        >
          {/* <TableCell component="th" scope="row">
        <Tooltip title={time || "-"}>
          <TimeFeild style={{ fontSize: "0.875rem", maxWidth: "150px" }}>{time || "-"}</TimeFeild>

        </Tooltip>
      </TableCell> */}

          <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <Tooltip title={patient_name || "-"}>
              <span style={{ fontSize: "0.875rem", maxWidth: "200px", color: '#000000de' }}>{patient_name || "-"}</span>
            </Tooltip>
          </TableCell>

          {/* <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <span style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }}>{phone_number || "-"}</span>
          </TableCell> */}

          {/* <TableCell component="th" scope="row">
        <span style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }}>{modality || "-"}</span>
      </TableCell> */}

          {/* <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <span style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }}>{requester_name || "-"}</span>
          </TableCell> */}

          <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <Priority
              style={{ fontSize: "0.875rem", maxWidth: "140px" }}
              bgColor={
                urgency === 'urgent' ? '#F5CECE' :
                  urgency === 'asap' ? '#FFF5C2' :  // Light yellow
                    urgency === 'routine' ? '#D7EFF7' :     // Light blue
                      '#D7EFF7' // Default color
              }
            >
              {capitalizeFirstLetter(urgency) || "-"}
            </Priority>
          </TableCell>

          <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <span style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }}> {moment(new Date(row?.request_date)).format("DD-MMM-YYYY") || "-"}</span>
          </TableCell>
          <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} >
            <span style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }}>{performer_info || "-"}</span>
          </TableCell>

          <TableCell component="th" scope="row" onClick={()=>handleScanClick(study_url)} sx={{maxWidth: "200px",textOverflow: 'ellipsis',overflow:'hidden',wordWrap: "break-word",whiteSpace:'nowrap'}} >
          <Tooltip title={comments || "-"}>
          <span style={{ fontSize: "0.875rem",maxWidth: "200px", color: '#000000de'}}>{comments || "-"}</span>
          </Tooltip>
            
          </TableCell>

          <TableCell component="th" scope="row">
            <FormControl sx={{ width: "160px" }} >
              <Select
                value={(status === "draft" ? "draft" : status) || "draft"}
                onChange={(e) => {
                  onStatusUpdate(e.target.value)
                }}
                sx={{
                  paddingRight: 1,
                  paddingTop: 0,
                  paddingLeft:2,
                  height: "45px",
                  alignItems:'center',
                  textAlign:'center',
                  color: status == "draft" ? "#CC8552" : status == "completed" ? "#7ABA56" : "#808080",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: `2px solid  ${status == "draft" ? "#CC8552" : status == "completed" ? "#7ABA56" : "#808080"} !important`,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${status == "draft" ? "#CC8552" : status == "completed" ? "#7ABA56" : "#808080"}`,
                  },
                  "& .MuiSvgIcon-root": {
                    display:'none',
                    background: `${status == "draft" ? "#CC8552" : status == "completed" ? "#7ABA56" : "#808080"}`,
                    borderRadius: 1,
                    color: "#FFF",
                  },
                  pointerEvents:'none',
                }}
              >
                {dropdownOptions.map((option) => (
                  <MenuItem key={`dropdown-${option.value}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>

          {/* <TableCell align="right" onClick={()=>handleScanClick(study_url)} >
            <IconButton aria-label="expand row" size="small">
              <KeyboardArrowRight />
            </IconButton>
          </TableCell> */}
        </TableRow>
      );
    }) : <TableRow><EmptyListMessage>No patients data found</EmptyListMessage></TableRow>

  }


  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Tele-radiology",
    },
  ];



  const isFilterData = filters.patient_name ||
    filters.performer_info ||
    filters.phone_number ||
    filters.urgency ||
    filters.request_date ||
    filters.comments ||
    filters.status


    const handleScanClick = (fhirId) => {
      // navigate("/tele-radiology/patient-info", {
      //   state: {
      //     studyFhirId: fhirId,
      //   },
      // });
      console.log("handle click");
      
    };


  return (
    <Container style={{ margin: !isMobileWidth ? '0 5px 0 5px' : '0' }}>
      <Breadcrumb links={breadCrubLinks} />
      <div style={{ marginTop: '20px' }}>
        <HeaderWrapper >
          {/* <Box display="flex" justifyContent="start" alignItems={"center"} width={"30%"}> */}
          <Header style={{ color: '#000000', fontWeight: 500, alignItems: 'center', marginLeft: '-12px', cursor: 'pointer',width:'500px' }} onClick={handleHomeNavigation}>        <ArrowBackImage src={IBackArrow} alt="back-arrow" />
            Tele-radiology  </Header>
           
                        <ClearsearchBtn
                          onClick={() => {
                            setFilters(headCells.reduce((acc, cell) => ({ ...acc, [cell.id]: "" }), {}));
                            
                            setPatientsFilteredData([]);
                          }}
                        >
                          {" "}
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00001 14.6663C4.31811 14.6663 1.33334 11.6815 1.33334 7.99967C1.33334 4.31777 4.31811 1.33301 8.00001 1.33301C11.6819 1.33301 14.6667 4.31777 14.6667 7.99967C14.6667 11.6815 11.6819 14.6663 8.00001 14.6663ZM8.00001 13.333C10.9455 13.333 13.3333 10.9452 13.3333 7.99967C13.3333 5.05415 10.9455 2.66634 8.00001 2.66634C5.05449 2.66634 2.66668 5.05415 2.66668 7.99967C2.66668 10.9452 5.05449 13.333 8.00001 13.333ZM8.00001 7.05687L9.88561 5.17125L10.8284 6.11405L8.94281 7.99967L10.8284 9.88527L9.88561 10.8281L8.00001 8.94247L6.11439 10.8281L5.17158 9.88527L7.05721 7.99967L5.17158 6.11405L6.11439 5.17125L8.00001 7.05687Z"
                              fill="white"
                            />
                          </svg>
                          Clear Search
                        </ClearsearchBtn>
        </HeaderWrapper>
      </div>
      {isLoading ? (
        <>

          <Box >
            {Array.from(new Array(20)).map(() => {
              return <Skeleton animation="wave" sx={{ bgcolor: 'grey.200' }} height={30} />
            })}
          </Box>

        </>

      ) : (
        <>
          {!isLoading && patientData?.length > 0 ? (<>

            <PatientContainer style={{ background: '#fff', justifyContent: 'flex-start', display: 'flex', padding: '4px' }}>
              <TableContainer
                sx={{
                  display: 'flex !important',
                  flexDirection: 'column !important',
                  justifyContent: 'flex-start !important',
                  alignItems: 'start !important',
                  '.MuiTablePagination-root': {
                    overflow: 'visible !important',

                  }
                }}
              >

                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  aria-label="collapsible table"
                  size={dense ? "small" : "medium"}
                >

                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={patientData.length}
                    filtersValue={filters}
                    setFiltersData={setFilters}
                  />

                  <TableRow className="searchHeader">

                    {headCells.map((column) => {
                      return (
                        <>
                          {column.type === "select" ? (
                            <TableCell>
                              <Select
                                value={filters[column.id] || ""}
                                onChange={(e) => handleFilterChange(column.id, e.target.value)}
                                // value={ ""}
                                displayEmpty
                                size="small"
                                fullWidth
                                sx={{ mt: 1,minWidth: "90px" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {column.options?.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {capitalizeFirstLetter(option)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          ) : column.type === "text" && (column.id !== "requester_name") ? ( // Only show search for labName and comments
                            <TableCell>
                              <TextField
                                value={filters[column.id] || ""}
                                onChange={(e) => handleFilterChange(column.id, e.target.value)}
                                placeholder={`Search`}
                                size="small"
                                fullWidth
                                sx={{ mt: 1, minWidth: "110px" }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon sx={{ color: "#7ABA56",paddingLeft:'2px' }} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                          ) : column.type === 'date' ?
                            (
                              <TableCell>
                                <FormControl size="small" sx={{ minWidth: "120px", marginTop: '10px' }} >
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                      inputFormat="DD-MM-YYYY"
                                      disableFuture
                                      label=""

                                      value={filters[column.id] || null}
                                      
                                      onChange={(e) =>
                                        handleFilterChange("request_date", e)
                                      }
                                      // onChange={handleDobChange}
                                      InputProps={{
                                        placeholder: "DD-MM-YYYY",
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{
                                            svg: { color: "#7BBA56" },
                                            ".MuiInputBase-root": {
                                              height: "40px",
                                            },
                                            ".MuiInputBase-input": {
                                              borderColor: "#E4E4E7",
                                            },

                                            // "& .MuiOutlinedInput-notchedOutline": {
                                            //   borderColor: "#E4E4E7",
                                            // },
                                            "& .MuiInputBase-adornedEnd": {
                                              borderColor: "#E4E4E7 !important",
                                            },
                                            // "&:hover > .MuiOutlinedInput-notchedOutline":
                                            // {
                                            //   borderColor: "#E4E4E7",
                                            // },
                                          }}
                                          {...params}
                                          helperText={null}
                                          error={false}

                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </TableCell>
                            )

                            : (<TableCell><div style={{ height: '50px' }}></div></TableCell>)}

                        </>
                      )
                    })}
                   

                  </TableRow>


                  <TableBody>
                    {patientsSortedData.length ? <PatienTableRow patientsData={patientsSortedData} />:null}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={(patientsFilteredData.length ?patientsFilteredData.length : patientData?.length) || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </PatientContainer>

          </>


          ) : (
            <EmptyListMessage>No patients data found</EmptyListMessage>
          )}
        </>
      )}

      {/* <SnackBar /> */}
    </Container>
  );
};
export default TeleRediology;
