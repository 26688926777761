import {
  MenuItem,
  FormControl,
  Select,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { API_URL, DEVICE } from "../../../utilities/constants";
import { setShowStatus } from "../../../store/slice/statusSlice";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import closeIcon from "../../../assets/close-icon.png";
import PhoneNumberValidation from "../PhoneNumberValidation/PhoneNumberValidation";
import { Spinner } from "@react-pdf-viewer/core";

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pl: 4,
  pb: 4,
};

const StyledTextField = styled(TextField)`
  && {
    background-color: #EBF7FB;
    border-radius: 8px;
    width: 100%;
    border: none;
    font-size: 16px;
    .MuiOutlinedInput-input {
      border: none;
      font-size: 16px;
      @media(max-width:650px){
        font-size: 14px;
      }
    .MuiOutlinedInput-root {
      fieldset,
      &.Mui-focused fieldset {
        border: none;
      }
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiOutlinedInput-root {
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input {
    border-radius: 8px;
    background-color: #ebf7fb;
    @media (max-width: 650px) {
      font-size: 14px;
    }
  }
`;
const CloseButtonWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  background: transparent;
`;
const ModalHeader = styled(Typography)`
  color: #0099cc;
  font-size: 18px !important;
  margin-bottom: 40px !important;
`;

const SaveButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: ${(props) =>
    props.disabled ? "#cccccc" : "#7aba56"}; /* Grey color when disabled */
  color: ${(props) =>
    props.disabled ? "#666666" : "white"}; /* Darker text color when disabled */
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  cursor: ${(props) =>
    props.disabled ? "default" : "pointer"}; /* Default cursor when disabled */
  pointer-events: ${(props) =>
    props.disabled
      ? "none"
      : "auto"}; /* Disable click events when button is disabled */
`;
const CustomSelect = styled.select({
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "45px",
  flexGrow: 1,
  paddingLeft: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});
const MobileNumWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const CloseButton = styled.img`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const ModalBody = styled.div`
  padding-right: 32px;
`;

const StyledLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 16px;
`;
export interface PatientAddEditProps {
  open: boolean;
  testId: string | null;
  testName?: string | null | undefined;
  testPrice?: string | null | undefined;
  closeHandler: () => void;
}

const AddEditPatient = ({
  open,
  closeHandler,
  testId,
  testName,
  testPrice,
}: PatientAddEditProps) => {
  const maxNameLength = 35;
  const namePrefix = "";
  const [cookies, setCookie, removeCookie] = useCookies();

  const [notValidInput, setNotValidInput] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string>("");
  const [patientEmail, setPatientEmail] = useState<string>("");

  const [patientNumber, setPatientNumber] = useState<any | null>();
  const [selectedGender, setSelectedGender] = useState("");
  const [userDob, setUserDob] = useState("");
  const [patientProfileData, setPatientProfileData] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isFormEdited, setIsFormEdited] = useState(false);

  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (getSearchParam("patient_fhir_id")) {
      fetchPatientData();
    }
  }, [searchParams]);

  useEffect(() => {
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
      }
    } catch (error) {
      console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  const handlePhoneNumberChange = (newPhoneNumber: any) => {
    setPatientNumber(newPhoneNumber);
  };

  // useEffect(() => {
  //   if (testPrice != patientNumber?.toString()) {
  //   //   if (patientNumber) {

  //   //     if (
  //   //       patientNumber.toString().length >= 12
  //   //     ) {
  //   //     console.log("sdmfbjhsgfjsgfjsgdj",patientNumber);

  //   //       setNotValidInput(false);
  //   //       setErrorMsg("")
  //   //     } else {
  //   //       setNotValidInput(true);
  //   //       setErrorMsg("Please enter a valid mobile number")
  //   //     }
  //   //   } else {
  //   //     setNotValidInput(true);
  //   //     setErrorMsg("*Required a valid mobile number")
  //   //   }
  //   // } else {
  //   //   setNotValidInput(true);
  //   // }
  // }, [patientNumber]);

  const checkFormEdited = (name: any, gender: any, dob: any) => {
    const edited =
      name !== patientProfileData?.name ||
      gender !== patientProfileData?.gender ||
      dob !== patientProfileData?.dob;
    setIsFormEdited(edited);
  };

  const handleNameChange = (event: any) => {
    setPatientName(event.target.value);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleEmailChange = (event: any) => {
    setPatientEmail(event.target.value);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleGenderChange = (selectedValue: string) => {
    setSelectedGender(selectedValue);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleDobChange = (newValue: any) => {
    setUserDob(newValue);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleSave = () => {
    // if (notValidInput) {
    //   return;
    // }
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(patientEmail);
    const isNumber = patientNumber && patientNumber.toString().length >= 12;

    if (
      (patientEmail &&
        isEmail &&
        (patientNumber?.toString().length < 3 || !patientNumber)) ||
      (!patientEmail && patientNumber && isNumber) ||
      (patientEmail && isEmail && patientNumber && isNumber)
    ) {
      setErrorMsg("");

      setIsLoading(true);

      var jsonData: any = {
        name: namePrefix + patientName,
      };

      if (!!patientNumber && patientNumber.toString().length >= 12) {
        jsonData.contact_number =
          patientNumber && patientNumber.toString().length < 3
            ? ""
            : "+" + patientNumber;
      }

      if (!!selectedGender) {
        jsonData.gender = selectedGender;
      }
      if (!!userDob) {
        jsonData.date_of_birth = moment(userDob).format("YYYY-MM-DD");
      }
      if (!!patientEmail) {
        jsonData.email = patientEmail;
      }

      axios
        .post(`${API_URL}/patient`, jsonData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies["access_token"]}`,
          },
        })
        .then((response) => {
          console.log(response);
          // dispatch(incrementCount());
          closeHandler();
          if (getSearchParam("fromSendReport")) {
            // searchParams.delete("fromSendReport");
            // setSearchParams(searchParams);
          } else {
            window.location.reload();
          }
          dispatch(
            setShowStatus({
              message: "Patient added successfully",
              severity: "success",
            })
          );
        })
        .catch((err) => {
          // Handle success - report not sent, and something else
          let message = "patient creation failed";
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            message = "Failure. " + err.response.data.message;
          }
          setIsLoading(false);
          setErrorMsg("");

          dispatch(
            setShowStatus({
              message: message,
              severity: "error",
              autoHide: "no",
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
          setErrorMsg("");
        });
    } else {
      if (patientNumber && !isNumber) {
        setErrorMsg("Please enter a valid  mobile number");
      }

      if (patientEmail && !isEmail) {
        setErrorMsg("Please enter a valid email");
      }
      if (!patientEmail && !patientNumber) {
        setErrorMsg("*Required a valid mobile number or Email");
      }
    }
  };

  const handleEdit = () => {
    setIsLoading(true);

    var jsonData: any = {
      id: getSearchParam("patient_fhir_id"),
      name: patientName,
    };

    if (!!selectedGender) {
      jsonData.gender = selectedGender;
    }
    if (!!userDob) {
      jsonData.date_of_birth = moment(userDob).format("YYYY-MM-DD");
    }
    if (!!patientEmail) {
      jsonData.email = patientEmail;
    }

    axios
      .patch(`${API_URL}/patient`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);
        // dispatch(incrementCount());
        closeHandler();
        window.location.reload();
        dispatch(
          setShowStatus({
            message: "Patient updated successfully",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        // Handle success - report not sent, and something else
        let message = "patient updation failed";
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          message = "Failure. " + err.response.data.message;
        }
        setIsLoading(false);
        dispatch(
          setShowStatus({
            message: message,
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const fetchPatientData = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/patient/${getSearchParam(
      "patient_fhir_id"
    )}/profile`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (
          response.data &&
          response.data?.status == "success" &&
          response.data?.data
        ) {
          setPatientNumber(response.data?.data?.phone_number);
          setPatientName(response.data?.data?.name || "");
          setPatientEmail(response.data?.data?.email || "");
          setPatientProfileData(response.data?.data);
          setIsFormEdited(false);
          if (response.data?.data?.gender)
            setSelectedGender(response.data?.data?.gender);
          if (response.data?.data?.dob)
            // setUserDob(moment(response.data?.data?.dob).format("YYYY-MM-DD"))
            // setUserDob(moment(response.data?.data?.dob).format("DD-MM-YYYY"))
            setUserDob(response.data?.data?.dob);
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFormattedDate = (date: string | null | undefined): string => {
    if (!date) return "";
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
  };
  
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseButtonWrapper>
          <CloseButton src={closeIcon} onClick={closeHandler} />
        </CloseButtonWrapper>
        <ModalHeader id="modal-modal-title" variant="h6">
          Add Patient
        </ModalHeader>
        <ModalBody>
          <StyledLabel>Mobile Number</StyledLabel>
          <PhoneNumberValidation
            onPhoneNumberChange={handlePhoneNumberChange}
            selectedCountry={selectedCountry}
            initialPhoneNumber={patientNumber}
            disabled={getSearchParam("patient_fhir_id") ? true : false}
          />
          <StyledLabel>Email</StyledLabel>
          <StyledTextField
            variant="outlined"
            size="small"
            type="email"
            placeholder="Email Address"
            value={patientEmail}
            onChange={handleEmailChange}
            fullWidth
          />
          <StyledLabel>Patient Name</StyledLabel>
          <StyledTextField
            variant="outlined"
            size="small"
            type="name"
            placeholder="Patient Name"
            value={patientName}
            onChange={handleNameChange}
            fullWidth
          />

          <Box
            display="flex"
            justifyContent="space-between"
            marginTop="16px"
            alignItems={"end"}
          >
            <Box style={{ width: "49%" }}>
              <StyledLabel>Gender (Optional)</StyledLabel>
              <StyledFormControl variant="outlined" size="small" fullWidth>
                <Select
                  displayEmpty // to display the placeholder when no item is selected
                  value={selectedGender}
                  onChange={(e) => handleGenderChange(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Gender
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </StyledFormControl>
            </Box>
            <Box style={{ width: "49%" }}>
              <StyledLabel>Date of Birth (Optional)</StyledLabel>
              <StyledTextField
                variant="outlined"
                size="small"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={getFormattedDate(userDob)}
                onChange={(e) => handleDobChange(e.target.value)}
              />
            </Box>
          </Box>
          <Box>
            <span style={{ fontSize: "10px", color: 'red' }}>
            {errorMsg}
            </span>
          </Box>
          <ButtonWrapper>
            <SaveButton
              onClick={() => {
                if (getSearchParam("patient_fhir_id")) {
                  handleEdit();
                } else handleSave();
              }}
              disabled={
                !isLoading && !patientEmail && !patientNumber ? true : false
              }
            >
              {!isLoading ? "Save" : <Spinner size="10px" />}
            </SaveButton>
          </ButtonWrapper>
        </ModalBody>
      </Box>
    </Modal>
  );
};

export default AddEditPatient;
