import styled from "styled-components";
import { COLOR_PRIMARY_LIGHT, FOOTER_HEIGHT } from "../../utilities/constants";

const FooterRow = styled.div`
  color: #808080;
  font-size: 14px;
  height: ${FOOTER_HEIGHT};
  background-color: ${COLOR_PRIMARY_LIGHT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const FixedFooter = () => {
  return <FooterRow>© HemePortal 2024, All rights reserved.</FooterRow>;
};

export default FixedFooter;
