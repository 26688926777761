import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const dummyUserList = [
  {
    name: "User1",
    id: 1,
  },
  {
    name: "User2",
    id: 2,
  },
  {
    name: "User3",
    id: 3,
  },
];
const UserDropdown = () => {
  const [userList, setUserList] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  useEffect(() => {
    setUserList(dummyUserList);
    // setSelectedUser(dummyUserList[0].id);
  });

  return (
    <>
      {userList && userList.length ? (
        <FormControl>
          <Select
            sx={{
              backgroundColor: "#EBF7FB",
              height: "45px",
              width: "120px",
              borderRadius: "8px",
              paddingInline: "15px",
              color: "#808080",
              fontSize: "16px",
            }}
            value={selectedUser ? selectedUser : null}
            disableUnderline
            onChange={(event) => {
              setSelectedUser(event.target.value);
            }}
            variant={"standard"}
          >
            {userList.map((user, i) => (
              <MenuItem value={user.id}>{user.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        ""
      )}
    </>
  );
};

export default UserDropdown;
