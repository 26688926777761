import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useDispatch, useSelector } from "react-redux";
// import { ICloseModal } from "../../../assets";
import closeIcon from "../../../assets/close-icon.png"

import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
// import {
//   startTranscriptionJobAction,
// } from "../../../store/actions";

import {
  ModalBody,
  ModalHeader,
  CloseButtonWrapper,
  CloseButton,
  SaveButton,
  CopySummaryButton,
  FinishButton,
  LoaderWrapper,
  TextAreaWrapper,
  Label,
  TextArea,
  LabelTextArea,
  ModalBodySecond,
  StyledMinimizeIcon,
  StyledSelect,
  StartTranscriptionContainer,
  FromText,
  EHRContainer,
  SideIcon,
  TranscriptionextArea,
  AddToEhrButton,
  PreviewAudioSection,
  Transcribe,
  CustomRecordBox,
  TranscribeBox
} from "./style.components";
import { apiClient } from "../../../api/api-client";
import { API_ENDPOINT, FONT_FAMILY_GENERAL } from "../../../utilities/constants";
import { patientSelector } from "../../../store/slice/patient.slice";
import axios from "axios";
import RecordRTC from "recordrtc";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { LiveAudioVisualizer } from "react-audio-visualize";
import SimpleLoader from "../Loaders/SimpleLoader";

const SAMPLE_RATE = 48000;

const style = {
  display: "flex",
  flexDirection: "column",
  width:'100%',
  height:'100%'

 

};

var mediaRecorder = null; // set mediaRecorder as  an globally accessible
var audioText;
const DG_KEY = "a0cfd002c33bb168ce45a0e9e5e05c6da88802fb";
let socket; // Declare socket globally

const AudioRecordModal = ({
  open,
  handleClose,
  handleSave,
  setIsTranscriptionProgress,
  isTranscriptionProgress,
  postTranscriptCallback,
}) => {
  const dispatch = useDispatch();
  const [audioUrl, setAudioUrl] = useState(null);
  const [showAudio, setShowAudio] = useState(false); // State to control rendering of the audio element
  const [audioBlobWhisper, setAudioBlobWhisper] = useState(null);
//   const [fileUrl, setFileUrl] = useState("");
  const [jobId, setJobId] = useState("");
  const [getNotesObject, setGetNotesObject] = useState({});
  const [isPolling, setIsPolling] = useState(false);
  const [openAITranscription, setOpenAITranscription] = useState(false);
  const [isUploadingAudio, setIsUploadingAudio] = useState(false);
  const [transcriptionService, setTranscriptionService] = useState("OpenAI");
  const [isStartRecording, setStartRecording] = useState(true);
  const audioC = document.getElementById("audioElement");
  const [stop, setStop] = useState(false);
  const [chunkData, setChunkData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [addToEhrloading, setAddToEhrloading] = useState(false);
  const navigate = useNavigate();
  const increment = useRef(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const playpauseButtonRef = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [inputDevice, setInputDevice] = useState("");
  const [recording, setRecording] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState("");
  const [partialTranscript, setPartialTranscript] = useState("");
  const audioStreamRef = useRef();
  const recorderRef = useRef();
  const socketRef = useRef();
  const divRef = useRef(null);
  const [record, setRecord] = useState(false);
  const [text, setText] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [audioRef, setAudioRef] = useState(null); // Store audio element reference
  const [audioChunks, setAudioChunks] = useState([]);
  const [file, setFile] = useState(null);
  const [patientID, setPatientID] = useState();
  const location = useLocation();
  const [addToEhrLoading, setAddToEhrLoading] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const [language, setLanguage] = useState("en");
  // Array of language options
  const languages = [
    { value: "es", label: "Spanish" },
    { value: "en", label: "English" },
    { value: "hi", label: "Hindi" },
    { value: "ja", label: "Japanese" },
    { value: "ru", label: "Russian" },
    { value: "uk", label: "Ukrainian" },
    { value: "sv", label: "Swedish" },
    { value: "zh", label: "Chinese" },
    { value: "pt", label: "Portuguese" },
    { value: "nl", label: "Dutch" },
    { value: "tr", label: "Turkish" },
    { value: "fr", label: "French" },
    { value: "de", label: "German" },
    { value: "id", label: "Indonesian" },
    { value: "ko", label: "Korean" },
    { value: "it", label: "Italian" },
  ];

// useEffect(()=>{
//     handleSave(text)
// },[text])

  let currentText = ""; // if you want record all audio even if you stoped and restart MediaRecorder, so you should set it as a global variable
  const recorder = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listAudioDevices();
  }, []);

  const startRec = () => {
    setRecord(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500); // Simulate starting recording after 2 seconds
    recorder.startRecording();
    setText("");
    setBlobURL("");
    setAudioChunks([]);
  };

  const stopRec = async () => {
    if (record && mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();

      // Stop all tracks of the media stream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      recorder.stopRecording();
    }
    // Close the WebSocket connection if it exists
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
    }
    setRecord(false);
    const blob = new Blob(audioChunks, { type: "audio/mp4" });
    const url = URL.createObjectURL(blob);
    setBlobURL(url);
    setAudioChunks([]); // Reset audioChunks here as well

    // Stop the audio if it's playing and reset its state
    if (audioRef && !audioRef.paused) {
      audioRef.pause(); // Pause the audio playback
      audioRef.currentTime = 0; // Reset the audio to the beginning
    }

    // Unload the audio source to remove the audio icon
    if (audioRef) {
      audioRef.removeAttribute("src"); // Remove the source
      audioRef.load(); // Reset the audio element to unload the source
    }
  };

  const deepGramAudio2text = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      let supportedMimeType = null;
      const mimeTypes = [
        "audio/webm",
        "audio/ogg",
        "audio/wav",
        "audio/mp4",
        "audio/mpeg",
      ];

      for (let mimeType of mimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          supportedMimeType = mimeType;
          break;
        }
      }

      if (!supportedMimeType) {
        return alert("No supported MIME type found.");
      }

      // Proceed with the recording setup
      mediaRecorder = new MediaRecorder(stream, {
        mimeType: supportedMimeType,
      });

      const url = `wss://api.deepgram.com/v1/listen?model=nova-2-general&smart_format=true&language=${language}`;
      socket = new WebSocket(url, ["token", DG_KEY]);

      socket.onopen = () => {
        if (!mediaRecorder) {
          console.error("MediaRecorder not initialized");
          return;
        }

        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
            setAudioChunks((prevChunks) => [...prevChunks, event.data]);
          }
        });

        mediaRecorder.start(1100);
        console.log("Recording started");
      };

      socket.onmessage = async (message) => {
        try {
          const received = JSON.parse(message.data);

          if (
            received.channel &&
            received.channel.alternatives &&
            received.channel.alternatives.length > 0
          ) {
            const transcript = received.channel.alternatives[0].transcript;

            if (transcript && received.is_final) {
              currentText = currentText.concat(" " + transcript);
              audioText = currentText;
              console.log("Current text:", audioText);
              setText(audioText);
            }
          }
        } catch (error) {
          console.error("Error processing message:", error);
        }
      };
    });
  };

  useEffect(() => {
    if (record) {
      deepGramAudio2text();
    }
  }, [record]);

  async function listAudioDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      if (audioInputDevices.length === 0) {
        console.log("No audio input device found");
        return;
      }
      const defaultDevice = audioInputDevices[0].deviceId;
      setInputDevice(defaultDevice);
    } catch (error) {
      console.error("Error listing audio devices:", error);
    }
  }

  const handleStart = () => {
    setTimer(0);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    setTimer(0);
    clearInterval(increment.current);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

//   useEffect(() => {
//     if (fileUrl) {
//       const payload = {
//         s3_file_url: fileUrl,
//       };
//       dispatch(startTranscriptionJobAction(payload)).then((actionResult) => {
//         const newJobId = actionResult.payload?.data?.job_id;
//         if (newJobId) {
//           setJobId(newJobId);
//         }
//       });
//     }
//   }, [fileUrl, dispatch]);

  // useEffect(() => {
  //   const fetchTranscriptionResults = async () => {
  //     if (jobId && !isPolling) {
  //       setIsPolling(true);
  //       const actionResult = await dispatch(
  //         getNotesAudioAction({ jobId: jobId })
  //       );
  //       const responseData = actionResult.payload?.data;

  //       if (responseData === "pending") {
  //         setTimeout(fetchTranscriptionResults, 15000);
  //       } else {
  //         setIsPolling(false);
  //         setIsTranscriptionProgress("finished");
  //         setGetNotesObject(responseData);
  //         if (responseData?.clinical_data) {
  //           setPhysicalExamination(
  //             responseData.clinical_data.HISTORY_OF_PRESENT_ILLNESS.join("\n")
  //           );
  //           setOtherNotes(
  //             [
  //               ...responseData.clinical_data.ASSESSMENT,
  //               ...responseData.clinical_data.PLAN,
  //             ].join("\n")
  //           );
  //           setSymptoms(
  //             [
  //               ...responseData.clinical_data.PAST_MEDICAL_HISTORY,
  //               ...responseData.clinical_data.CHIEF_COMPLAINT,
  //               ...responseData.clinical_data.REVIEW_OF_SYSTEMS,
  //             ].join("\n")
  //           );
  //           // setPatientHistory(responseData.clinical_data.PAST_MEDICAL_HISTORY.join('\n'));
  //         }
  //         if (responseData?.transcript_data) {
  //           const formattedTranscription = responseData?.transcript_data
  //             .map((text) => text.split(",").join("\n"))
  //             .join("\n");
  //           setTranscriptionResponse(formattedTranscription);
  //         }
  //       }
  //     }
  //   };

  //   fetchTranscriptionResults();

  //   return () => {
  //     setIsPolling(false);
  //   };
  // }, [jobId, dispatch]);

  useEffect(() => {
    if (open) {
      setAudioUrl(null);
    }
  }, [open]);

  useEffect(() => {
    console.log("isRecording", isStartRecording);
    if (!isStartRecording) {
      startRecording();
      handleStart();
    }

    return () => {
      stopRecording();
    };
  }, [isStartRecording]);

  const addAudioElement = async (blob) => {
    setChunkData([...chunkData, blob]);
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  // const copySymptomsToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(symptoms)
  //     .then(() => {
  //       console.log("Symptoms copied to clipboard!");
  //       toast.success("Symptoms copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       console.error("Could not copy symptoms: ", err);
  //       toast.error("Failed to copy symptoms!");
  //     });
  // };

  // const copyPhysicalExaminationToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(physicalExamination)
  //     .then(() => {
  //       console.log("Physical Examination copied to clipboard!");
  //       toast.success("Physical Examination copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       console.error("Could not copy physical examination: ", err);
  //       toast.error("Failed to copy physical examination!");
  //     });
  // };

  // const copyOtherNotesToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(otherNotes)
  //     .then(() => {
  //       console.log("Other Notes copied to clipboard!");
  //       toast.success("Other Notes copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       console.error("Could not copy other notes: ", err);
  //       toast.error("Failed to copy other notes!");
  //     });
  // };

  // const onFinishHealthScribe = () => {
  //   setIsTranscriptionProgress("start");
  //   handleClose();
  // };

  const getTranscriptionData = async (url) => {
    setIsTranscriptionProgress("inProgress");

    const responseTranscription = await axios.get(url, {
      headers: {
        "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
        "Content-Type": "application/json",
      },
    });
    if (responseTranscription.data.status === "done") {
      setIsTranscriptionProgress("finished");
      console.log(
        "transcription for file upload responseTranscription finish",
        responseTranscription
      );
      setFinalTranscript(
        responseTranscription?.data?.result?.transcription?.full_transcript
      );
      return responseTranscription;
    } else {
      getTranscriptionData(url);
      return responseTranscription;
    }
  };

  // const startTranscription = async () => {
  //   console.log("======strat transcription", audioBlobWhisper);
  //   if (audioBlobWhisper) {
  //     const url = URL.createObjectURL(audioBlobWhisper);
  //     console.log("======strat transcription 2", url);

  //     if (true) {
  //       setIsUploadingAudio(true);
  //       const formData = new FormData();
  //       formData.append("audio", audioBlobWhisper);
  //       try {
  //         //   const actionResult = await dispatch(uploadAudioAction(formData));
  //         const responseData = await axios.post(
  //           "https://api.gladia.io/v2/upload",
  //           formData,
  //           {
  //             headers: {
  //               "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
  //             },
  //           }
  //         );
  //         const newFileUrl = responseData?.data?.audio_url;

  //         console.log("newFileUrl", newFileUrl);
  //         if (newFileUrl) {
  //           setIsUploadingAudio(false);
  //           setIsTranscriptionProgress("inProgress");

  //           try {
  //             const response = await axios.post(
  //               "https://api.gladia.io/v2/transcription",
  //               { audio_url: newFileUrl },
  //               {
  //                 headers: {
  //                   "x-gladia-key": "5161c10e-3029-43e6-bcb9-7bc6727fd090",
  //                   "Content-Type": "application/json",
  //                 },
  //               }
  //             );
  //             if (response?.data?.result_url) {
  //               console.log("transcription for file upload", response);
  //               const responseTranscription = await getTranscriptionData(
  //                 response?.data?.result_url
  //               );
  //               console.log(
  //                 "transcription for file upload responseTranscription",
  //                 responseTranscription
  //               );
  //               if (responseTranscription.data.status === "done") {
  //                 setIsTranscriptionProgress("finished");
  //                 console.log(
  //                   "transcription for file upload responseTranscription finish",
  //                   responseTranscription
  //                 );
  //                 setFinalTranscript(
  //                   responseTranscription?.data?.result?.transcription
  //                     ?.full_transcript
  //                 );
  //               } else {
  //                 getTranscriptionData(response?.data?.result_url);
  //               }
  //             }
  //           } catch (error) {
  //             return Promise.reject(error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Upload failed:", error);
  //       } finally {
  //         setIsUploadingAudio(false);
  //       }
  //     }
  //   }
  // };

  // const handleFileSelect = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) {
  //     return;
  //   }
  //   addAudioElement(file);
  //   setAudioBlobWhisper(file);
  // };

  const stopRecording = () => {
    setRecording(false);
    setIsRecordingPaused(true);
    handleReset();
    setStop(true);
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        recorderRef.current = null;
      });
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStreamRef.current = null;
    }

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  };

  const pauseRecording = () => {
    if (recording === true) {
      setStop(true);
      recorderRef.current.pauseRecording();
      setIsRecordingPaused(true);
      clearInterval(playpauseButtonRef.current);
      handlePause();
      setRecording(false);
    } else {
      if (recorderRef.current) {
        setIsRecordingPaused(false);
        setStop(false);
        recorderRef.current.resumeRecording();
        handleResume();
        setRecording(true);
      } else {
        startRecording();
      }
    }
  };

  async function startRecording() {
    const gladiaUrl = "wss://api.gladia.io/audio/text/audio-transcription";
    const gladiaKey = "5161c10e-3029-43e6-bcb9-7bc6727fd090";

    socketRef.current = new WebSocket(gladiaUrl);

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: inputDevice
          ? {
              deviceId: { exact: inputDevice },
              echoCancellation: true,
              noiseSuppression: true,
            }
          : { echoCancellation: true, noiseSuppression: true },
      });
      audioStreamRef.current = mediaStream;

      const configuration = {
        x_gladia_key: gladiaKey,
        frames_format: "bytes",
        language_behaviour: "automatic single language",
        sample_rate: SAMPLE_RATE,
        desiredSampRate: SAMPLE_RATE,
      };

      socketRef.current.onopen = async () => {
        await socketRef.current.send(JSON.stringify(configuration));
        console.log("socket connected successfully!!!--@@@@--!!!");
        setRecording(true);
        setStop(false);

        ///geting response from socket
        socketRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data?.event === "transcript" && data.transcription) {
            if (data.type === "final") {
              setFinalTranscript((prev) => prev + data.transcription);
              setPartialTranscript("");
            } else {
              setPartialTranscript(data.transcription);
            }
          }
        };

        ///recording  setup  after socket is up
        recorderRef.current = await new RecordRTC(mediaStream, {
          type: "audio",
          mimeType: "audio/wav",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 1000,
          ondataavailable: async (blob) => {
            ///sending data to socket
            await socketRef.current.send(blob);
          },
          sampleRate: SAMPLE_RATE,
          desiredSampRate: SAMPLE_RATE,
          numberOfAudioChannels: 1,
        });
        recorderRef.current.startRecording();
        ////////////////
      };

      socketRef.current.onerror = () => {
        console.log("Couldn't connect to the server ther is something wrong");
        stopRecording();
      };
      socketRef.current.onclose = (event) => {
        console.log(`Server connection close: [${event.code}] ${event.reason}`);
        stopRecording();
      };
    } catch (error) {
      console.log(`Error_!!!!!!!!!: ${error.message || error}`);
      stopRecording();
    }
  }

  const sleep = (delay) => new Promise((f) => setTimeout(f, delay));
  const sendTranscript = async (data) => {
    try {
      setAddToEhrloading(true);
      const response = await apiClient.post(
        API_ENDPOINT.POST_TRANSCRIPT_SUMMARY,
        data,
        false
      );
      if (response) {
        postTranscriptCallback({
          infoResponse: response,
          transcript: text,
        });
        stopRecording();
        setAddToEhrloading(false);
        handleClose();
      }
      return response;
    } catch (error) {
      setAddToEhrloading(false);
      return Promise.reject(error);
    }
  };

  const handleBlur = () => {
    // Update the state with the content when the div loses focus
    setText(divRef.current.innerText);
  };

  // Function to save cursor position
  const saveCaretPosition = (el) => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null; // No selection range
    const range = selection.getRangeAt(0);
    return {
      startOffset: range.startOffset,
      endOffset: range.endOffset,
    };
  };

  // Function to restore cursor position
  const restoreCaretPosition = (el, positions) => {
    if (!positions) return; // Ensure positions are valid
    const selection = window.getSelection();
    const range = document.createRange();

    // Check if the element has child nodes and valid text content
    if (el.childNodes.length === 0 || !el.childNodes[0].nodeValue) return;

    // Ensure startOffset and endOffset do not exceed the node's text length
    const textLength = el.childNodes[0].nodeValue.length;
    const startOffset = Math.min(positions.startOffset, textLength);
    const endOffset = Math.min(positions.endOffset, textLength);

    range.setStart(el.childNodes[0], startOffset);
    range.setEnd(el.childNodes[0], endOffset);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleInput = () => {
    const caretPosition = saveCaretPosition(divRef.current);

    const currentText = divRef.current.innerText;
    setText(currentText);

    // Activate the button only when there's at least one character
    if (currentText.trim() !== "") {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }

    setTimeout(() => {
      restoreCaretPosition(divRef.current, caretPosition);
    }, 0);
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const renderRecorderPage = () => (
    <Box width={"60%"} margin={"0 auto"}>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "",
            overflowY: "auto", // Scroll if content overflows
          }}
        >
          <Box className="container">
            {recorder.mediaRecorder && !loading && record && (
              <LiveAudioVisualizer
                mediaRecorder={recorder.mediaRecorder}
                width={200}
                height={75}
              />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                gap: { xs: 2, sm: 2 }, // Adjust the gap between elements
                width: "100%",
              }}
            >
              <InputLabel
                id="dropdown-label"
                sx={{
                  marginRight: { sm: 2 },
                  width: { xs: "100%", sm: "auto" },
                  color:"#000",
                  fontFamily:FONT_FAMILY_GENERAL,
                  flexGrow: { xs: 0, sm: 0 }, // Avoid stretching on mobile
                }}
              >
                Select Language
              </InputLabel>
              <Select
                labelId="dropdown-label"
                value={language}
                // label="Select an option"
                onChange={handleChange}
                sx={{ width: { xs: "100%", sm: "auto" },fontFamily:FONT_FAMILY_GENERAL }} // Full width on small screens
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.value} value={lang.value}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <div className="real-time-interface" style={{fontFamily:FONT_FAMILY_GENERAL}}>
              <p id="modal-modal-title" style={{fontFamily:FONT_FAMILY_GENERAL}}>
                Click the button below and start speaking into your microphone
                to see your live transcription.
              </p>

              {loading ? (
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    marginTop: "3px",
                    display: "flex", // Use flexbox
                    alignItems: "center", // Align items vertically
                    justifyContent: "center", // Center content horizontally
                    gap: "10px", // Space between loader and text
                  }}
                >
                  <Bars
                    height="30"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    visible={true}
                  />
                  <p
                    id="modal-modal-title"
                    style={{
                      color: "#000",
                      marginTop: "0px", // Space between loader and text
                      fontSize: "14px",
                      fontFamily: FONT_FAMILY_GENERAL
                    }}
                  >
                    Speak after loading stops...
                  </p>
                </div>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#7ABA56",
                    color: "white",
                    height: "45px",
                    fontFamily:FONT_FAMILY_GENERAL
                  }}
                  sx={{ color: "#FFF",fontFamily:FONT_FAMILY_GENERAL }}
                  variant="contained"
                  onClick={record ? stopRec : startRec}
                  startIcon={record ? <StopIcon /> : <MicIcon />}
                >
                  {record ? "Stop recording" : "Start Talking"}
                </Button>
              )}
            </div>
            <br></br>
            {blobURL && (
              <div>
                <audio
                  ref={(element) => setAudioRef(element)}
                  src={blobURL ? blobURL : ""}
                  controls
                  preload="metadata"
                />
              </div>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            padding: 2,
            backgroundColor: "",
            height: "300px", // Fixed height

            overflowY: "auto", // Scroll if content overflows
          }}
          className="container"ß
        >
          <TranscribeBox
            contentEditable={true}
            suppressContentEditableWarning={true}
            ref={divRef}
            onKeyUp={handleInput}
            onBlur={handleBlur}
          >
            {text}
          </TranscribeBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2, // Space between text and buttons
            }}
          >
            <Button
              style={{
                backgroundColor: blobURL || btnActive ? "#7ABA56" : "#ccc", // Green when active, grey when disabled
                color: blobURL || btnActive ? "white" : "#666", // Light grey text when disabled
                marginRight: "10px",
                fontFamily:FONT_FAMILY_GENERAL
              }}
              sx={{ color: "#FFF", height: "45px" }}
              variant="contained"
              disabled={!blobURL && !btnActive}
              onClick={() => {
                handleSave(text)
              }}
            >
              {addToEhrloading ? (
                <CircularProgress size={24} color="inherit" /> // Show loader
              ) : (
                "Generate Report"
              )}
            </Button>

            <Button
              style={{ backgroundColor: "#7ABA56", color: "white",fontFamily:FONT_FAMILY_GENERAL }}
              sx={{ color: "#FFF", height: "45px",fontFamily:FONT_FAMILY_GENERAL }}
              variant="contained"
              onClick={() => {
                stopRec();
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );

  // const renderFinishedPage = () => (
  //   <ModalBodySecond>
  //     <TextAreaWrapper>
  //       <Label>Symptoms</Label>
  //       <LabelTextArea>
  //         <TextArea
  //           value={symptoms}
  //           onChange={(e) => setSymptoms(e.target.value)}
  //         />
  //         <CopySummaryButton onClick={copySymptomsToClipboard}>
  //           Copy
  //         </CopySummaryButton>
  //       </LabelTextArea>
  //     </TextAreaWrapper>
  //     <TextAreaWrapper>
  //       <Label>Physical Examination</Label>
  //       <LabelTextArea>
  //         <TextArea
  //           value={physicalExamination}
  //           onChange={(e) => setPhysicalExamination(e.target.value)}
  //         />
  //         <CopySummaryButton onClick={copyPhysicalExaminationToClipboard}>
  //           Copy
  //         </CopySummaryButton>
  //       </LabelTextArea>
  //     </TextAreaWrapper>
  //     <TextAreaWrapper>
  //       <Label>Other Notes</Label>
  //       <LabelTextArea>
  //         <TextArea
  //           value={otherNotes}
  //           onChange={(e) => setOtherNotes(e.target.value)}
  //         />
  //         <CopySummaryButton onClick={copyOtherNotesToClipboard}>
  //           Copy
  //         </CopySummaryButton>
  //       </LabelTextArea>
  //     </TextAreaWrapper>

  //     <EHRContainer>
  //       <FinishButton onClick={onFinishHealthScribe}>Add to EHR</FinishButton>
  //     </EHRContainer>
  //   </ModalBodySecond>
  // );

  const shouldShowLoader = () => {
    return isTranscriptionProgress === "inProgress";
  };

  return (
    // <Modal
    //   open={open}
    //   onClose={() => {
    //     handleClose();
    //   }}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
      <Box sx={style}>
        {/* <CloseButtonWrapper>
          {isTranscriptionProgress === "inProgress" ? (
            <StyledMinimizeIcon
              onClick={() => {
                handleClose();
              }}
            />
          ) : (
            <CloseButton
              src={closeIcon}
              onClick={() => {
                stopRec();
                handleClose();
              }}
            />
          )}
        </CloseButtonWrapper> */}
        {/* <ModalHeader id="modal-modal-title">Audio Scribe</ModalHeader> */}
        <>
          {isUploadingAudio && (
            <LoaderWrapper>
              <SimpleLoader text="Uploading Audio..." />
            </LoaderWrapper>
          )}
          {renderRecorderPage()}
          {shouldShowLoader() && (
            <LoaderWrapper>
              <SimpleLoader text="Transcription In Progress" />
            </LoaderWrapper>
          )}
        </>
      </Box>
    // </Modal>
  );
};

export default AudioRecordModal;
