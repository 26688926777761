import {
  Button,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.svg";
import { FC } from "react";

interface ConfirmationBoxProps {
  title: string;
  description: string;
  open: boolean;
  onConfirm: (status: string) => void;
  status: string;
  isLoading: boolean;
  closeHandler: () => void;
}

const ConfirmationBox: FC<ConfirmationBoxProps> = ({
  title,
  description,
  open,
  onConfirm,
  status,
  isLoading,
  closeHandler,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "20px", padding: "30px" } }}
    >
      <DialogTitle
        style={{
          justifyContent: "space-between",
          color: "#0099CC",
          margin: "20px 30px 0",
          fontWeight: "600",
        }}
      >
        {title}
        <img
          onClick={closeHandler}
          height={50}
          width={50}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "0",
            top: "2px",
          }}
          src={closeIcon}
          alt="close icon"
        />
      </DialogTitle>
      <DialogContent
        sx={{
          margin: "0px 30px 0",
        }}
      >
        {description}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "20px 30px",
        }}
      >
        <>
          <Button
            onClick={() => onConfirm(status)}
            sx={{
              color: "#FFF",
              minWidth: "150px",
              borderRadius: "8px",
              height: "44px",
            }}
            disabled={isLoading}
            variant="contained"
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>
          <Button
            onClick={closeHandler}
            sx={{ minWidth: "150px", borderRadius: "8px", height: "44px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationBox;
