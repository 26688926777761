import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import MetaInfo from "../MetaInfo/MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import { removeSpecialCharacters } from "../../../utilities/helper";
import "./InfoSection.css";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFileInfo,
  getInfoDetails,
  getScansInfo,
  getAttachedReport,
  updateStatus,
} from "../../../api/report";
import { toast } from "react-toastify";
// @ts-ignore
import { ShimmerContentBlock, ShimmerCategoryItem} from "react-shimmer-effects";
import { GlobalWorkerOptions } from "pdfjs-dist";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import RecordItem from "../RecordItem/RecordItem";
import { useSelector } from "react-redux";
import { ReportObj } from "../ShareReport/ShareReport.type";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../../store/store";
import UploadReportModal from "./UploadReport";
import { getThumbnailURL } from "../../../store/actions/records.action";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  StyledModal,
  StyledModalContent,
  StyledCloseButton,
} from "./style.components";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import ConfirmationBox from "./ConfirmationBox";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const InfoSection = () => {
  const location = useLocation();
  const { studyFhirId } = location.state || {};
  const [recordInfo, setRecordInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [scans, setScans] = useState([]);
  const navigate = useNavigate();
  const thumbnails = useSelector((state: any) => state?.thumbnail?.thumbnails);
  const [loadingAttachedReport, setLoadingAttachedReport] = useState(false);
  const [attachedReport, setAttachedReport] = useState<ReportObj[]>([]);
  const [storeData, setStoreData] = useState<ReportObj | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<ReportObj | null>(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const [isAddedNewRecords, setIsAddedNewRecords] = useState<boolean>(false);
  const [isShowChangeStatus, setShowChangeStatus] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingScanDetails, setLoadingScanDetails] = useState<boolean>(false);
  const [issStatusChangeLoading, setIsStatusChangeLoading] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchStudyInfo = async () => {
    setLoading(true);
    try {
      const response = await getInfoDetails(studyFhirId);
      if (response?.data?.status === "success") {
        setRecordInfo(response.data.data);
      }
    } catch (error) {
      console.log("Error occured while fetching info details of study or scan");
    } finally {
      setLoading(false);
    }
  };

  const fetchScanInfo = async () => {
    setLoadingScanDetails(true);
    try {
      const scanInfo = await getScansInfo(studyFhirId);
      if (scanInfo?.data?.status === "success") {
        
        setScans(scanInfo.data?.data || []);
      }
    } catch (error) {
      console.log("Error occured while fetching info details of study or scan");
    } finally {
      setLoadingScanDetails(false);
    }
  };

  useEffect(() => {
    if (studyFhirId && selectedPatientId) {
      fetchStudyInfo();
      fetchScanInfo();
    } else {
      navigate(`/patients/patientId?name=${selectedPatientId}`);
    }
  }, []);

  useEffect(() => {
    if(studyFhirId){
      fetchAttachedReport();
    }
  }, [isAddedNewRecords]);

  const fetchAttachedReport = async () => {
    setLoadingAttachedReport(true);
    try {
      const { data } = await getAttachedReport({
        study_id: studyFhirId,
        patient_id: selectedPatientId,
      });
      if (data && data?.status === "success") {
        const responseScansData = data?.data || [];
        setAttachedReport(responseScansData);
        fetchThubnailImages(responseScansData);
      } else {
        console.error("Failed to fetching attached report:", data.error);
      }
    } catch (error) {
      console.error("Failed to fetch attached report:", error);
    } finally {
      setLoadingAttachedReport(false);
    }
  };

  const onStatusAvailable = async (status: string) => {
    if (status) {
      setIsStatusChangeLoading(true);
      let data = {
        record_type: "report",
        id: storeData?.id || "",
        status: status || "",
      };
      try {
        const response = await updateStatus({ payload: data });
        if (response && response.data && response.data.status === "success") {
          const attachedReports = attachedReport.map((item) => {
            return item.id !== storeData?.id
              ? item
              : { ...item, status: status };
          });
          setAttachedReport([...attachedReports]);

          toast.success("Status updated Successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        } else {
          alert("Something went wrong!");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsStatusChangeLoading(false);
        setShowChangeStatus(false);
      }
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };

  const fetchThubnailImages = async (responseScansData: ReportObj[]) => {
    if (!responseScansData?.length) return;
    const filePaths = responseScansData
      .filter((item) => item?.file_path)
      .map((item) => ({
        file_path: item.file_path || "",
        fhir_id: item.id || "",
      }));
    filePaths.length &&
      dispatch(
        getThumbnailURL({
          file_objects: filePaths,
        })
      );
  };

  const handleBackNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: ReportObj
  ) => {
    if (e.target.value !== item?.status) {
      setShowChangeStatus(true);
      setStoreData({ ...item, status: e.target.value });
    }
  };

  const fetchReportUrl = async (filePath: string) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await getFileInfo(payload);
      if (response && response?.data?.status === "success") {
        return response.data?.data;
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  const handleListItemClick = async (
    e: React.MouseEvent<HTMLInputElement>,
    record: ReportObj
  ) => {
    e.stopPropagation();
    if (record?.file_path) {
      const url = await fetchReportUrl(record?.file_path);
      if (url) {
        setFileUrl(url);
        setSelectedRecord({ ...record, url: url });
        setOpen(true);
      }
    }
  };

  const renderAttachedReport = () => (
    <>
      {!loadingAttachedReport ? (
        attachedReport?.length ? (
          <Grid container gap={3} mt={4}>
            {attachedReport.map((item, index) => {
              return (
                <Box
                  style={{ width: 140, color: "#000" }}
                  display="flex"
                  flexDirection="column"
                >
                  <RecordItem
                    reportType={"report"}
                    fetchUrlForFile={(e: React.MouseEvent<HTMLInputElement>) =>
                      handleListItemClick(e, item)
                    }
                    setStoreData={setStoreData}
                    menuItems={[]}
                    row={item}
                    index={index}
                    thumbnailData={thumbnails}
                    isNotShowDropdown={true}
                    isShowDropdown={true}
                    width={135}
                  />
                  <StatusDropdown
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e, item)
                    }
                    selectedStatus={item?.status || ""}
                    dropdownOptions={dropdownOptions}
                  />
                </Box>
              );
            })}
          </Grid>
        ) : (
          <Box
            mt={6}
            mb={2}
            display={"flex"}
            alignItems="center"
            sx={{ color: "#000", fontSize: "13px" }}
            justifyContent="center"
          >
            Records not Attached.{" "}
          </Box>
        )
      ) : (
        <Box m={2}>
          <ShimmerContentBlock
            title
            text
            cta
            thumbnailWidth={100}
            thumbnailHeight={100}
          />
        </Box>
      )}
    </>
  );
  
  const handleHomeNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Patients",
      href: "/patients",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  return (
    <Box ml={5}>
      <Breadcrumb links={breadCrubLinks} />
      <TopHeader>Studies</TopHeader>
      <Box display="flex" mt={2} alignItems="stretch">
        <Grid item display="flex">
          <Paper
            className="box-wrapper"
            sx={{
              background: "#EBF7FB",
              borderRadius: { xs: "1rem 1rem 0 0", md: "1rem 0 0 1rem" },
              paddingBottom: 2,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="info-header"
              sx={{ borderTopLeftRadius: "1rem", padding: "1.2rem" }}
            >
              <Typography variant="h6" sx={{ color: "#FFF" }}>
                {recordInfo?.study_info?.patient_name &&
                  removeSpecialCharacters(recordInfo.study_info.patient_name)}
              </Typography>
              <Button
                sx={{
                  color: "#FFF",
                  border: "1px solid #FFF",
                  borderRadius: "15px",
                  padding: "5px 10px",
                }}
                onClick={handleOpenModal}
              >
                <AddIcon sx={{ color: "#FFF", marginRight: 1 }} /> Add Record
              </Button>
            </Box>
            <Box>
              {!loading ? (
                recordInfo ? (
                  <FileInfoSection recordInfo={recordInfo.study_info} />
                ) : null
              ) : (
                <Box
                  m={2}
                  sx={{
                    padding: "1rem",
                    borderRadius: "1rem",
                    background: "#FFF",
                  }}
                >
                  <ShimmerCategoryItem
                    hasImage
                    imageType="circular"
                    imageWidth={100}
                    imageHeight={80}
                    title
                  />
                </Box>
              )}

              {attachedReport.length ? (
                <Box
                  display="flex"
                  m={2}
                  p={2}
                  flexDirection="column"
                  sx={{ background: "#FFF", borderRadius: 4 }}
                >
                  <Box className="series-text" pl={2}>
                    Attached Report{" "}
                    {attachedReport.length ? `(${attachedReport.length})` : ""}
                    {renderAttachedReport()}
                  </Box>
                </Box>
              ) : null}
              <Box
                display="flex"
                m={2}
                p={2}
                flexDirection="column"
                sx={{ background: "#FFF", borderRadius: 4 }}
              >
                <Box className="series-text" mt={3} ml={2}>
                  Series { scans && scans.length ? `(${scans.length})` : ""}
                </Box>
                <Box display="flex">
                  {!loadingScanDetails ? (
                    <DisplaySeries recordInfo={scans} />
                  ) : (
                    <Box m={2}>
                      <ShimmerContentBlock
                        title
                        text
                        cta
                        thumbnailWidth={400}
                        thumbnailHeight={200}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item display="flex">
          <MetaInfo recordInfo={recordInfo} scans={scans} isLoading={loading} />
        </Grid>
      </Box>
      <UploadReportModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        studyFhirId={studyFhirId}
        setIsAddedNewRecords={setIsAddedNewRecords}
        isAddedNewRecords={isAddedNewRecords}
      />
      <StyledModal open={open} onClose={() => {}}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {fileUrl && selectedRecord?.content_type === "application/pdf" ? (
            <Worker workerUrl={workerUrl}>
              <Viewer fileUrl={fileUrl} />
            </Worker>
          ) : (
            fileUrl && (
              <img
                height={"90%"}
                width={"90%"}
                src={fileUrl}
                style={{
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            )
          )}
        </StyledModalContent>
      </StyledModal>
      {isShowChangeStatus ? (
        <ConfirmationBox
          title={"Are you Sure?"}
          description={
            storeData?.status === "RESTRICTED"
              ? "You are changing the report sharing status. The patient will lose access to the file."
              : "You are changing the report sharing status. The patient will get access to the report and will be notified."
          }
          closeHandler={() => {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
          }}
          open={isShowChangeStatus}
          onConfirm={onStatusAvailable}
          isLoading={issStatusChangeLoading}
          status={storeData?.status || ""}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default InfoSection;