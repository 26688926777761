import { Box } from "@mui/material";
import { FC } from "react";
import {
  ListItem,
  ListItemWrapper,
  ThumbImage,
  CardHeader,
  ThumbImages,
  ThumbText,
} from "../../../pages/Records";
import DropdownThreeDotMenu from "../../common/DropdownThreeDot/Dropdown";
import { useSelector } from "react-redux";
import {
  formatTimestamp,
  removeSpecialCharacters,
} from "../../../utilities/helper";

interface SingleReportProps {
  name?: string;
  icon?: string;
  test_type?: string;
  upload_date?: string;
  status?: string;
  id?: string;
}
const SingleReport: FC<SingleReportProps> = ({
  name,
  icon,
  test_type,
  upload_date,
  status,
  id
}) => {
  const thumbnails = useSelector((state: any) => state?.thumbnail?.thumbnails);

  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "1rem",
        border: "0.5rem solid #D7EFF7",
      }}
      display="flex"
      justifyContent="center"
    >
      <ListItemWrapper style={{ cursor: "auto", width: "140px" }}>
        <Box display="flex" justifyContent="space-between">
          <CardHeader>
            <span>{name && removeSpecialCharacters(name)}</span>
          </CardHeader>
          <DropdownThreeDotMenu menuItems={[]} />
        </Box>
        <ThumbImages>
          {icon && <ThumbImage src={(thumbnails && id && thumbnails[id]) || icon} alt="records-thumbnail" />}
          <ThumbText>
            <span>{test_type}</span>
            <span className="format">
              {test_type === "report" ? "PDF" : "dcm"}
            </span>
          </ThumbText>
        </ThumbImages>
        <div>
          <ListItem>{upload_date && formatTimestamp(upload_date)}</ListItem>
        </div>
        {status === "RESTRICTED" && (
          <Box
            sx={{
              color: "#CC8552",
              backgroundColor: "#FFF",
              border: "2px solid #CC8552",
              padding: "10px",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          >
            {" "}
            {status}{" "}
          </Box>
        )}
      </ListItemWrapper>
    </Box>
  );
};

export default SingleReport;
