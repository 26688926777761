import { FC } from "react";
import { Box, FormControl, InputAdornment, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export interface Option {
  label: string;
  value: string;
}

interface Props {
  handleChange: (event: any) => void;
  selectedStatus: string;
  dropdownOptions?: Option[];
  disabled?: boolean;
  isInsideNewScans?: boolean
}
const StatusDropdown: FC<Props> = ({
  handleChange,
  selectedStatus,
  dropdownOptions=[
    { label: "Available", value: "AVAILABLE" },
    { label: "Restricted", value: "RESTRICTED" },
  ],
  disabled = false,
  isInsideNewScans
}) => {
  return (
    <>
       <FormControl sx={{ width: "inherit"}}>
        <Select
          value={selectedStatus}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            paddingRight: 1,
            paddingTop: 0,
            height: "45px",
            pointerEvents: disabled ? "none" : "auto",
            color: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid  ${
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552 !important"
              }`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: selectedStatus !== 'RESTRICTED' ? '#7ABA56' : '#CC8552',
            },
            "& .MuiSvgIcon-root": {
              background:
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
              borderRadius: 1,
              color: "#FFF",
            },
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={`dropdown-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default StatusDropdown;
