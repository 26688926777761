import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { DEVICE, FOOTER_HEIGHT, HEADER_HEIGHT } from "../../../utilities/constants";

export const ShareContainer = styled.div<{ bgColor?: string }>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #0099CC;
  padding-top: 40px;
  cursor: pointer;
`;

export const ProfileWrapper = styled.div`
  margin-left: 24px;
`;

export const ProfileIcon = styled.img`
  margin: 24px;
`;
export const PatientName = styled.div`
  font-size: 16px;
  color: #0099cc;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  width: 170px;
  margin-bottom: 5px;
  word-break: break-all; 
`;

export const PatientGender = styled.div`
  font-size: 14px;
  color: #808080;
  margin-top: 7px;
  display: flex;
  gap: 8px;
`;

export const PatientDob = styled.div`
  font-size: 14px;
  color: #808080;
  margin-top: 7px;
  display: flex;
  gap: 8px;
`;

export const SideNavbar = styled.div`
  width: 260px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  height: calc(10px + 100vh);
  padding: 1rem;
  border-radius: 1rem;
  margin: 0px 0 0 48px;
`;

export const ArrowBackImage = styled.img``;

export const PhoneNumber = styled.span``;

export const PatientEmail = styled.div `
  font-size: 16px;
  margin-left: 30px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  width: 170px;
`;

export const EmailText = styled.div`
    font-size: 14px;
    color: #000000;
    max-width: 170px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;   
    word-break: break-all; 
`;

export const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 7px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 5px;
`;