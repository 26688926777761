import axios from "axios";
import Cookies from "js-cookie";
import { API_KEY, BASE_API_URL } from "../utilities/constants";

let axiosClient = null;

export const setupAxiosInstance = () => {
  const accessToken = Cookies.get("access_token");
  axiosClient = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      "X-API-KEY": API_KEY,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
    timeout: 5000000,
  });

  axiosClient.defaults.timeout = 90000;

  axiosClient.interceptors.response.use(
    (data) => {
      return data?.data;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error?.response?.data?.message);
    }
  );
};

const setAxiosHeaders = (isFormData) => {
  if (!axiosClient) {
    console.error("Axios client is not initialized. Please call setupAxiosInstance() before making any requests.");
    return;
  }

  if (isFormData) {
    axiosClient.defaults.headers.common = {
      ...axiosClient.defaults.headers.common,
      "Content-Type": "multipart/form-data",
    };
  } else {
    axiosClient.defaults.headers.common = {
      ...axiosClient.defaults.headers.common,
      "Content-Type": "application/json",
    };
  }
};

// All request will wait 7 seconds before timeout
export const get = async (URL, isFormData = false) => {
  setAxiosHeaders(isFormData);
  return await axiosClient.get(`${URL}`).then((response) => response);
};

export const post = async (
  URL,
  payload,
  isFormData = false,
  responseType = "json"
) => {
  setAxiosHeaders(isFormData);
  return await axiosClient
    .post(`${URL}`, payload, { responseType })
    .then((response) => response);
};

export const put = async (URL, payload, isFormData = false) => {
  setAxiosHeaders(isFormData);
  return await axiosClient.put(`${URL}`, payload).then((response) => response);
};

export const patch = async (URL, payload, isFormData = false) => {
  setAxiosHeaders(isFormData);
  return await axiosClient
    .patch(`${URL}`, payload)
    .then((response) => response);
};

export const _delete = async (URL, isFormData = false) => {
  setAxiosHeaders(isFormData);
  return await axiosClient.delete(`${URL}`).then((response) => response);
};

export const setAuthHeader = (token) => {
  if (axiosClient) {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

const apiClient = {
  post,
  get,
  patch,
  _delete,
  put,
  setAuthHeader,
};

export { apiClient };
