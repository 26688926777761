import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { NavigateFunction, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "@mui/material";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import NoData from "../components/common/UserMessages/NoData";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import {
  API_ENDPOINT,
  API_URL,
  COLOR_GREY,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";

const tableColumns = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "text",
    key: "file_name",
  },
  {
    type: "text",
    key: "patientCount",
  },
  {
    type: "text",
    key: "doctorCount",
  },
  {
    type: "patient",
  },
  // {
  //   type: "actionText",
  //   key: "appUserPhone",
  //   align: "left",
  // },
  {
    type: "text",
    key: "uploadDate",
    align: "left",
  },
  {
    type: "text",
    key: "labUserName",
    prefix: "Sent by",
  },
  {
    type: "actionButton",
    subType: "singleReportDetails",
    handler: () => { },
  },
  {
    type: "actionButton",
    subType: "view",
    handler: () => { },
  },
];

const styleTTextSmall = { color: "#808080", fontSize: "12px" };
const styleTATextSmall = {
  color: "#0099CC",
  fontSize: "14px",
  cursor: "pointer",
};
const tableColumnsSmall = [
  {
    type: "stack",
    columns: [
      {
        type: "text",
        key: "fileName",
        align: "left",
        styles: styleTTextSmall,
        maxChars: 30,
      },
      {
        type: "text",
        key: "uploadDate",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "patientCount",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "doctorCount",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "labUserName",
        prefix: "by",
        styles: styleTTextSmall,
      },
    ],
  },
  {
    type: "stack",
    columns: [
      {
        type: "actionButton",
        subType: "singleReportDetails",
        handler: () => { },
        small: true,
      },
      {
        type: "actionButton",
        subType: "view",
        handler: () => { },
        small: true,
      }
    ],
  },
];

const Container = styled.div`
  background-color: ${COLOR_SECONDARY};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const SectionHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: left;
  }
`;

const SearchSection = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${DEVICE.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const TableContainer = styled.div`
  @media ${DEVICE.laptop} {
    display: none;
  }
`;

const TableContainerSmall = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const Reports = () => {
  const [cookies] = useCookies();
  const [searchParams, setSearchParams] = useSearchParams();

  let searchParam = searchParams.get("search");

  const [reportsData, setReportsData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(searchParam || "");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInnerLoading, setIsInnerLoading] = useState<boolean>(false);
  const [pageToken, setPageToken] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  
  useEffect(() => {
    fetchReportsData()
  }, [])

  useEffect(() => {
    if (pageNumber > 1) {
      fetchReportsData();
    }
  }, [pageNumber]);

  useEffect(() => {
    setSearchTerm(searchParam ? searchParam : "");
  }, [searchParam]);

  const convertDataToTableFormat = (inputData: any[]) => {
    const outputData = inputData?.map((obj, i) => {
      return {
        key: obj["id"],
        appUserId: obj["id"] || "",
        fileName: obj["file_name"],
        logo: obj["thumbnail"],
        patientCount: obj["patient_count"],
        doctorCount: obj["doctors_count"],
        uploadDate: moment(obj["upload_date"])
          .local()
          .format("DD-MMM-YYYY   hh:mm a"),
        uploadDay: moment(obj["upload_date"]).local().format("DD-MMM-YYYY"),
        uploadTime: moment(obj["upload_date"]).local().format("hh:mm a"),
      };
    });
    return outputData;
  };

  const fetchReportsData = () => {

    const countPerPage = 10;
    const token = cookies["access_token"];
    let endpointUrl;
    if (pageNumber == 1) {
      endpointUrl = `${API_ENDPOINT.REPORTS}?limit=${countPerPage}`;
      setIsLoading(true);
    } else {
      endpointUrl = `${API_ENDPOINT.REPORTS}?limit=${countPerPage}&page_token=${pageToken}`;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (response.data.page_token && response.data.page_token != pageToken) {
          setPageToken(response.data.page_token);
        } else {
          setPageToken('');
        }
        const newReportsData = convertDataToTableFormat(
          response.data.data
        );
        if (pageNumber == 1) {
          setReportsData([...newReportsData]);
        } else {
          setReportsData([...reportsData, ...newReportsData]);
        }
      })
      .catch((error) => {
        console.log("error -> ", JSON.stringify(error, null, 2));
      })
      .finally(() => {
        setIsLoading(false);
        setIsInnerLoading(false);
      });
  };

  const handleNextFetch = () => {
    setIsInnerLoading(true);
    setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <Container>
        <HeaderRow>
          <SectionHeader>Reports</SectionHeader>
          <SearchSection>
            <HorizontalSep size={20} />
          </SearchSection>
        </HeaderRow>
        <TableContainer>
          {!isLoading ? (
            reportsData && reportsData.length ? (
              <>
                <CustomTable
                  tableData={reportsData}
                  tableColumns={tableColumns}
                  styles={{ marginTop: 30, maxHeight: "60vh" }}
                  isInnerLoading={isInnerLoading}
                  scrollHandler={handleNextFetch}
                  hasNext={pageToken ? true : false}
                />
              </>
            ) : (
              <NoData message={"No Reports"} />
            )
          ) : (
            <SimpleLoader />
          )}
        </TableContainer>
        <TableContainerSmall>
          {!isLoading ? (
            reportsData && reportsData.length ? (
                <CustomTable
                  tableData={reportsData}
                  tableColumns={tableColumnsSmall}
                  styles={{ marginTop: 30, maxHeight: "55vh" }}
                  isInnerLoading={isInnerLoading}
                  scrollHandler={handleNextFetch}
                  hasNext={pageToken ? true : false}
                />
            ) : (
              <NoData message={"No Reports"} />
            )
          ) : (
            <SimpleLoader />
          )}
        </TableContainerSmall>
      </Container>
      <VerticalSep size={20} />
    </>
  );
};

export default Reports;
