import React, { useEffect, useState } from "react";
import { FileDrop } from "react-file-drop";
import styled from "styled-components";

import uploadIcon from "../../../assets/upload-icon.svg";

// Only for minimal styles
import "./UploadFiles.css";
import { useDispatch } from "react-redux";
import { setShowStatus } from "../../../store/slice/statusSlice";

const FileDragBlock = styled.div`
  background-color: #ebf7fb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export interface UploadFilesProps {
  displayText: string;
  prefix: string;
  singleFile?: boolean;
  onChange?: (files: File[]) => void;
  filesSelected?: any;
  setFilesSelected?: any;
}

const UploadFiles = ({
  displayText,
  singleFile,
  prefix,
  filesSelected,
  setFilesSelected,
}: UploadFilesProps) => {
  const MAX_NO_OF_FILES = 5;
  const MAX_FILES_SIZE = 5000000;  // 5MB

  const dispatch = useDispatch();

  const onFilesUploaded = (files: FileList | null) => {
    console.log(" - - files - - ", typeof files);

    let fileSizeLimitExceeded = false;

    files && Array.from(files).forEach((fileItem: any, i: any) => {
      if (files[i].size > MAX_FILES_SIZE) {
        fileSizeLimitExceeded = true
        return;
      }
    })

    if (files) {
      if ([...filesSelected, ...Array.from(files)].length > MAX_NO_OF_FILES) {
        dispatch(
          setShowStatus({
            message: `Maximum ${MAX_NO_OF_FILES} files can be upload` || "Something went wrong!",
            severity: "error",
          })
        );
        return;
      } else {

        if (!fileSizeLimitExceeded) {
          setFilesSelected((filesSelected = []) => [
            ...filesSelected,
            ...Array.from(files),
          ]);
        } else {
          dispatch(
            setShowStatus({
              message: `Maximum file size limit of 5MB allowed` || "Something went wrong!",
              severity: "error",
            })
          );
        }
      }

    }
  };

  const fileDropHandler = (
    files: FileList | null,
    ev: React.DragEvent
  ): void => {

    let fileSizeLimitExceeded = false;

    files && Array.from(files).forEach((fileItem: any, i: any) => {
      if (files[i].size > MAX_FILES_SIZE) {
        fileSizeLimitExceeded = true
        return;
      }
    })

    if (files) {
      if ([...filesSelected, ...Array.from(files)].length > MAX_NO_OF_FILES) {
        dispatch(
          setShowStatus({
            message: `Maximum ${MAX_NO_OF_FILES} reports can be upload` || "Something went wrong!",
            severity: "error",
          })
        );
        return;
      } else {


        if (!fileSizeLimitExceeded) {
          setFilesSelected((filesSelected = []) => [
            ...filesSelected,
            ...Array.from(files),
          ]);
        } else {
          dispatch(
            setShowStatus({
              message: `Maximum file size limit of 5MB allowed` || "Something went wrong!",
              severity: "error",
            })
          );
        }

        // setFilesSelected((filesSelected = []) => [
        //   ...filesSelected,
        //   ...Array.from(files),
        // ]);
      }
    }
    console.log(ev);
  };

  return (
    <div style={{ flexGrow: 1, width: "100%", height: "100%" }}>
      <FileDrop
        className="fileUploadSection"
        onDrop={(files: FileList | null, ev: React.DragEvent) =>
          fileDropHandler(files, ev)
        }
      >
        <FileDragBlock>
          <input
            key={Math.random().toString(36) || ""}
            type="file"
            accept=".pdf"
            className="inputFile"
            name={prefix + "FileUpload"}
            id={prefix + "FileUpload"}
            multiple={singleFile ? false : true}
            onChange={(e) => onFilesUploaded(e.target.files)}
          />
          <label htmlFor={prefix + "FileUpload"} style={{ cursor: "pointer" }}>
            <div
              style={{
                minHeight: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ textAlign: "center", height: "100%" }}>
                <img src={uploadIcon} alt={"Upload Icon"} />
              </div>
              <div
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {displayText}
              </div>
            </div>
          </label>
        </FileDragBlock>
      </FileDrop>
    </div>
  );
};

export default UploadFiles;
