import { FunctionComponent, useState } from "react";
import CustomDialogLocal from "../components/common/Dialog/CustomDialogLocal";

interface ViewReportLocalProps {}

const ViewReportLocal: FunctionComponent<ViewReportLocalProps> = () => {
  const [itemBlobUrl, setItemBlobUrl] = useState<number | null>(1);
  return (
    <>
      {itemBlobUrl ? (
        <CustomDialogLocal
          itemBlobUrl={itemBlobUrl}
          itemName={""}
          closeDialog={() => {
            setItemBlobUrl(null);
          }}
        />
      ) : null}
      ;
    </>
  );
};

export default ViewReportLocal;
