import axios from "axios";
import { createAxiosConfig } from "../utilities/helper";
import { API_URL } from "../utilities/constants";

export const getRecipientInfo = async (
    payload: any,
  ): Promise<any> => {
    try {
      const url = `${API_URL}/share/users`;
      const response = await axios.post(url, payload , createAxiosConfig());
      return response;
    } catch (error) {
      return error;
    }
};