import { createSlice } from "@reduxjs/toolkit";

interface StatusState {
  showStatus: boolean;
  severity: "success" | "info" | "warning" | "error";
  message: string;
  autoHide: boolean | string;
  autoHideIn: number;
}


export const initialState: StatusState = {
  showStatus: false,
  severity: "warning",
  message: "Something went wrong",
  autoHide: true,
  autoHideIn: 3000,
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setShowStatus: (state, action) => {
      state.showStatus = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      state.autoHide = action.payload.autoHide !== undefined ? action.payload.autoHide : true;
    },
    unsetShowStatus: (state) => {
      state.showStatus = false;
    },
  },
});

export const { setShowStatus, unsetShowStatus } = statusSlice.actions;

interface RootState {
  status: StatusState;
}

export const statusSelector = (state: RootState) => state.status;

export default statusSlice.reducer;
