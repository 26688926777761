import AwsS3 from "@uppy/aws-s3";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Compressor from "@uppy/compressor";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import DropTarget from "@uppy/drop-target";
import GoldenRetriever from "@uppy/golden-retriever";
import RemoteSources from "@uppy/remote-sources";
import Transloadit from "@uppy/transloadit";
import Tus from "@uppy/tus";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useState } from "react";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./UppyUploadBox.css";
import StatusDropdown, { Option } from "../StatusDropdown/StatusDropdown";
import { Box, Checkbox, SelectChangeEvent } from "@mui/material";

const UPLOADER: "tus" | "s3" | "s3-multipart" | "xhr" = "tus" as
  | "tus"
  | "s3"
  | "s3-multipart"
  | "xhr";
const COMPANION_URL = "http://companion.uppy.io";
const TUS_ENDPOINT = "https://dev.uploader.hemehealth.app/files/";
const XHR_ENDPOINT = "";
const RESTORE = false;

declare global {
  interface Window {
    uppy: any;
  }
}

interface UppyUploaderProps {
  onUploadComplete: (response: any) => void;
  selectedStatus: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  dropdownOptions: Option[];
  handleDirectLinkChangeForScan: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  checked: boolean;
  directLink: boolean;
}

const UppyUploader: React.FC<UppyUploaderProps> = ({
  onUploadComplete,
  selectedStatus,
  handleChange,
  dropdownOptions,
  handleDirectLinkChangeForScan,
  checked,
  directLink,
}) => {
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);

  useEffect(() => {
    const uppyDashboard = new Uppy({})
      .use(Dashboard, {
        inline: true,
        target: "#uppy",
        showProgressDetails: true,
        // proudlyDisplayPoweredByUppy: true,
        width: "100%",
        height: 350,
      })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: [],
      })
      .use(Transloadit, {
        importFromUploadURLs: true,
        assemblyOptions: {
          params: {
            auth: { key: "ea5babaf8c8d4baeab822a7f48b55ad9" },
            template_id: "b25b2604b7a04a79b34c21acbec36663",
          },
        },
        waitForEncoding: true,
      })
      .use(DropTarget, {
        target: document.body,
      })
      .use(Compressor);

    switch (UPLOADER) {
      case "tus":
        uppyDashboard.use(Tus, { endpoint: TUS_ENDPOINT, limit: 6 });
        break;
      case "s3":
        uppyDashboard.use(AwsS3, { companionUrl: COMPANION_URL, limit: 6 });
        break;
      case "s3-multipart":
        uppyDashboard.use(AwsS3Multipart, {
          companionUrl: COMPANION_URL,
          limit: 6,
        });
        break;
      case "xhr":
        uppyDashboard.use(XHRUpload, {
          endpoint: XHR_ENDPOINT,
          limit: 6,
          bundle: true,
        });
        break;
      default:
    }

    if (RESTORE) {
      uppyDashboard.use(GoldenRetriever, { serviceWorker: true });
    }

    window.uppy = uppyDashboard;

    uppyDashboard.on("transloadit:complete", (assembly) => {
      const uploadedFiles = assembly?.uploads.map((upload) => ({
        file_id: upload.id,
        file_name: upload.name,
        mimetype: upload.mime,
        file_size: upload.size,
      }));

      if (onUploadComplete) {
        onUploadComplete({ assemblyID: assembly?.assembly_id, uploadedFiles });
      }
    });

    return () => {
      uppyDashboard.close();
    };
  }, []);

  return (
    <>
      <div id="uppy" className="uppy-uploader-container"></div>
      {uploadComplete && (
        <Box display="flex" alignContent="center">
          <Box mt={1}>
            <StatusDropdown
              selectedStatus={selectedStatus}
              handleChange={(e) => handleChange(e)}
              dropdownOptions={dropdownOptions}
            />
          </Box>
          {directLink && (
            <Box display="flex" alignItems="center" mb={2} ml={5} >
              <div>
                <Checkbox
                  checked={checked}
                  sx={{ padding: 0 }}
                  onChange={handleDirectLinkChangeForScan}
                />
              </div>
              <Box ml={1}>Allow Direct Link</Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default UppyUploader;
