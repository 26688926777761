import { NavigateFunction, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CustomTable from "../components/common/CustomTable/CustomTable";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UploadFiles from "../components/common/UploadFiles/UploadFiles";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import {
  API_ENDPOINT,
  API_URL,
  COLOR_GREY,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  COLOR_VARIANT,
  DEVICE,
} from "../utilities/constants";
import patientProfileImage from "../assets/patient-profile-image.svg";
import { Button, Pagination } from "@mui/material";
import SendReport from "../components/data/SendReport/SendReport";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import NoData from "../components/common/UserMessages/NoData";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { useSelector, useDispatch } from "react-redux";

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLOR_SECONDARY};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
    margin-top: 10px;
    text-align: left;
  }
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
  }
`;

const ProfileSection = styled.div`
  flex-grow: 4;
  @media ${DEVICE.mobileL} {
    margin-bottom: 20px;
  }
`;
const ProfileImage = styled.img`
  @media ${DEVICE.mobileL} {
    width: 80px;
  }
`;

const PhoneNumber = styled.div`
  color: #000000;
  font-size: 24px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
  }
`;

const UserName = styled.div`
  color: #808080;
  font-size: 24px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
  }
`;

const SearchSection = styled.div`
  flex-basis: 50%;
`;

const TableContainer = styled.div`
  @media ${DEVICE.laptop} {
    display: none;
  }
`;
const TableContainerSmall = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0px 0px 0px;
`;

const PaginationWrapperSmall = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
`;

const styleTTextSmall = { color: "#808080", fontSize: "12px" };
const styleTATextSmall = {
  color: "#0099CC",
  fontSize: "14px",
  cursor: "pointer",
};
const tableColumnsSmall = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "stack",
    columns: [
      // {
      //   type: "actionText",
      //   key: "appUserPhone",
      //   handler: (phone: string, navigate: NavigateFunction) => {
      //     navigate(`/patients/${phone}`);
      //   },
      //   align: "left",
      //   styles: styleTATextSmall,
      // },
      {
        type: "text",
        key: "fileName",
        align: "left",
        styles: styleTTextSmall,
        maxChars: 18,
      },
      {
        type: "text",
        key: "uploadDay",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "uploadTime",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "labUserName",
        prefix: "by",
        styles: styleTTextSmall,
      },
    ],
  },
  {
    type: "stack",
    columns: [
      {
        type: "actionButton",
        subType: "replace",
        handler: () => { },
        small: true,
      },
      {
        type: "actionButton",
        subType: "view",
        handler: () => { },
        small: true,
      },
      {
        type: "actionButton",
        subType: "whatsapp",
        handler: () => { },
        small: true,
      },
    ],
  },
];

const tableColumns = [
  {
    type: "key",
    key: "id",
  },
  {
    key: "thumbnail",
    type: "icon",
  },
  {
    type: "text",
    key: "fileName",
    maxChars: 60,
  },
  {
    type: "text",
    key: "uploadDate",
  },
  {
    type: "text",
    key: "labUserName",
    prefix: "Sent by",
  },
  {
    type: "actionButton",
    subType: "whatsapp",
    handler: () => { },
    user: "patient",
  },
  {
    type: "actionButton",
    subType: "view",
    handler: () => { },
  },
];

const tableData = [
  {
    reportId: 1,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
  {
    reportId: 2,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
  {
    reportId: 3,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
];
const SingleReport = () => {
  let { reportId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const reportsPerPage = 5;

  const [reportsData, setReportsData] = useState<any[]>([]);
  const [singleReportData, setSingleReportData] = useState<any>()
  const [fetchCount, setFetchCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reloadCount = useSelector((state: any) => state.reportsReload.count);

  let pageNumber = searchParams.get("page");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);

  const totalPages = Math.ceil(totalCount / reportsPerPage);

  useEffect(() => {
    fetchSingleReportData();
  }, [fetchCount, reloadCount, currentPage]);

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(parseInt(pageNumber));
    }
  }, [pageNumber]);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const convertDataToTableFormat = (inputData: any[]) => {
    const outputData = inputData.map((obj, i) => {
      return {
        key: obj["id"],
        // appUserId: obj["app_user"]["id"],
        // appUserName: obj["app_user"]["name"],
        // appUserPhone: obj["app_user"]["phone_number"],
        // labUserId: obj["lab_user"]["id"],
        // labUserName: obj["lab_user"]["name"],
        fileName: obj["file_name"],
        // thumbnail: obj["thumbnail_url"],
        uploadDate: moment(obj["upload_date"])
          .local()
          .format("DD-MMM-YYYY   hh:mm a"),
        uploadDay: moment(obj["upload_date"]).local().format("DD-MMM-YYYY"),
        uploadTime: moment(obj["upload_date"]).local().format("hh:mm a"),
      };
    });
    return outputData;
  };

  const fetchSingleReportData = () => {
    setIsLoading(true);
    const token = cookies["access_token"];
    const endpointUrl = `https://2977fdff-f840-423c-9d1a-84cd9b46057f.mock.pstmn.io/reports/${encodeURIComponent(
      reportId ? reportId : "1"
    )}`;
    console.log("endpointUrl -> ", endpointUrl)
    // const endpointUrl = `${API_URL}/lab_portal/reports/list?limit=${reportsPerPage}&user=patient&search=${encodeURIComponent(
    //   reportId ? reportId : ""
    // )}&page_number=${currentPage}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${API_ENDPOINT.REPORT}/f94074db-f5b6-4e0b-b9b6-519183bf9e62`

    axios
      .get(url, config)
      // .get(endpointUrl, config)
      .then((response) => {
        console.log("single report response -> ", JSON.stringify(response, null, 2));
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data
          ) {
            // alert("success")
            const newReportsData = convertDataToTableFormat(
              response.data.data
              );
              // setTotalCount(response.data.data.total_count);
              // setReportsData([...newReportsData]);
              
              // console.log("newReportsData -> ", JSON.stringify(newReportsData, null, 2));
          // setSingleReportData([...newReportsData]);
          setSingleReportData(response.data.data[0]);
        }
      })
      .catch((error) => {
        alert("fail")
        // console.log(error);
        console.log(JSON.stringify(error, null, 2))
        // setSingleReportData(
        //     {
        //       id: "1cf8bfbd-881d-45d1-8524-c2083d189a24",
        //       upload_date: "2023-05-17T10:22:24.781741+00:00",
        //       file_name: "614_4f58ecf2-9e1d-11ed-ade2-e23ee00f8e73.pdf",
        //       patient: {
        //         name: null,
        //         contacts: [
        //           {
        //             system: "phone",
        //             value: "+911111111"
        //           }
        //         ]
        //       },
        //       doctor: [
        //         {
        //           name: null,
        //           contacts: [
        //             {
        //               system: "phone",
        //               value: "+911111111"
        //             }
        //           ]
        //         },
        //         {
        //           name: null,
        //           contacts: [
        //             {
        //               system: "phone",
        //               value: "+911111111"
        //             }
        //           ]
        //         }
        //       ],
        //       performer: [
        //         {
        //           name: null,
        //           contacts: [
        //             {
        //               system: "phone",
        //               value: "+911111111"
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   )
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnPageChange = (event: any, value: number) => {
    setSearchParams({
      ...searchParams,
      name: getSearchParam("name") || "",
      page: value.toString(),
    });
  };

  return (
    <>
      <TopRow>
        <ProfileSection>
          <Container>
            <HeaderRow>
              <SectionHeader>{singleReportData?.file_name}</SectionHeader>
            </HeaderRow>
            <VerticalSep size={20} />
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <ProfileImage src={patientProfileImage} />
              </div>
              <HorizontalSep size={30} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PhoneNumber>{reportId}</PhoneNumber>
                <UserName>{getSearchParam("name")}</UserName>
              </div>
            </div> */}
          </Container>
        </ProfileSection>
        {/* <HorizontalSep />
        <SendReport
          onSentHandler={() => {
            setReportsData([]);
            setFetchCount((fetchCount) => fetchCount + 1);
          }}
          phone={reportId}
        /> */}
      </TopRow>
      <Container>
        <HeaderRow>
          <SectionHeader>Patient</SectionHeader>
        </HeaderRow>
        
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <div>
                <ProfileImage src={patientProfileImage} />
              </div> */}
              {/* <HorizontalSep size={30} /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PhoneNumber>{singleReportData?.patient.contacts[0].value}</PhoneNumber>
                <UserName>{singleReportData?.patient.name}</UserName>
              </div>
            </div> 
          
      </Container>
      <VerticalSep size={20} />
      <Container>
        <HeaderRow>
          <SectionHeader>Doctors</SectionHeader>
        </HeaderRow>
        {singleReportData?.doctors?.map((docItem: any) => {
          return(
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <div>
                <ProfileImage src={patientProfileImage} />
              </div> */}
              {/* <HorizontalSep size={30} /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PhoneNumber>{docItem.contacts[1].system}</PhoneNumber>
                <UserName>{docItem.contacts[1].value}</UserName>
              </div>
            </div> 
          )
        })}
      </Container>
      <VerticalSep size={20} />
      <Container>
        <HeaderRow>
          <SectionHeader>Performer</SectionHeader>
        </HeaderRow>
        {singleReportData?.performers?.map((performerItem: any) => {
          return(
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <div>
                <ProfileImage src={patientProfileImage} />
              </div> */}
              {/* <HorizontalSep size={30} /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PhoneNumber>{performerItem.contacts[1].system}</PhoneNumber>
                <PhoneNumber>{performerItem.contacts[1].value}</PhoneNumber>
              </div>
            </div> 
          )
        })}
      </Container>
      <VerticalSep size={20} />
    </>
  );
};

export default SingleReport;
