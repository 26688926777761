import React, { FunctionComponent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { ArrowBack } from "@mui/icons-material";
import { API_ENDPOINT, API_URL } from "../../../utilities/constants";
import SimpleLoader from "../Loaders/SimpleLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

interface CustomDialogProps {
  closeDialog: any;
  itemId: any;
  itemName: any;
  itemBlob?: any;
}

const CustomDialog: FunctionComponent<CustomDialogProps> = ({
  closeDialog,
  itemId,
  itemName,
  itemBlob,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [open, setOpen] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const tempNavigator: any = window.navigator;

  useEffect(() => {
    fetchUrlForFile(itemId, cookies["access_token"]);
  }, []);

  const fetchUrlForFile = (itemId: any, token: any) => {
    setIsLoading(true);
    const apiUrl = API_URL;
    const documentAPIUrl = `${API_ENDPOINT.REPORT}/${itemId}/view`;
    // alert(documentAPIUrl)

    const payload = {
      file_id: itemId,
      file_type: "REPORT",
    };

    fetch(documentAPIUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'X-API-KEY': '1234',
      },
      // body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response && response?.data) {
          setFileUrl(response.data);
        } else {
          console.log(response.status);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    closeDialog();
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={true}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBack
              onClick={handleClose}
              style={{ marginRight: "10px", cursor: "pointer" }}
            />{" "}
            {itemName}
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            {!isLoading ? (
              fileUrl ? (
                tempNavigator && tempNavigator.pdfViewerEnabled ? (
                  <embed src={fileUrl} width="100%" height="1500px" />
                ) : (
                  <iframe src={fileUrl} height="1000px"></iframe>
                )
              ) : (
                "Something went wrong..."
              )
            ) : (
              <SimpleLoader />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CustomDialog;
