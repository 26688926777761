import { FunctionComponent, useState } from "react";
import CustomDialog from "../components/common/Dialog/CustomDialog";

interface ViewReportProps {}

const ViewReport: FunctionComponent<ViewReportProps> = () => {
  const [itemId, setItemId] = useState<number | null>(1);
  return (
    <>
      {itemId ? (
        <CustomDialog
          itemId={itemId}
          itemName={""}
          closeDialog={() => {
            setItemId(null);
          }}
        />
      ) : null}
      ;
    </>
  );
};

export default ViewReport;
