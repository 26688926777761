import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png"
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import AtlassianDropdown from "../SearchDropdown/AtlassianDropdown";
import PatientAttechedDropdown from "../SearchDropdown/PatientAttechedDropdown";
import { useEffect, useState } from "react";
import DownArrow from "../../../../src/assets/down-arrow-circle.svg";
import CustomShareCard from "../SearchDropdown/CustomShareCard";
import axios from "axios";
import { API_URL } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import CustomOthersShareCard from "../SearchDropdown/CustomOthersShareCard";
import { setShowStatus } from "../../../store/slice/statusSlice";
import { useDispatch} from "react-redux";

export const Dropdownlist = styled.div`
  padding: 2px 10px;
  background: transparent;
  
`;
const ArrowImageInstance = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
`;
const ViewerButtonMenu = styled.button`
  background-color: #fff;
  border: 1px solid #E4E4E7;
  color:#808080;
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width:100%;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  // &:hover {
  //   img {
  //     transform: rotate(180deg);
  //   }
  //   .custom-modal {
  //     display: block;
  //   }
  // }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index:99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;

const SearnContainerMenu = styled.button`
  background-color: #fff;
  padding:0px;
  border: 1px solid #E4E4E7;
  color:#808080;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width:100%;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  // &:hover {
  //   img {
  //     transform: rotate(180deg);
  //   }
  //   .custom-modal {
  //     display: block;
  //   }
  // }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index:99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;
const SelectDoctorCard = styled.div`
// width:46%;
border:1px solid #E4E4E7;
border-radius:8px;
min-height: 370px;
.input-search-box{
  border-radius:8px 8px 0px 0px;
  background:#F8F8F8;
  padding:10px;
}
`;
const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;


const TextInput = styled.input`
  padding: 0 10px;
  font-size: 14px;
  box-sizing: border-box;
  width:100%;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 8px;
  height: 43px;
  outline: none;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  margin-left: 12px;
  font-size: 14px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null
  closeHandler: () => void;
  onDelete?: () => void;
  isLoading: boolean;
  isLoaderPopup?: boolean;
  isAutoPopulate: any;
  autoPopulateData: any;
  setIsautoPopulate: any;
  setautoPopulateData: any;
  setSelectedPatient: any;
  setSelectedDoctor: any;
  setSelectedDoctorItem: any;
  selectedDoctorItem:any;
  setConfirmLoading:any;
  confirlAll:any;
  isSendLoading:boolean;
  isAllowOthersDirectLink:boolean;
  setAllowOthersDirectLink:any;
  isDirectLinkOthersEnabled:boolean;
  scansData:any;
  patientId:any;
  fetchExistingRecipients:any;

}

interface Doctor {
  doctor_id: string;
  name: string;
  phone: string | null;
  email: string | null;
}

const ShareDoctorDialog = ({
  open,
  closeHandler,
  title,
  discription,
  onDelete,
  isLoading,
  isLoaderPopup,
  isAutoPopulate,
  autoPopulateData,
  setIsautoPopulate,
  setautoPopulateData,
  setSelectedPatient,
  setSelectedDoctor,
  setSelectedDoctorItem,
  selectedDoctorItem,
  setConfirmLoading,
  confirlAll,
  isAllowOthersDirectLink,
  setAllowOthersDirectLink,
  isDirectLinkOthersEnabled,
  scansData,
  patientId,
  fetchExistingRecipients

}: EditTestProps) => {

  const [selectedDoctors, setSelectedDoctors] = useState<any>([]);
  const [selectedDoctorsList, setSelectedDoctorsList] = useState<Doctor[]>([]);
  const [groups, setGroups] = useState<{ label: string; value: string, numberOfDoctors: number }[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupInfo, setGroupInfo] = useState<any>(null);
  const [isGroupOpen, setGroupOpen] = useState<boolean>(false);
  const [isSendLoading, setSendLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [cookies] = useCookies();

  const setShareDoctor = () => {
    
    const selectedDoctorsData=[...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]
    if( scansData.fhir_resource_status !== "RESTRICTED" && otherUsers.length > 0) {
      const shareAllOthers = otherUsers.map((item: any) => {
        const isNumWithCountryCode = /^\+[1-9]{1}[0-9]{3,14}$/.test(item?.contact_id)
        const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(item?.contact_id);
          let contact_id = item?.contact_id;

          if(isEmail){
            contact_id = item?.contact_id;
          }else if (!isNumWithCountryCode){
            contact_id = `+91${item?.contact_id}`
          }
        const token = cookies["access_token"];
        const payload = {
          patient_id: patientId,
          "contact_id": contact_id,
          report_type: 'scans',
          resource_id: scansData.fhir_resource_id,
          direct_viewer_link: isAllowOthersDirectLink,
          scan_info: {
            modality: [scansData?.modality_list],
            study_date: scansData?.study_timestamp,
            location: scansData?.location,
            description: scansData?.study_description,

          },
        };
        const Url = `${API_URL}/share/anyone`;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };


        return axios.post(Url, payload, config).then((response) => { return response }).catch((error) => error);
      });

      setSendLoading(true)
      return Promise.all(shareAllOthers).finally(() => {
        if(selectedDoctorsData.length > 0) {
          setSendLoading(true)
          let doctorIds = selectedDoctorsData.map(
            (doctor: any) => doctor.doctor_id || doctor.patient_fhir_id
          );
      
          setTimeout(() => {
            confirlAll(doctorIds).then((res:any) => {
              if ([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem] && [...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]?.length) {
                localStorage.setItem(
                  "lastShareDoctorItem",
                  JSON.stringify([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
                );
              }
              setConfirmLoading({})
              closeHandler()
          setSendLoading(false)
            })
          }, 2000);
        }else{
          fetchExistingRecipients().then((r:any)=>{
            console.log("fetch recipients");
            
            setConfirmLoading({})
            
        setSendLoading(false)
        setTimeout(() => { closeHandler()},1000);
          })
        }
      })

    }else if(selectedDoctorsData.length > 0) {
    setSendLoading(true)
    // setSelectedDoctor([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
    //setSelectedDoctorItem([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]);
    let doctorIds = selectedDoctorsData.map(
      (doctor: any) => doctor.doctor_id || doctor.patient_fhir_id
    );

    setTimeout(() => {
      confirlAll(doctorIds).then((res:any) => {
        if ([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem] && [...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]?.length) {
          localStorage.setItem(
            "lastShareDoctorItem",
            JSON.stringify([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
          );
        }
        setConfirmLoading({})
        closeHandler()
    setSendLoading(false)
      })
    }, 2000);
  }else{
  }

  };

  const setlastShareDoctor = (data: any) => {
    setSelectedDoctors(data);

  };

  useEffect(() => {
    if (open) fetchGroups();
  }, [open])


  const onRemove = (doctorId: any) => {
    console.log("doctorId",doctorId);
    
    const selectedDoctorItems = [...selectedDoctorsList]
    const index = selectedDoctorItems.findIndex(el => el.doctor_id === doctorId)
    if (index !== -1) {
      selectedDoctorItems.splice(index, 1);
      setSelectedDoctorsList(selectedDoctorItems);
    }
  }


  const handleGroupChange = async (groupId: any) => {
    setGroupOpen(false)
    setSelectedGroup(groupId);
    await fetchDoctors(groupId);
  };

  const fetchGroups = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (response.data.status === "success" && response.data.data) {
          const groupOptions = response.data.data.map((group: any) => ({
            label: group.group_name,
            value: group.group_id,
            numberOfDoctors: group.number_of_doctors
          }));
          setGroups(groupOptions);
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDoctors = async (groupId: string) => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group/${groupId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(endpointUrl, config);
      if (response.data.status === "success" && response.data.data) {
        setGroupInfo(response.data.data.group_info);
        setSelectedDoctorsList(response.data.data.doctors_list);
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [inputValue,setInputValue] = useState('')
  const [otherUsers,setOtherUsers] = useState<any>([])
  const [confirmOthersLoading, setOthersLoading] = useState<any>({});
  const [inputError, setInputError] = useState<any>({ mobile: "", email: "" });
  const handleChange = (e:any) => {
    const value = e.target.value
    setInputValue(value)
  };


  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // sx={{ padding: "100px" }}
    >



      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "5px 30px 0",
          fontWeight: "600"
        }}
      >
        {title}
        <img onClick={closeHandler} height={50} width={50} style={{ cursor: "pointer", position: "absolute", right: " 0", top: "2px" }} src={closeIcon} />
      </DialogTitle>
      <DialogContent
        style={{ justifyContent: "flex-start", margin: "0px 30px" }}
      >
        <div style={{ marginTop: isLoaderPopup ? "59px" : "", minHeight: "470px" }}>

          <Box display={"flex"} justifyContent={"space-between"}>
            <Box width={"46%"}>
            <SelectDoctorCard>
              <div className="input-search-box">
                <PatientAttechedDropdown
                  type={"doctor"}
                  isAutoPopulate={isAutoPopulate}
                  autoPopulateData={autoPopulateData}
                  setIsautoPopulate={setIsautoPopulate}
                  setautoPopulateData={setautoPopulateData}
                  setSelectedPatient={setSelectedPatient}
                  setSelectedDoctor={setSelectedDoctor}
                  setSelectedDoctorItem={(data) =>
                    setlastShareDoctor(data)
                  }
                  otherUsers={otherUsers}
                  setOtherUsers={setOtherUsers}
                  scansData={scansData}
                  patientId={patientId}
                  fetchExistingRecipients={fetchExistingRecipients}


                />
              </div>
            </SelectDoctorCard>
            </Box>
            <Box width={"46%"} margin={"0 10px"}>
            <SelectDoctorCard>
              <div className="input-search-box">
                <ViewerButtonMenu onClick={() => setGroupOpen(!isGroupOpen)}>
                  Select group of doctors
                  <ArrowImageInstance style={{ transform: isGroupOpen ? "rotate(180deg)" : "" }} src={DownArrow} />
                </ViewerButtonMenu>
              </div>

              <>
                {isGroupOpen && <div className="custom-modal">
                  {groups.map((group) => (
                    <Dropdownlist onClick={() => handleGroupChange(group.value)}>
                      <CustomShareCard isGroup={true} data={group} onRemove={onRemove}/>
                    </Dropdownlist>
                  ))}
                </div>}


                <>
                  {selectedGroup === "" || selectedDoctorsList.length === 0 && (
                    <p style={{ marginLeft: "20px" }}>No doctors present in this group</p>
                  )}
                </>

                <>
                  {groupInfo && !isGroupOpen && (
                    <div>
                      {/* <CustomShareCard isGroup={true} data={groupInfo}/> */}

                      {selectedDoctorsList.length === 0 ? (
                        <p>No doctors present in this group</p>
                      ) : (selectedDoctorsList.map((doctor) => (<Dropdownlist>
                        <CustomShareCard isGroup={false} data={doctor} onRemove={onRemove} />

                      </Dropdownlist>))
                      )}
                    </div>
                  )}
                </>

              </>

            </SelectDoctorCard>
            </Box>

            {/* <Box width={"46%"}>
            <Typography>Others (Temporary access)</Typography>
            <SelectDoctorCard>
              <div className="input-search-box">
                <SearnContainerMenu>
                <TextInput
                type="text"
                onChange={handleChange}
                placeholder="Phone number or e-mail"
                value={inputValue}
                style={{
                  display: "block",
                  border: "0px solid #E4E4E7"
                }}
              />
                  <Button disabled={!inputValue} onClick={()=>onAddOthers()} sx={{ color: "#FFF", minWidth: "60px", borderRadius: "0px",height:"43px",border:"0px"}} variant="contained">
                          Add
          </Button>

                </SearnContainerMenu>
              </div>
              {inputError && (inputError["mobile"]||inputError["email"]) &&  (
                  <ErrorMessage>{ inputError["mobile"]||inputError["email"]}</ErrorMessage>
                )}
              <>
                <>
                  {otherUsers  && (
                    <div>

                      {otherUsers.length === 0 ? (
                        <p style={{textAlign:'center'}}>No records</p>
                      ) : (otherUsers.map((doctor:any) => (<Dropdownlist>
                        <CustomOthersShareCard isConfimLoading={confirmOthersLoading[doctor.contact_id]} data={doctor} onRemove={onRemoveOthers} onConfirm={onConfirmOthers}/>
                      </Dropdownlist>))
                      )}
                    </div>
                  )}
                </>
              </>
            </SelectDoctorCard>
            </Box> */}
            

          </Box>
        </div>
      </DialogContent>
     
      { isDirectLinkOthersEnabled ? (
                    <Box display="flex" flexDirection={'column'} justifyContent={"center"} alignItems="center" mb={2} ml={5} mr={4}>
                      <Box display="flex" justifyContent={"center"} alignItems="center" ml={2} mr={4}>
                     <div>
                        <Checkbox
                          checked={isAllowOthersDirectLink}
                          sx={{ padding: 0 }}
                          onChange={() =>setAllowOthersDirectLink(!isAllowOthersDirectLink)}
                        />
                      </div>
                      <Box ml={1}>Allow Direct Link</Box>
                      </Box>
                      { otherUsers?.length && scansData.fhir_resource_status === "RESTRICTED"  ?( <Typography color={'red'} fontSize={14}>
  Note : You can share restricted files with only verified doctors. 
                     </Typography>)
                     :null}
                    </Box>
                  ):null}
      <DialogActions
        style={{
          justifyContent: "center",
          padding: "0px 20px 20px 10px",
          margin: "0px 30px",
        }}
      >
       
        {!isLoaderPopup && <>
          
          <Button disabled={isLoading || isSendLoading || (![...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem].length && !otherUsers.length) } onClick={setShareDoctor} sx={{ color: "#FFF", minWidth: "150px", borderRadius: "8px", height: "44px" }} variant="contained">
            {isSendLoading ? (
                          <CircularProgress size={26} color="inherit" />
                        ) : (
                          "Share"
                        )}
          </Button>
          
        </>
        }
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default ShareDoctorDialog;
