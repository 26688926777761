import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
  } from "@mui/material";
  import closeIcon from "../../../assets/close-icon.png"
  import SimpleLoader from "../Loaders/SimpleLoader";
  import styled from "styled-components";
  import { VerticalSep } from "../../styled/Gaps";
import InfoIcon from "@mui/icons-material/Info";
  
  const TestForm = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const TextFieldLabel = styled.div`
    color: #808080;
    font-size: 16px;
    margin-bottom: 10px;
  `;
  export interface EditTestProps {
    open: boolean;
    testId: string | null;
    title?: string | null | undefined;
    discription?: string | null 
    closeHandler: () => void;
    Icon?:any;
  }
  
  const InfoDialog = ({
    open,
    closeHandler,
    title,
    discription,
    Icon,
  }: EditTestProps) => {
    return (
      <Dialog
        fullWidth
        open={open}
        onClose={closeHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ padding: "100px" }}
      >
        <DialogTitle
          style={{
            justifyContent: "flex-start",
            color: "#0099CC",
            margin: "20px 30px 0",
            fontWeight:"600"
          }}
        >
          {title}
          <img onClick={closeHandler} height={50} width={50} style={{cursor:"pointer" ,  position: "absolute",right:" 0",top: "2px"}} src={closeIcon}  />
        </DialogTitle>
        <DialogContent
          style={{ display:"flex", justifyContent: "space-between",alignItems:"center", margin: "0px 30px" }}
        >
            <Box>
            <IconButton
                  aria-label="dicom"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    marginTop: "-2px",
                  }}
                >
                  <InfoIcon 
                    sx={{ color: "#30aed7", fontSize: "80px" }}
                  />
                </IconButton>
            </Box>
          <div>
              {discription}
          </div>
        </DialogContent>
        <VerticalSep size={10} />
      </Dialog>
    );
  };
  
  export default InfoDialog;
  