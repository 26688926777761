import moment from "moment";

export const truncateText = (str: string, num: number) => str.length > num ? str.slice(0, num) + "..." : str

export const capitalizeFirstLetter = (str: string) => `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;

export const validateContact = (contact_no: string) => {
    // const contactRegex = /^\+\d+$/;
    const contactRegex = /^\d{4,14}$/;
    return contactRegex.test(contact_no);
  };
  
  export const validateEmail = (email: any) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email.toLowerCase());
  };

  export function removeCountryCode91(phoneNumber: string) {
    // Remove '+91' country code, considering various formats
    return phoneNumber?.replace(/^(\+91|0|91)?(\s|-)?/, '');
  }

  export const removeSpecialCharacters = (str: string) => {
    if (!str) return "";
    return str.replace(/[^a-zA-Z0-9. ]/g, '');
  };

  export const formatTimestamp = (timestamp: string): string => {
    if (!timestamp) {
      return "";
    }
    return moment(timestamp).local().format("DD-MMM-YYYY, hh:mm a");
  };

  export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void => {
    let timeoutId: NodeJS.Timeout | null;
    return (...args: Parameters<T>) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  export const getCookie = (name: string) => {
    const cookies = document.cookie.split('; ');
    let cookieValue = null;
  
    cookies.forEach(cookie => {
      const [cookieName, value] = cookie.split('=');
      if (cookieName === name) {
        cookieValue = decodeURIComponent(value);
      }
    });
  
    return cookieValue;
}

export const createAxiosConfig = (): any => {
  const accessToken = getCookie("access_token");
  if (!accessToken) {
    throw new Error("Access token not found.");
  }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
}
  