import React, { FC, useState, MouseEvent } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface MenuItemProps {
  label: string;
  onClick: () => void;
}

interface Props {
  menuItems: MenuItemProps[];
  data?: any;
  setStoreData?: (data: any) => void;
  recordType?: string;
  bradcrumb_label?: string;
}

const DropdwonThreeDotMenu: FC<Props> = ({
  menuItems = [],
  data,
  setStoreData = (e) => {},
  recordType,
  bradcrumb_label
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setStoreData({ ...data, record_type: recordType,bradcrumb_label });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuClick = (event: MouseEvent<HTMLElement>, itemClick: () => void) => {
    event.stopPropagation();
    itemClick();
    setAnchorEl(null);
  };
  const isDisplayMenu = Boolean(menuItems.length) ? "block" : "none";
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="dynamic-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "#0099CC", cursor: "pointer", padding: "2px", display: isDisplayMenu }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="dynamic-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={`dropdown-${index}`}
            onClick={(e) => menuClick(e, item.onClick)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdwonThreeDotMenu;
