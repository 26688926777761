import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import logoWhite from "../assets/logo-horizontal-white.svg";
import PDFImagestep1 from "../assets/Step1.png";
import PDFImagestep2 from "../assets/Step2.png";
import PDFImagestep3 from "../assets/Step3.png";
import FirstImageStep2 from "../assets/PdfImageStep2.png";
import FirstImageStep3 from "../assets/PdfImageStep3.png";
import SecondImageStep1 from "../assets/PdfSecImageStep1.png";
import SecondImageStep2 from "../assets/PdfSecImageStep2.png";
import SecondImageStep3 from "../assets/PdfSecImageStep3.png";
import moment from "moment";
import { APP_BASE_URL } from "../utilities/constants";

<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.3/html2pdf.bundle.min.js"></script>;

function PatientReportPdf(
  { data, setPdf, downloadingImageData = [] }: any,
  ref: any
) {
  const {
    patient_details,
    lab_info,
    study_info,
    study_sharable_code,
    study_metadata,
  } = data;
  

  const splitPhoneNumber = (phone: string) => {
    const phoneNumber = phone.slice(-10);
    const countryCode = phone.slice(1, -10);
    return { phoneNumber, countryCode };
  };  

  const { phoneNumber, countryCode } = splitPhoneNumber(patient_details?.phone || "");

  const summaryRef: any = useRef<HTMLDivElement>();

  const styles: any = {
    mainContainer: {
      margin: "0 auto",
    },
    page: {
      background: "#fff",
      padding: "10px 25px",
      width: "750px",
      height:'1123px',
      fontFamily: "'Noto Sans Mono', monospace"

    },
    header: {
      marginBottom: "10px",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    headTitle: {
      margin: "0",
      fontSize: "20px",
      color: "#0099cc",
      fontWeight: "700"
    },
    simpletext: {
      color: "#000",
      fontSize: "10px",
      margin: "0px 0px 10px",
    },
    logoButton: {
      background: "#0099cc",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "10px",
      border: "0px solid",
    },
    headerBox: {
      color: "#0099CC",
      marginTop: "10px",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "bold"
    },
    headerBoxBlue: {
      background: "#0099cc",
      color: "#FFFFFF",
      fontWeight: "bold",
      marginTop: "10px",
      borderRadius: "6px 6px 0px 0px",
      padding: "10px 12px",  
      fontSize: "16px",
    },
    headerBoxBlueMeta: {
      background: "#0099cc",
      color: "#FFFFFF",
      fontWeight: "bold",
      marginTop: "40px",
      borderRadius: "6px 6px 0px 0px",
      padding: "10px 12px",  
      fontSize: "16px",
    },
    containerBox: {
      background: "#F5FBFC",
      padding: "15px 15px",
    },
    containerCard: {
      marginBottom: "5px",
    },
    cardtitle: {
      margin: "0px 0px 10px",
    },
    phoneCard: {
      borderLeft: "2px solid #ddd",
      paddingLeft: "30px",
    },
    customButton: {
      border: "2px solid #0099CC",
      color: "#0099cc",
      borderRadius: "5px",
      padding: "5px 8px",
      marginBottom: "5px",
      fontSize: "14px"
    },
    forPatients: {
      fontSize: "25px",
      textAlign: "center",
      margin: "10px",
      fontWeight: "bold",
    },
  };

  const optionsSummary = {
    margin: [2, 0],
    filename: "summary.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    enableLink: true,
  };

  const formatTimestamp = (timestamp: string | null | undefined): string => {
    return timestamp ? moment(timestamp).format('DD-MMM-YYYY, h:mm a') : '';
  };

  useImperativeHandle(ref, () => ({
    async onDownload() {
      summaryRef.current.style.display = "block";
      const pdfWorker = html2pdf().from(summaryRef.current).set(optionsSummary);
      const summaryBlob = await pdfWorker.outputPdf("blob");
      const summaryFile = new File([summaryBlob], "summary.pdf", {
        type: "application/pdf",
      });
      openPdfInNewTab(summaryFile);
    },
  }));
  
  const openPdfInNewTab = (file: any) => {
    const blobUrl = URL.createObjectURL(file);
    window.open(blobUrl, "_blank");
    summaryRef.current.style.display = "none";
    setPdf(false);
  };

  const renderImageFrames = (frames: any[]) => {
    const maxFramesPerPage = 25; // Assuming each page can hold 18 images
    const maxImagePages = 2; // Only 3 pages are available for images
    const totalFrames = maxImagePages * maxFramesPerPage;
  
    const framesToRender = frames.slice(0, totalFrames);
  
    const pages = Math.ceil(framesToRender.length / maxFramesPerPage);
  
    return Array.from({ length: pages }).map((_, pageIndex) => (
      <div key={pageIndex} style={styles.page}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}>
          {framesToRender.slice(pageIndex * maxFramesPerPage, (pageIndex + 1) * maxFramesPerPage).map((src, index) => (
            <img key={index} src={src} width={"150px"} height={"150px"} />
          ))}
        </div>
      </div>
    ));
  };  

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;700&display=swap');
          .pdf-container {
            font-family: 'Noto Sans Mono', monospace;
          }
        `}
      </style>
      <div ref={summaryRef} style={styles.mainContainer}>
        <div style={styles.page}>
          <div style={styles.header}>
            <div style={styles.container}>
              <div style={{ width: "60%" }}>
                <h4 style={styles.headTitle}>{lab_info.name}</h4>
                <p style={styles.simpletext}>
                  {lab_info?.address}
                  <br />
                  {""}
                </p>
              </div>
              <button style={styles.logoButton}>
                <img src={logoWhite} style={{ width: "170px" }} />
              </button>
            </div>
          </div>

          <div style={styles.containerCard}>
            <div style={styles.headerBoxBlue}>Your data is stored securely on HemeHealth</div>
            <div style={styles.containerBox}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ width: "60%" }}>
                  <h4 style={styles.cardtitle}>
                    {patient_details?.patient_name}{" "}
                    {patient_details?.patient_age}/
                    {patient_details?.patient_gender}
                  </h4>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Date & Time</b>:{" "}
                    {study_metadata?.study_datetime}
                    {/* <b>SOP Class UID</b> : X-Ray Angiographic Image Storage{" "} */}
                  </p>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Instance UID:</b>
                    {study_info?.study_id}
                  </p>
                </div>
                <div style={styles.phoneCard}>
                  <h3 style={{ margin: "0px 0px 10px", fontSize: "12px" }}>
                    Patient Login Info
                  </h3>
                  <div
                    style={{
                      ...styles.customButton,
                      wordWrap: "break-word",
                      width: "237px",
                      fontSize: "12px",
                      color: "#0099CC"
                    }}
                  >
                    {patient_details?.phone && !patient_details?.email && (
                      <>{phoneNumber}</>
                    )}
                    {patient_details?.email && !patient_details?.phone && (
                      <>{patient_details.email}</>
                    )}
                    {patient_details?.phone && patient_details?.email && (
                      <>
                        {phoneNumber}
                        <div
                          style={{
                            height: "1px",
                            margin: "5px 0",
                            backgroundColor: "#0099CC",
                          }}
                        ></div>
                        {patient_details.email}
                      </>
                    )}
                </div>
              </div>
              
              </div>
            </div>
          </div>

          <div style={styles.containerCard}>
            <div style={styles.headerBox}>How to Access?</div>
            <div style={{ textAlign: "center" }}>
              <p style={styles.forPatients}> Patient Copy</p>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  marginTop: '30px',
                  borderBottom: '1px solid #d3d3d3',
                  paddingBottom: '30px'
                }}
              >
                <div style={{ textAlign: 'center'}}>
                  <div style={{marginBottom: "20px"}}
                  >
                    <QRCode
                      size={130}
                      value={
                        phoneNumber
                          ? `https://patients.hemehealth.io/mobile/${phoneNumber}/${countryCode}`
                          : `https://patients.hemehealth.io/email/${patient_details?.email}`
                      }
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>1. Scan this QR Code</div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ width: '140px', height: 'auto', marginBottom: "20px" }}>
                    <img src={FirstImageStep2} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>2. Enter OTP</div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ width: '140px', height: 'auto', marginBottom: "20px" }}>
                    <img src={FirstImageStep3} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>3. View scan</div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '40px'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px' }}>1. Visit at…</div>
                  <div style={{ width: '240px', height: 'auto', marginBottom: "10px" }}>
                    <img src={SecondImageStep1} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '17px', fontWeight: 'bold' }}>Or visit <a
                        style={{
                          textDecoration: "underline",
                          color: "#0099cc",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ phoneNumber ? `https://patients.hemehealth.io/mobile/${phoneNumber}/${countryCode}`:
                        `https://patients.hemehealth.io/email/${patient_details?.email}`
                        
                      }
                      >
                        this link
                      </a>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px'  }}>2. Enter phone number and OTP</div>
                  <div style={{ fontSize: '23px', fontWeight: 'bold', color: '#0099CC', marginBottom: '10px' }}>{phoneNumber}</div>
                  <div style={{ width: '240px', height: 'auto'}}>
                    <img src={SecondImageStep2} style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
                
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px' }}>3. View scans</div>
                  <div style={{ width: '240px', height: 'auto'}}>
                    <img src={SecondImageStep3} style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <div className="html2pdf__page-break"></div> */}

        {/******************* * */}
        <div style={styles.page}>
        <div style={styles.header}>
            <div style={styles.container}>
              <div style={{ width: "60%" }}>
                <h4 style={styles.headTitle}>{lab_info.name}</h4>
                <p style={styles.simpletext}>
                  {lab_info?.address}
                  <br />
                  {""}
                </p>
              </div>
              <button style={styles.logoButton}>
                <img src={logoWhite} style={{ width: "170px" }} />
              </button>
            </div>
          </div>

          <div style={styles.containerCard}>
            <div style={styles.headerBoxBlue}>Your data is stored securely on HemeHealth</div>
            <div style={styles.containerBox}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ width: "60%" }}>
                  <h4 style={styles.cardtitle}>
                    {patient_details?.patient_name}{" "}
                    {patient_details?.patient_age}/
                    {patient_details?.patient_gender}
                  </h4>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Date & Time</b>:{" "}
                    {study_metadata?.study_datetime}
                    {/* <b>SOP Class UID</b> : X-Ray Angiographic Image Storage{" "} */}
                  </p>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Instance UID:</b>
                    {study_info?.study_id}
                  </p>
                </div>
                <div style={styles.phoneCard}>
                  <h3 style={{ margin: "0px 0px 10px", fontSize: "12px" }}>
                    Patient Login Info
                  </h3>
                  <div
                    style={{
                      ...styles.customButton,
                      wordWrap: "break-word",
                      width: "237px",
                      fontSize: "12px",
                      color: "#0099CC"
                    }}
                  >
                    {patient_details?.phone && !patient_details?.email && (
                      <>{phoneNumber}</>
                    )}
                    {patient_details?.email && !patient_details?.phone && (
                      <>{patient_details.email}</>
                    )}
                    {patient_details?.phone && patient_details?.email && (
                      <>
                        {phoneNumber}
                        <div
                          style={{
                            height: "1px",
                            margin: "5px 0",
                            backgroundColor: "#0099CC",
                          }}
                        ></div>
                        {patient_details.email}
                      </>
                    )}
                </div>
              </div>
              </div>
            </div>
          </div>

          <div style={styles.containerCard}>
            <div style={styles.headerBox}>How to Access?</div>
            <div style={{ textAlign: "center" }}>
              <p style={styles.forPatients}> Doctor Copy</p>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  marginTop: '30px',
                  borderBottom: '1px solid #d3d3d3',
                  paddingBottom: '30px'
                }}
              >
                <div style={{ textAlign: 'center'}}>
                  <div style={{marginBottom: "20px"}}
                  >
                    <QRCode
                      size={130}
                      value={`https://data.hemehealth.io/?accessCode=` + study_sharable_code}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>1. Scan this QR Code</div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ width: '140px', height: 'auto', marginBottom: "20px" }}>
                    <img src={PDFImagestep2} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>2. Enter OTP</div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ width: '140px', height: 'auto', marginBottom: "20px" }}>
                    <img src={FirstImageStep3} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>3. View scan</div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '40px'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px' }}>1. Visit at…</div>
                  <div style={{ width: '240px', height: 'auto', marginBottom: "10px" }}>
                    <img src={PDFImagestep1} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div style={{ fontSize: '17px', fontWeight: 'bold' }}>Or visit <a
                      style={{
                        textDecoration: "underline",
                        color: "#0099cc",
                        cursor: 'pointer'
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://data.hemehealth.io/?accessCode=` + study_sharable_code}
                    >
                      this link
                    </a>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px'  }}>2. Login via phone number<br/>and OTP, enter the access code</div>
                  <div style={{ fontSize: '23px', fontWeight: 'bold', color: '#0099CC', marginBottom: '10px' }}>{study_sharable_code}</div>
                  <div style={{ width: '240px', height: 'auto'}}>
                    <img src={PDFImagestep3} style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '17px', color: '#000000', fontWeight: 'bold', marginBottom: '10px' }}>3. View scans</div>
                  <div style={{ width: '240px', height: 'auto'}}>
                    <img src={SecondImageStep3} style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <div className="html2pdf__page-break"></div> */}
        {/* ******************** */}
        {/******************* * */}
        <div style={styles.page}>
        <div style={styles.header}>
            <div style={styles.container}>
              <div style={{ width: "60%" }}>
                <h4 style={styles.headTitle}>{lab_info.name}</h4>
                <p style={styles.simpletext}>
                  {lab_info?.address}
                  <br />
                  {""}
                </p>
              </div>
              <button style={styles.logoButton}>
                <img src={logoWhite} style={{ width: "170px" }} />
              </button>
            </div>
          </div>

          <div style={styles.containerCard}>
            <div style={styles.headerBoxBlue}>Your data is stored securely on HemeHealth</div>
            <div style={styles.containerBox}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ width: "60%" }}>
                  <h4 style={styles.cardtitle}>
                    {patient_details?.patient_name}{" "}
                    {patient_details?.patient_age}/
                    {patient_details?.patient_gender}
                  </h4>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Date & Time</b>:{" "}
                    {study_metadata?.study_datetime}
                    {/* <b>SOP Class UID</b> : X-Ray Angiographic Image Storage{" "} */}
                  </p>
                  <p style={styles.simpletext}>
                    {" "}
                    <b>Study Instance UID:</b>
                    {study_info?.study_id}
                  </p>
                </div>
                <div style={styles.phoneCard}>
                  <h3 style={{ margin: "0px 0px 10px", fontSize: "12px" }}>
                    Patient Login Info
                  </h3>
                  <div
                    style={{
                      ...styles.customButton,
                      wordWrap: "break-word",
                      width: "237px",
                      fontSize: "12px",
                      color: "#0099CC"
                    }}
                  >
                    {patient_details?.phone && !patient_details?.email && (
                      <>{phoneNumber}</>
                    )}
                    {patient_details?.email && !patient_details?.phone && (
                      <>{patient_details.email}</>
                    )}
                    {patient_details?.phone && patient_details?.email && (
                      <>
                        {phoneNumber}
                        <div
                          style={{
                            height: "1px",
                            margin: "5px 0",
                            backgroundColor: "#0099CC",
                          }}
                        ></div>
                        {patient_details.email}
                      </>
                    )}
                </div>
              </div>
              </div>
            </div>
          </div>
          <div style={styles.containerCard}>
            <div style={styles.headerBoxBlueMeta}>Meta Data</div>
            <div style={{ minHeight: "80vh" }}>
              <table width={"100%"}>
              <tbody>
                {[
                  { label: "Study Date And Time", value: study_metadata?.study_datetime },
                  { label: "Study - Id", value: study_metadata?.study_id },
                  { label: "Patient Name", value: study_metadata?.patient_name },
                  { label: "Protocol", value: study_metadata?.protocol },
                  { label: "Operator Name", value: "-" },
                  { label: "Study Date", value: study_metadata?.study_date },
                  { label: "Gender", value: study_metadata?.patient_gender },
                  { label: "Age", value: study_metadata?.patient_age },
                  { label: "Manufacturer", value: study_metadata?.manufacturer },
                  { label: "Institution Name", value: study_metadata?.institution_name },
                  { label: "Physician Name", value: study_metadata?.performing_physician_name }
                ].map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "1px solid #b3b2af",
                    }}
                  >
                    <td style={{ fontWeight: "bold", padding: "15px 20px" }}>{item.label}</td>
                    <td style={{ padding: "15px 20px"}}>:</td>
                    <td style={{ padding: "15px 20px"}}>{item.value}</td>
                  </tr>
                ))}
              </tbody>
              </table>
            </div>
          </div>
        </div>

        {renderImageFrames(downloadingImageData.flat())}
      </div>
    </>
  );
}

export default forwardRef(PatientReportPdf);
