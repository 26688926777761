import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReportsList, getThumbnails } from "../../api/report";
import { setShowStatus, unsetShowStatus } from "../slice/statusSlice";
import { getConfigs, getUploadRecords, pollRecords, sendRecords, splitPdf } from "../../api";

interface GetRecordsData {
  patient_id: string;
  report_type: string;
  limit: number;
  page_token?: string;
}

interface GetRecordsPayload {
  status: string;
  data: any;
}

interface GetRecordsResponse {
  type: string;
  payload: GetRecordsPayload;
}

interface SendRecordsData {
  type: string;
  payload: any;
}

export const getRecordsAction = createAsyncThunk<
  GetRecordsResponse,
  GetRecordsData,
  { rejectValue: string }
>("get-records", async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(
      setShowStatus({
        message: `Fetching records`,
        severity: "info",
        autoHide: "no",
      })
    );
    const response = await getReportsList(data);
    thunkAPI.dispatch(unsetShowStatus());
    return response;
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      thunkAPI.dispatch(
        setShowStatus({
          message: error.message || `Error while fetching ${data.report_type}`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error.message || `Error while fetching ${data.report_type}`
      );
    }
  }
});

interface ThumbnailResponse { 
  file_objects: {
    fhir_id: string | null;
    file_path: string | null;
  }[];
}

export const getThumbnailURL = createAsyncThunk(
  "get_thumbnail_image",
  async (data: ThumbnailResponse, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching thumbnail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getThumbnails(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get thumbnail",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get thumbnail"
      );
    }
  }
);

export const getUploadRecordsAction = createAsyncThunk(
  "get-records",
  async (assembly_id, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getUploadRecords(assembly_id);      
      thunkAPI.dispatch(unsetShowStatus());
      return response; // Return the data
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching record",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching record");
    }
  }
);

export const pollRecordsAction = createAsyncThunk(
  "poll-records",
  async (assembly_id, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await pollRecords(assembly_id);
      thunkAPI.dispatch(unsetShowStatus());
      return response; // Return the data
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching record",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching record");
    }
  }
);

export const splitPdfAction = createAsyncThunk(
  "split-pdf",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Splitting File`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await splitPdf(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while splitting a file",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while splitting a file");
    }
  }
);

export const sendRecordsAction = createAsyncThunk(
  "send-records",
  async (data: SendRecordsData, thunkAPI) => {
    try {
      const { type, payload } = data;
      
      if (!type || !payload) {
        console.error("Invalid data passed to sendRecordsAction:", { type, payload });
        throw new Error("Invalid data format for sending records.");
      }

      thunkAPI.dispatch(
        setShowStatus({
          message: `Sending Records`,
          severity: "info",
          autoHide: "no",
        })
      );

      const response = await sendRecords({ type, payload });
      
      thunkAPI.dispatch(
        setShowStatus({
          message: "Records added successfully.",
          severity: "success",
        })
      );

      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sending records",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while sending records");
    }
  }
);

export const getConfigsAction = createAsyncThunk(
  "get-configs",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching configs`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getConfigs();      
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching configs",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching configs");
    }
  }
);