import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRecordsAction } from "../actions/records.action";

// Define types for the state structure and payload
interface RecordData {
  data: any[]; // You can replace 'any' with a more specific type if known
  total: number;
}

interface RecordsState {
  isLoading: boolean | null;
  records: Record<string, RecordData & { patient_id: string | null }> | null;
}

// Initial state with type annotations
const initialState: RecordsState = {
  isLoading: null,
  records: null,
};

const recordsSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // get records
    builder.addCase(getRecordsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getRecordsAction.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        if (payload && payload.record_type) {
          const { record_type, data, patient_id } = payload;
          state.records = {
            ...state.records,
            [record_type]: {
              data: data.data || [],
              total: data.total || 0,
            },
            patient_id: patient_id || null,
          };
        }
      }
    );
    builder.addCase(getRecordsAction.rejected, (state) => {
      state.isLoading = false;
      state.records = null;
    });
  },
});

export const recordsSelector = (state: { record: RecordsState }) =>
  state.record?.records || {};
export default recordsSlice.reducer;
export const { resetState } = recordsSlice.actions;
