import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberValidation.css';

const PhoneNumberValidation = ({ onPhoneNumberChange, selectedCountry, initialPhoneNumber,disabled }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    setPhoneNumber(initialPhoneNumber || '');
  }, [initialPhoneNumber]);

  let countryOptions = {};
  let defaultCountry = 'in'; // Default country
  if (selectedCountry === 'IN') {
    countryOptions = {
      excludeCountries: ['gb'], // Exclude UK's country code if the selected country is India
      key: 'exclude-gb', // Unique key to force re-render
    };
  } else if (selectedCountry === 'GB') {
    countryOptions = {
      onlyCountries: ['gb'], // Include only UK's country code if the selected country is the UK
      preferredCountries: ['gb'],
      key: 'only-gb', // Unique key to force re-render
    };
    defaultCountry = 'gb'; // Set default country to United Kingdom when selectedCountry is GB
  }

  const handleChange = (value) => {
    setPhoneNumber(value);
    if (onPhoneNumberChange) {
      onPhoneNumberChange(value);
    }
  };

  return (
    <div>
        <PhoneInput
           {...countryOptions}
           country={defaultCountry}
           value={phoneNumber}
           onChange={handleChange}
           inputProps={{
             required: true,
           }}
           key={countryOptions.key}
           disabled={disabled?disabled:false}
        />
    </div>
  );
};

export default PhoneNumberValidation;