import { CircularProgress } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { COLOR_VARIANT } from "../../../utilities/constants";

interface SimpleLoaderProps {
  height?: string;
  size?: number;
  text?: string;
  color?: any;
}

const LoaderDiv = styled.div<{ height?: string }>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SimpleLoader: FunctionComponent<SimpleLoaderProps> = ({
  height,
  size,
  text,
  color
}) => {
  useEffect(() => {
    console.log(height);
  });
  return (
    <LoaderDiv height={height}>
      <div style={{ marginRight: "10px", color: COLOR_VARIANT }}>{text}</div>
      <CircularProgress size={size ? size : 30} color= {color || 'inherit'} />
    </LoaderDiv>
  );
};

export default SimpleLoader;
