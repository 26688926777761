import React, { useState, useEffect } from "react";
import IBackArrow from "../../../assets/back-arrow.svg";
import IProfileImage from "../../../assets/profile-image-small.svg";
import IDobIcon from "../../../assets/dob-icon.svg";
import IGenderIcon from "../../../assets/gender-icon.svg";
import IEmailIcon from "../../../assets/email.svg";
import IPhoneIcon from "../../../assets/phone.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SideNavbar,
  TopHeader,
  PatientName,
  ProfileWrapper,
  PatientGender,
  PatientDob,
  ProfileIcon,
  PhoneNumber,
  ArrowBackImage,
  PatientEmail,
  EmailImage,
  EmailItem,
  EmailText,
} from "./style.components";
import { useSelector } from "react-redux";
import { getPatientProfileAction } from "../../../store/actions/patients.action";
import { useAppDispatch } from "../../../store/store";
import moment from "moment";
import { Box } from "@mui/material";
// @ts-ignore
import { ShimmerCategoryItem } from "react-shimmer-effects";

const SideNav = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id") || "";
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const [selectedPatientId, setSelectedPatientId] = useState(
    getSearchParam("name") || ""
  );
  const patientDetails = useSelector(
    (state: any) => state?.patientProfile?.patientProfile
  );

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    if (selectedPatientId) {
      fetchPatientData();
    }
  }, [selectedPatientId]);

  const capitalizeFirstLetter = (string: string) => {
    if (!string || typeof string !== "string") return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchPatientData = async () => {
    try {
      if (selectedPatientId) {
        setLoading(true);
        await dispatch(getPatientProfileAction(selectedPatientId));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideNavbar>
      <TopHeader onClick={() => navigate("/home")}>
        <ArrowBackImage src={IBackArrow} alt="back-arrow" />
        Home
      </TopHeader>
      {!loading ? (
        <>
          {" "}
          <ProfileIcon src={IProfileImage} alt="Profile Icon" />
          <ProfileWrapper>
            {patientDetails?.name && (
              <PatientName>
                {capitalizeFirstLetter(patientDetails?.name)}
              </PatientName>
            )}
            {patientDetails?.phone_number && (
              <EmailItem>
                <EmailImage src={IPhoneIcon} alt="phone" />
                <EmailText>{patientDetails?.phone_number}</EmailText>
              </EmailItem>
            )}
            {patientDetails?.email && (
              <EmailItem>
                <EmailImage src={IEmailIcon} alt="email" />
                <EmailText>{patientDetails?.email}</EmailText>
              </EmailItem>
            )}
            {patientDetails?.gender && (
              <PatientGender>
                <img src={IGenderIcon} alt="Gender"></img>
                {patientDetails.gender}
              </PatientGender>
            )}
            {patientDetails?.dob && (
              <PatientDob>
                <img src={IDobIcon} alt="Gender"></img>
                {moment(patientDetails.dob).format("DD-MMM-YYYY")} (
                {moment().diff(
                  moment(patientDetails.dob, "YYYY-MM-DD"),
                  "years"
                )}{" "}
                Years)
              </PatientDob>
            )}
          </ProfileWrapper>
        </>
      ) : (
        <Box m={1}>
          <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={60}
            imageHeight={60}
            text
            cta
          />
        </Box>
      )}
    </SideNavbar>
  );
};

export default SideNav;
