import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png"
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import AtlassianDropdown from "../SearchDropdown/AtlassianDropdown";
import PatientAttechedDropdown from "../SearchDropdown/PatientAttechedDropdown";
import { useEffect, useState } from "react";
import DownArrow from "../../../../src/assets/down-arrow-circle.svg";
import CustomShareCard from "../SearchDropdown/CustomShareCard";
import axios from "axios";
import { API_URL } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import CustomOthersShareCard from "../SearchDropdown/CustomOthersShareCard";
import { setShowStatus } from "../../../store/slice/statusSlice";
import { useDispatch } from "react-redux";

export const Dropdownlist = styled.div`
  padding: 2px 10px;
  background: transparent;
  
`;
const ArrowImageInstance = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
`;
const ViewerButtonMenu = styled.button`
  background-color: #fff;
  border: 1px solid #E4E4E7;
  color:#808080;
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width:100%;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  // &:hover {
  //   img {
  //     transform: rotate(180deg);
  //   }
  //   .custom-modal {
  //     display: block;
  //   }
  // }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index:99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;

const SearnContainerMenu = styled.button`
  background-color: #fff;
  padding:0px;
  border: 1px solid #E4E4E7;
  color:#808080;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width:100%;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  // &:hover {
  //   img {
  //     transform: rotate(180deg);
  //   }
  //   .custom-modal {
  //     display: block;
  //   }
  // }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index:99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;
const SelectDoctorCard = styled.div`
// width:46%;
border:1px solid #E4E4E7;
border-radius:8px;
min-height: 370px;
.input-search-box{
  border-radius:8px 8px 0px 0px;
  background:#F8F8F8;
  padding:10px;
}
`;
const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;


const TextInput = styled.input`
  padding: 0 10px;
  font-size: 14px;
  box-sizing: border-box;
  width:100%;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 8px;
  height: 43px;
  outline: none;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  margin-left: 12px;
  font-size: 14px;
`;


const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  
  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);


export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null
  closeHandler: () => void;
  onDelete?: () => void;
  isLoading: boolean;
  isLoaderPopup?: boolean;
  isAutoPopulate: any;
  autoPopulateData: any;
  setIsautoPopulate: any;
  setautoPopulateData: any;
  setSelectedPatient: any;
  setSelectedDoctor: any;
  setSelectedDoctorItem: any;
  selectedDoctorItem: any;
  setConfirmLoading: any;
  confirlAll: any;
  isSendLoading: boolean;
  isAllowOthersDirectLink: boolean;
  setAllowOthersDirectLink: any;
  isDirectLinkOthersEnabled: boolean;
  scansData: any;
  patientId: any;
  fetchExistingRecipients: any;
  onFetchTeleRadiologyDetails:any;

}

interface Doctor {
  doctor_id: string;
  name: string;
  phone: string | null;
  email: string | null;
}

const ShareTeraRadiolgyDialog = ({
  open,
  closeHandler,
  title,
  discription,
  onDelete,
  isLoading,
  isLoaderPopup,
  isAutoPopulate,
  autoPopulateData,
  setIsautoPopulate,
  setautoPopulateData,
  setSelectedPatient,
  setSelectedDoctor,
  setSelectedDoctorItem,
  selectedDoctorItem,
  setConfirmLoading,
  confirlAll,
  isAllowOthersDirectLink,
  setAllowOthersDirectLink,
  isDirectLinkOthersEnabled,
  scansData,
  patientId,
  fetchExistingRecipients,
  onFetchTeleRadiologyDetails

}: EditTestProps) => {

  const [selectedDoctors, setSelectedDoctors] = useState<any>([]);
  const [selectedDoctorsList, setSelectedDoctorsList] = useState<Doctor[]>([]);
  const [groups, setGroups] = useState<{ label: string; value: string, numberOfDoctors: number }[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupInfo, setGroupInfo] = useState<any>(null);
  const [isGroupOpen, setGroupOpen] = useState<boolean>(false);
  const [isSendLoading, setSendLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [cookies] = useCookies();


const onSendTeleRadiology = async (doctoreData: any,comment:string,priority:string,scan_data:any) => {
  const token = cookies["access_token"];
  const endpointUrl = `${API_URL}/servicerequest`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const payload = {
      // "hospital_name": "Sample hospital Test two",
      // "full_name": "Test serv production",
      "subject_id": patientId,
      "subject_type": "Patient",
      "requester_type": "Organization",
      "requester_id": scan_data.lab_id,
      "performer_type": "Practitioner",
      "performer_id":doctoreData.patient_fhir_id,
      "study_url":scan_data.fhir_resource_id,
      "service_request_code": ["teleradiology"],
      "comment": comment,
      "priority": priority
  }

  try {
    const response = await axios.post(endpointUrl, payload, config);
    if (response) {
    dispatch(
     setShowStatus({
     message: "  Request for Tele-readiology sent successfully",
     severity: "success",
   })
   )

      onFetchTeleRadiologyDetails(response.data.fhir_id)

        closeHandler()
      setSendLoading(false)
    } else {
      alert("Something went wrong!!");
      closeHandler()
      setSendLoading(false)
    }
  } catch (error) {
    console.log(error);
    closeHandler()
    setSendLoading(false)
  }
};




  const setShareDoctor = () => {

    const selectedDoctorsData = [...selectedDoctors, ...selectedDoctorsList, ...selectedDoctorItem]
    console.log("sekhjkjhk");
    
     if (selectedDoctorsData.length > 0 && priority) {
      setPriorityError(false)
      setSendLoading(true)
      // setSelectedDoctor([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
      //setSelectedDoctorItem([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]);
      let doctorIds = selectedDoctorsData.map(
        (doctor: any) => doctor.doctor_id || doctor.patient_fhir_id
      );

      setTimeout(() => {
        // confirlAll(doctorIds).then((res: any) => {
        //   if ([...selectedDoctors, ...selectedDoctorsList, ...selectedDoctorItem] && [...selectedDoctors, ...selectedDoctorsList, ...selectedDoctorItem]?.length) {
        //     localStorage.setItem(
        //       "lastShareDoctorItem",
        //       JSON.stringify([...selectedDoctors, ...selectedDoctorsList, ...selectedDoctorItem])
        //     );
        //   }
        //   setConfirmLoading({})
       

onSendTeleRadiology(selectedDoctorsData[0],comment,priority,scansData)


         
        // })
      }, 2000);


    } else {
      setPriorityError(true)
    }

  };

  const setlastShareDoctor = (data: any) => {
    setSelectedDoctors(data);

  };

  useEffect(() => {
    if (open) fetchGroups();
  }, [open])


  const onRemove = (doctorId: any) => {
    console.log("doctorId", doctorId);

    const selectedDoctorItems = [...selectedDoctorsList]
    const index = selectedDoctorItems.findIndex(el => el.doctor_id === doctorId)
    if (index !== -1) {
      selectedDoctorItems.splice(index, 1);
      setSelectedDoctorsList(selectedDoctorItems);
    }
  }


  const handleGroupChange = async (groupId: any) => {
    setGroupOpen(false)
    setSelectedGroup(groupId);
    await fetchDoctors(groupId);
  };

  const fetchGroups = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (response.data.status === "success" && response.data.data) {
          const groupOptions = response.data.data.map((group: any) => ({
            label: group.group_name,
            value: group.group_id,
            numberOfDoctors: group.number_of_doctors
          }));
          setGroups(groupOptions);
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDoctors = async (groupId: string) => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group/${groupId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(endpointUrl, config);
      if (response.data.status === "success" && response.data.data) {
        setGroupInfo(response.data.data.group_info);
        setSelectedDoctorsList(response.data.data.doctors_list);
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [comment, setComment] = useState('')
  const [otherUsers, setOtherUsers] = useState<any>([])
  const [confirmOthersLoading, setOthersLoading] = useState<any>({});
  const [inputError, setInputError] = useState<any>({ mobile: "", email: "" });
  const handleChange = (e: any) => {
    const value = e.target.value.replace(/^\s+/, '')
    setComment(value)
  };

  const [priority, setPriority] = useState('');
  const [priorityError, setPriorityError] = useState(false);

  const handleChangePriority = (event: any) => {
    setPriorityError(false)
    setPriority(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    // sx={{ padding: "100px" }}
    >



      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "5px 30px 0",
          fontWeight: "600"
        }}
      >
        {title}
        <img onClick={closeHandler} height={50} width={50} style={{ cursor: "pointer", position: "absolute", right: " 0", top: "2px" }} src={closeIcon} />
      </DialogTitle>
      <DialogContent
        style={{ justifyContent: "flex-start", margin: "0px 30px" }}
      >
        <div style={{ marginTop: isLoaderPopup ? "59px" : "", minHeight: "470px" }}>

          <Box display={"flex"} justifyContent={"space-between"}>
            <Box width={"46%"}>
              <SelectDoctorCard>
                <div className={selectedDoctors?.length == 0 ? "input-search-box":""} >
                  <PatientAttechedDropdown
                    type={"doctor"}
                    isAutoPopulate={isAutoPopulate}
                    autoPopulateData={autoPopulateData}
                    setIsautoPopulate={setIsautoPopulate}
                    setautoPopulateData={setautoPopulateData}
                    setSelectedPatient={setSelectedPatient}
                    setSelectedDoctor={setSelectedDoctor}
                    setSelectedDoctorItem={(data) =>
                      setlastShareDoctor(data)
                    }
                    otherUsers={otherUsers}
                    setOtherUsers={setOtherUsers}
                    scansData={scansData}
                    patientId={patientId}
                    fetchExistingRecipients={fetchExistingRecipients}
                    isTeraRadiology={true}


                  />
                </div>
              </SelectDoctorCard>
            </Box>
            <Box width={"46%"} margin={"0 10px"}>
              <Box>
                <Box sx={{marginTop:'30px',marginBottom:'40px'}}>
                  <Typography color="#808080">Add priority</Typography>
                  <FormControl sx={{ m: 1, minWidth: '70%' }} size="small">
                    <InputLabel id="demo-select-small-label">Select priority</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={priority}
                      label="Select priority"
                      onChange={handleChangePriority}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}

                      <MenuItem value={"routine"}>Routine</MenuItem>
                      {/* <MenuItem value={"asap"}>Asap</MenuItem> */}
                      <MenuItem value={"urgent"}>Urgent</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography color="red" sx={{marginLeft:'10px'}}>{priorityError?"Required field":null}</Typography>
                </Box>


                <Box>
                  <Typography color="#808080" >Comments</Typography>
                  <FormControl sx={{ m: 1, width: '70%', height: '200px' }}>
                    <Textarea  minRows={8} 
                    maxRows={8}
                    onChange={handleChange}
                    value={comment}
                    style={{borderColor:"#E4E4E7",
                      maxHeight: '200px',  // Set max height to allow scrolling
        overflowY: 'auto',   // Enable vertical scroll when content exceeds maxHeight
        resize: 'none' ,
        textIndent: '0',

                    }}
                    
                    />
                  </FormControl>
                </Box>


              </Box>
            </Box>

          </Box>
        </div>
      </DialogContent>

      {/* {isDirectLinkOthersEnabled ? (
        <Box display="flex" justifyContent={"center"} alignItems="center" mb={2} ml={5} mr={4}>
          <div>
            <Checkbox
              checked={isAllowOthersDirectLink}
              sx={{ padding: 0 }}
              onChange={() => setAllowOthersDirectLink(!isAllowOthersDirectLink)}
            />
          </div>
          <Box ml={1}>Allow Direct Link</Box>
        </Box>
      ) : null} */}
      <DialogActions
        style={{
          justifyContent: "center",
          padding: "0px 20px 20px 10px",
          margin: "0px 30px",
        }}
      >

        {!isLoaderPopup && <>

          <Button disabled={isSendLoading || (![...selectedDoctors, ...selectedDoctorsList, ...selectedDoctorItem].length && !otherUsers.length)} onClick={setShareDoctor} sx={{ color: "#FFF", minWidth: "150px", borderRadius: "8px", height: "44px" }} variant="contained">
            {isSendLoading ? (
              <CircularProgress size={26} color="inherit" />
            ) : (
              "Share"
            )}
          </Button>

        </>
        }
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default ShareTeraRadiolgyDialog;
