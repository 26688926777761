import { Box, Button, Card, CardContent, CardHeader, IconButton, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import styled from "styled-components";
// import { ShimmerTable } from "react-shimmer-effects";
import { visuallyHidden } from "@mui/utils";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UserDropdown from "../components/common/UserDropdown/UserDropdown";
import NoData from "../components/common/UserMessages/NoData";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import IProfileImageSmall from "../assets/profile-image-small.svg";
import { setSelectedPatient } from "../store/slice/patient.slice";
import {
  API_URL,
  FONT_FAMILY_GENERAL,
  DEVICE,
} from "../utilities/constants";
import IDobIcon from '../assets/dob-icon.svg';
import IGenderIcon from '../assets/gender-icon.svg';
import editIcon from "../assets/edit-icon.svg";
import IEmailIcon from "../assets/email.svg";
import IPhoneIcon from "../assets/phone.svg";
import TableViewIcon from "../assets/tableViewIcon";
import CardViewIcon from "../assets/cardViewIcon";
import ShortingArrow from "../assets/shortingArrow";
import { KeyboardArrowRight } from "@mui/icons-material";
import IAddIconWhite from "../assets/add-icon-white.svg";

const Container = styled.div`
  flex-grow: 1;
  padding: 0px 20px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
  display: flex;
  width: 100%;
`;
const HeaderLeft = styled.div`
  display: flex;
  width: 20%;
`;
const PatientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;
const PatientCard = styled.div`
  width: 330px;
  min-height: 129px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;
const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;
const EditText = styled.div`
  font-size: 16px;
  color: #7ABA57;
`;
const PhoneNumber = styled.div`
  font-size: 14px !important;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px
`;
const EmailText = styled.div`
    font-size: 14px !important;
    color: #000000;
    max-width: 200px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
`;

const Name = styled.div`
  font-size: 16px;
  color: #0099CC;
  margin-bottom: 4px;

`;
const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;
`;
const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
`;
const EmptyListMessage = styled.div`
  margin-top: 100px;
  color: red;
  display: flex;
  justify-content: center;
`;
const PatientInfo = styled.div`
`;
const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;

const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 3px;
`;
const AddTempWrapper = styled.button`
  width: 216px;
  height: 45px;
  background: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SearchSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const SearchTempWrapper = styled.div`
  flex-basis: 100%;
  @media ${DEVICE.laptop} {
    width: 100%;
  }
`;
const GenderImage = styled.img`
`;
const DobImage = styled.img`
`;
const EmailImage = styled.img`
  height: 14px;
  margin-top: 4px;
`;
const EditIcon = styled.img`
`;
const GenderText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
const DobText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
const DobDate = styled.span`
`;
const DobYears = styled.span`
  color: #808080;
  font-family: ${FONT_FAMILY_GENERAL};
`;


const Tabs = styled.span`
 display: flex; 
 justify-content: center;
 align-items: center;
  background: #ddebed;
  width: 118px;
  height: 50px;
  border-radius:8px;
`;

const TabButton = styled.span`
padding-top:3px;
 display: flex;
 align-items: center;
 justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.5s;
  width: 55px;
  height: 42px;
  border-radius:5px;
  text-align: center;
  &.active {
     background-color: #FFFFFF;
  }

`;
const UserName = styled.p`
   color: #0099CC; 
    overflow: hidden;
    text-transform:capitalize;
  margin: 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  font-weigth: 400px;
  cursor:pointer;
`;

const AddIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const AddText = styled.div`
  font-size: 16px;
  color: #FFFFFF;
  margin-left: 5px;
`;

const capitalizeFirstLetter = (string: any) => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Patients = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const patientsPerPage = 10;
  const initialUrl = `/patient?limit=${patientsPerPage}`;
  const pageNumber = searchParams.get("page");
  const searchParam = searchParams.get("search");
  const dispatch = useDispatch();
  const [patientsData, setPatientsData] = useState<any>([]);
  const [patientsDataCards, setPatientsDataCards] = useState<any>([]);
  const [patientsSortedData, setPatientsSortedData] = useState<any>([]);


  const [searchTerm, setSearchTerm] = useState(searchParam || "");
  const [endpointUrl, setEndpointUrl] = useState(initialUrl);
  const [nextUrl, setNextUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isInnerLoading, setIsInnerLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [pageNumberNew, setPageNumberNew] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false)
  const [activeTab, setActiveTab] = useState("card");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isSort, setIsSort] = useState(false);


  const totalPages = Math.ceil(totalCount / patientsPerPage);

  useEffect(() => {
    fetchPatientsData();
  }, [endpointUrl, searchTerm]);

  useEffect(() => {
    setSearchTerm(searchParam || "");
    // setCurrentPage(1);
  }, [searchParam]);

  useEffect(() => {
    const tabView = localStorage.getItem('patientListView')
    if (tabView === 'table') {
      setActiveTab('table')
    } else {
      setActiveTab('card')
    }
  }, [])

  const convertDataToTableFormat = (inputData: any) => {
    const outputData = inputData.map((obj: any, i: number) => {
      return {
        id: obj["id"],
        patient_fhir_id: obj["patient_fhir_id"],
        appUserPhone: obj["phone_number"],
        appUserName: obj["name"],
        gender: obj["gender"],
        dob: obj.dob == null ? null : moment(obj["dob"])
          .local()
          .format("DD-MMM-YYYY"),
      };
    });
    return outputData;
  };

  const handleOnSearchChange = (textValue: any) => {
    if (textValue !== searchTerm) {
      setPatientsData([])
      setPatientsDataCards([])
      setSearchParams({ page: "1", search: textValue });
    }
  };

  // const handlePatientClick = (id, phone_number, name, gender, dob) => {
  //   navigate("/patient-records");
  // };

  const fetchPatientsData = () => {
    setIsLoading(true)
    const token = cookies["access_token"];
    const url = `${API_URL}/patient?search=${encodeURIComponent(
      searchTerm
    )}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data
        ) {
          const newPatientData = response.data.data;
          setPatientsData([...newPatientData]);
          setPatientsDataCards([...newPatientData])
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
    localStorage.setItem('patientListView', tab)
  };


  interface Data {
    id: string;
    name: string;
    gender: string;
    age: any;
    email:string
    phone_number: number;




  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: Array<any>, comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map(
      (el: any, index: any) => [el, index] as [T, number]
    );
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isSorting?: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Patient Name",
      isSorting: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      isSorting: false,
    },
    {
      id: "gender",
      numeric: false,
      disablePadding: false,
      label: "Gender",
      isSorting: false,
    },
    {
      id: "age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      isSorting: false,
    },
    {
      id: "phone_number",
      numeric: true,
      disablePadding: false,
      label: "Phone number",
      isSorting: false,
    }

  ];

  interface EnhancedTableProps {
    // numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setIsSort(true);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patientsData.length) : 0;


  useEffect(() => {
if(isSort){
  const visibleRows = stableSort(
    patientsData, getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log("visibleRows", patientsData, visibleRows);

  setPatientsSortedData(visibleRows);
}else{
  const visibleRows = patientsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log("visibleRows", patientsData, visibleRows);

  setPatientsSortedData(visibleRows);
}
   
  }, [order, orderBy, page, rowsPerPage, patientsData,isSort])


  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      // onSelectAllClick,
      order,
      orderBy,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };


    return (<>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {headCell.isSorting ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={ShortingArrow}
                >
                  <span>{headCell.label} &nbsp; </span>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          ))}
          <TableCell sx={{ verticalAlign: "bottom" }} align="right">
          </TableCell>

        </TableRow>
      </TableHead>



    </>
    );
  }






  const PatienTableView = ({ patientsData }: any) => {
    return (
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        aria-label="collapsible table"
        size={dense ? "small" : "medium"}
        className="scansTable"
      >
        <EnhancedTableHead
          // numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={patientsData.length}
        />

        <TableBody>
          {patientsData.length && patientsData.map((row: any) => {
            return <TableRow
              sx={{
                "& > *": {
                  borderBottom: "unset",
                  background: '',
                  padding: "16px 16px",
                },
                "&:hover": {
                  backgroundColor: "#DDEBED !important"
                }
              }}
              // onMouseOver={() => setTableIcons(true)}
              // onMouseLeave={() => setTableIcons(false)}
              onClick={() => {
                dispatch(setSelectedPatient({ id: row.patient_fhir_id }));
                navigate(`patientId?name=${row.patient_fhir_id}`, {
                  state: { id: row.patient_fhir_id },
                });
              }}

            >

              <TableCell component="th" scope="row" >
                <Tooltip title={row.name || "-"}>
                  <UserName style={{ fontSize: "0.875rem", maxWidth: "300px", color: '#000000de' }} >{row.name || "-"}</UserName>
                </Tooltip>
              </TableCell>
              <TableCell component="th" scope="row" >
              <Tooltip title={row.email || "-"}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "200px", color: '#000000de' }} >{row.email || "-"}</UserName>
              </Tooltip>
            </TableCell>

              <TableCell component="th" scope="row" onClick={() => {
              }}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >{row.gender || "-"}</UserName>
              </TableCell>
              <TableCell component="th" scope="row" onClick={() => {
              }}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >
                  {row.dob ? moment().diff(moment(row.dob, 'YYYY-MM-DD'), 'years') : 0} Years
                </UserName>
              </TableCell>
              <TableCell component="th" scope="row" onClick={() => {
              }}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >{row.phone_number || "-"}</UserName>
              </TableCell>

              <TableCell
                align="right"
              >
                {/* <Box display={'flex'}>  */}
                <IconButton aria-label="expand row" size="small">
                  <KeyboardArrowRight />
                </IconButton>
                {/* </Box> */}
              </TableCell>
            </TableRow>
          })}
        </TableBody>

      </Table>
    )
  }




  return (
    <>
      <Container>
        <HeaderWrapper>
          <HeaderLeft>
            <Header>
              {`Patients ${patientsData?.length ? "(" + patientsData?.length + ")" : ''}`}
            </Header>
          </HeaderLeft>
          {/* <HeaderRight> */}
        

          <Box display="flex" gap={2} width="100%">
            <SearchSection>
              <Tabs>
                <TabButton
                  onClick={() => handleTabChange("card")}
                  className={activeTab === "card" ? "active" : ""}
                >
                  <CardViewIcon active={activeTab === "card" ? true : false} />
                </TabButton>
                <TabButton
                  onClick={() => handleTabChange("table")}
                  className={activeTab === "table" ? "active" : ""}
                >
                  <TableViewIcon active={activeTab === "table" ? true : false} />

                </TabButton>
              </Tabs>

              </SearchSection>
              <SearchTempWrapper>
                <SearchBox
                  placeholderText="Search by name, email or mobile number"
                  searchParamText={searchTerm}
                  searchHandler={(textValue: any) =>
                    handleOnSearchChange(textValue)
                  }
                />
              </SearchTempWrapper>
              <>
              <AddTempWrapper  onClick={() => {
                    if (!searchParams.has("replace")) {
                      searchParams.delete("patient_fhir_id");
                      searchParams.append("addEditPatient", "true");
                      setSearchParams(searchParams);
                    }
                  }}>
                  <AddIcon src={IAddIconWhite}></AddIcon>
                  <AddText>Add New</AddText>
              </AddTempWrapper>
              </>
          {/* </HeaderRight> */}
          </Box>
        </HeaderWrapper>
        {isLoading ? (
          //shimmer effect
          <>
            {activeTab == "card" ?
            <Box sx={{ display: 'flex', flexWrap:'wrap', justifyContent: 'start',width:'100vw'}}>
            {Array.from(new Array(12)).map(() =>{
             return <Card sx={{ width: 345, m: 2 }}>
                <CardHeader
                  avatar={
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  }

                  title={
                    <Skeleton
                      animation="wave"
                      height={15}
                      width="70%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    <Skeleton animation="wave" height={15} width="40%" />
                  }
                />

                <CardContent>
                  <>
                    <Skeleton animation="wave" height={14} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={15} width="80%" />
                  </>

                </CardContent>
              </Card>
            })}
            </Box>
               :
              <Box >
                {Array.from(new Array(20)).map(() =>{
                return  <Skeleton animation="wave"  sx={{ bgcolor: 'grey.200' }} height={30} />
                })}
              </Box>}

          </>

        ) : (
          <>
            {patientsData?.length > 0 ? (
              <>
                {activeTab == "card" ? <PatientContainer>
                  {patientsDataCards.map((patient: any) => {
                    const { id, name, gender, phone_number, dob, patient_fhir_id, email } = patient;
                    const showGender = gender != null;
                    const showDob = dob != null && dob !== '';
                    return (
                      <PatientCard
                        key={id}
                        onClick={() => {
                          dispatch(setSelectedPatient({ id: patient_fhir_id }));
                          navigate(`patientId?name=${patient_fhir_id}`, {
                            state: { id: patient_fhir_id },
                          });
                        }}
                      >
                        <InnerContainer>
                          <ProfileSection>
                            <ProfileImage
                              src={IProfileImageSmall}
                              alt="ProfileImage"
                            />
                            <PatientInfo>
                              {name && <Name>{capitalizeFirstLetter(name)}</Name>}
                              {phone_number && (
                                <EmailItem>
                                  <EmailImage
                                    src={IPhoneIcon}
                                    alt="phone"
                                  ></EmailImage>
                                  <EmailText>{phone_number}</EmailText>
                                </EmailItem>
                              )}
                              {email && (
                                <EmailItem>
                                  <EmailImage
                                    src={IEmailIcon}
                                    alt="email"
                                  ></EmailImage>
                                  <EmailText>{email}</EmailText>
                                </EmailItem>
                              )}

                              {gender && (
                                <DetailItem>
                                  <GenderImage
                                    src={IGenderIcon}
                                    alt="Gender"
                                  ></GenderImage>
                                  <GenderText>
                                    {capitalizeFirstLetter(gender)}
                                  </GenderText>
                                </DetailItem>
                              )}
                              {dob && (
                                <DetailItem>
                                  <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                  <DobText>
                                    <DobDate>
                                      {moment(dob).format("DD-MMM-YYYY")}
                                    </DobDate>
                                    {/* <DobYears> ({moment().diff(moment(dob, 'YYYY-MM-DD'), 'years')} Years)</DobYears> */}
                                  </DobText>
                                </DetailItem>
                              )}
                            </PatientInfo>
                          </ProfileSection>
                          <EditContainer
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!searchParams.has("replace")) {
                                searchParams.delete("addEditPatient");
                                searchParams.delete("patient_fhir_id");
                                searchParams.append("addEditPatient", "true");
                                searchParams.append(
                                  "patient_fhir_id",
                                  patient["patient_fhir_id"]
                                );
                                setSearchParams(searchParams);
                              }
                            }}
                          >
                            <EditIcon src={editIcon} style={{ marginRight: "5px" }} />
                            <EditText>Edit</EditText>
                          </EditContainer>
                        </InnerContainer>
                      </PatientCard>
                    );
                  })}
                </PatientContainer>

                  :
                  <PatientContainer style={{ background: '#fff', justifyContent: 'flex-start', display: 'flex', padding: '23px' }}>
                    <PatienTableView patientsData={patientsSortedData} />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={patientsData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </PatientContainer>}
              </>
            ) : (
              <EmptyListMessage>No patients data found</EmptyListMessage>
            )}
          </>

        )}
      </Container>
    </>
  );
};

export default Patients;
