import React, { FC } from "react";
import { Card, CardContent, Avatar, Typography, Box } from "@mui/material";

interface ExistingRecipientProps {
  name?: string;
  mobile?: number;
  email?: string;
}

const ExistingRecipient: FC<ExistingRecipientProps> = ({ name, mobile, email }) => {
  return (
    <Card
      sx={{
        display: "flex",
        minHeight: 100,
        boxShadow: 3,
        borderRadius: 2,
        transition: "border-color 0.3s ease-in-out",
        padding: 1,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: 2,
        }}
      >
        <Avatar src="/broken-image.jpg" sx={{ width: 40, height: 40 }} />
        <Box display="flex" flexDirection="column" ml={2} mt={{ xs: 2, sm: 0 }}>
          <Typography
            component="div"
            sx={{ color: "#0099CC", wordWrap: "break-word" }}
            variant="body2"
          >
            {name}
          </Typography>
          <Typography variant="body1">{mobile}</Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{ wordWrap: "break-word" }}
          >
            {email}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExistingRecipient;
