import { FunctionComponent } from "react";
import styled from "styled-components";

interface NoDataProps {
  message?: string;
}

const NoDataDiv = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #808080;
`;

const NoData: FunctionComponent<NoDataProps> = ({ message }) => {
  return <NoDataDiv>{message ? message : "No Data"}</NoDataDiv>;
};

export default NoData;
