import React, { useState, useEffect, useRef } from 'react';
import { ParentContainer, DragFilesText, UploadMainContainer, AddDocsContainer, AddScansContainer, PlusText, AddText, BackButtonContainer, BackArrowImg, BackText, NextButton } from './style.components';
import UppyUploader from '../../common/UppyUploadBoxScans/UppyUploadBox';
import UppyUploaderDoc from '../../common/UppyUploadBox/UppyUploadBox';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUploadRecordsAction } from '../../../store/actions/records.action';
import { setRecords, setUppyResponse } from '../../../store/slice';
import Breadcrumb from '../../common/Breadcrumb/Breadcrumb';
import SimpleLoader from '../../common/Loaders/SimpleLoader';
import SnackBar from '../../common/SnackBar/SnackBar';
import { useCookies } from "react-cookie";

const AddRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();
  const [fileTypes, setFileTypes] = useState([]);
  const [showUploader, setShowUploader] = useState(false);
  const [uppyResponse, setUppyLibResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [recordType, setRecordType] = useState("");
  const selectedPatientId = useSelector((state) => state.patient.selectedPatientId);
  const [selectedCountry, setSelectedCountry] = useState(""); 

  useEffect(() => {
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
      }
    } catch (error) {
        console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  const breadCrumbLinks = [
    {
      label: "Health Data",
      href: `/patients/patientId?name=${selectedPatientId}`,
    },
    {
      label: "Add Records",
    },
  ];

  const handleAddDocsClick = () => {
    setFileTypes(['.pdf', '.jpg', '.jpeg', '.png', '.rtf', '.doc']);
    setShowUploader(true);
    setRecordType("docs");
  };

  const handleAddScansClick = () => {
    setFileTypes(['.dcm', '.zip']);
    setShowUploader(true);
    setRecordType("scans");
  };

  const handleNextButton = async () => {
    setLoading(true);
    try {
      if(recordType === 'docs') {
        dispatch(setUppyResponse(uppyResponse));
        const response = await dispatch(getUploadRecordsAction(uppyResponse?.assemblyID));
        dispatch(setRecords(response.payload));
        navigate('/patient-records/add-records/send-records', { state: {records: response?.payload, uppyResponse: uppyResponse, recordType: recordType}});
      }
      else {
        navigate('/patient-records/add-records/send-records', { state: {records: null, uppyResponse: uppyResponse, recordType: recordType}});
      }
    } catch (error) {
      console.error("Error sending records:", error);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      <ParentContainer>
        <Breadcrumb links={breadCrumbLinks} />
        <UploadMainContainer>
            {!showUploader && (
                <>
                    <AddDocsContainer onClick={handleAddDocsClick}>
                        <PlusText>+</PlusText>
                        <AddText>Documents</AddText>
                    </AddDocsContainer>
                    <AddScansContainer onClick={handleAddScansClick}>
                        <PlusText>+</PlusText>
                        <AddText>Scans/Dicoms</AddText>
                    </AddScansContainer>
                </>
            )}

            {showUploader && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1' }}>
                    <div style={{ width: '100%' }}>
                        {recordType === "docs" && (
                            <UppyUploaderDoc onUploadComplete={setUppyLibResponse} allowedFileTypes={fileTypes} selectedCountry={selectedCountry} />
                        )}
                        {recordType === "scans" && (
                            <UppyUploader onUploadComplete={setUppyLibResponse} allowedFileTypes={fileTypes} />
                        )}
                    </div>

                    {uppyResponse?.uploadedFiles && (
                        <div style={{ width: '100%' }}>      
                            <NextButton onClick={handleNextButton}>
                                {loading ? <SimpleLoader size={24} /> : "Next"}
                            </NextButton> 
                        </div>
                    )}
                </div>
            )}
        </UploadMainContainer>
      </ParentContainer>
      <SnackBar />
    </>
  );
};

export default AddRecords;
