import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL, DEVICE } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import { setShowStatus } from "../../../store/slice/statusSlice";
import { useSelector, useDispatch } from "react-redux";
import { incrementCount } from "../../../store/slice/reportsReloadSlice";
import closeIcon from "../../../assets/close-icon.png"
import { useSearchParams } from "react-router-dom";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface DoctorAddEditProps {
  open: boolean;
  testId: string | null;
  testName?: string | null | undefined;
  testPrice?: string | null | undefined;
  closeHandler: () => void;
}

const AddEditDoctor = ({
  open,
  closeHandler,
  testId,
  testName,
  testPrice,
}: DoctorAddEditProps) => {
  const maxNameLength = 35;
  const countryCode = "+91";
  const namePrefix = "Dr. ";
  
  let [searchParams, setSearchParams] = useSearchParams();

  const [cookies, setCookie, removeCookie] = useCookies();
  const [notValidInput, setNotValidInput] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [doctorName, setDoctorName] = useState<string>("");
  const [doctorNumber, setDoctorNumber] = useState<number | null>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (testName != doctorName || testPrice != doctorNumber?.toString()) {
      if (doctorName && doctorNumber) {
        if (
          // doctorNumber.toString().match(/^[0-9]{10}$/) &&
          doctorNumber.toString().match(/^\d+$/) &&
          doctorName.length <= 35
        ) {
          setNotValidInput(false);
        } else {
          setNotValidInput(true);
        }
      } else {
        setNotValidInput(true);
      }
    } else {
      setNotValidInput(true);
    }
  }, [doctorName, doctorNumber]);

  const handleSave = () => {
    if (notValidInput) {
      return;
    }
    setIsLoading(true);

    var jsonData = {
      name: namePrefix + doctorName,
      contact_number: countryCode + doctorNumber + "",
    };

    axios
      .post(`${API_URL}/doctor`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);
        // dispatch(incrementCount());
        closeHandler();
        if(getSearchParam("fromSendReport")){
          // searchParams.delete("fromSendReport");
          // setSearchParams(searchParams);
        } else {
          window.location.reload();
        }
        // window.location.reload();
        dispatch(
          setShowStatus({
            message: "Docter added successfully",
            severity: "success",
          })
        );
        // closeHandler();
      })
      .catch((err) => {
        // Handle success - report not sent, and something else
        let message = "Doctor already exist";
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          message = "Failure. " + err.response.data.message;
        }
        setIsLoading(false);
        dispatch(
          setShowStatus({
            message: message,
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // sx={{
      //   padding: "100px",
      //   [`@media ${DEVICE.mobileL}`]: {
      //     padding: "0px",
      //   },
      // }}
    >
      <DialogTitle
        sx={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        Add Doctor
      </DialogTitle>
      <DialogContent
        sx={{
          justifyContent: "flex-start",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeHandler}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <img height={40} width={40} src={closeIcon} style={{}} />
        </IconButton>
        <div style={{}}>
          {!isLoading ? (
            <TestForm>
              <VerticalSep size={10} />
              <TextFieldLabel>Mobile No. *</TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <div style={{ marginRight: "10px" }}>{countryCode}</div>
                <Input
                  type="number"
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                    flexGrow: 1,
                  }}
                  onChange={(event) => {
                    setDoctorNumber(parseInt(event.target.value));
                  }}
                  value={doctorNumber}
                  placeholder={""}
                />
              </FormControl>
              <VerticalSep size={20} />
              <TextFieldLabel>
                Doctor Name{" "}
                <span style={{ fontSize: "12px" }}>
                  (max {maxNameLength} characters)
                </span>{" "}
                *
              </TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <div style={{ marginRight: "10px" }}>{namePrefix}</div>
                <Input
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                    flexGrow: 1,
                  }}
                  onChange={(event) => {
                    setDoctorName(event.target.value);
                  }}
                  value={doctorName}
                  placeholder={""}
                />
              </FormControl>
              <VerticalSep size={10} />
            </TestForm>
          ) : (
            <SimpleLoader height="100%" size={20} />
          )}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <Button
          onClick={handleSave}
          disabled={notValidInput}
          sx={{ color: "#FFF" }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default AddEditDoctor;
