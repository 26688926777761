import { NavigateFunction, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Pagination } from "@mui/material";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UploadFiles from "../components/common/UploadFiles/UploadFiles";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import {
  API_URL,
  COLOR_GREY,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  COLOR_VARIANT,
  DEVICE,
} from "../utilities/constants";
import doctorsProfileImage from "../assets/doctors-profile-image.svg";
import SendReport from "../components/data/SendReport/SendReport";
import NoData from "../components/common/UserMessages/NoData";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { capitalizeFirstLetter } from "../utilities/helper";
import backArrowIcon from "../assets/back-arrow.svg";

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : COLOR_SECONDARY};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
    margin-top: 10px;
    text-align: left;
  }
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
  }
`;

const ProfileSection = styled.div`
  flex-grow: 4;
  @media ${DEVICE.mobileL} {
    margin-bottom: 20px;
  }
`;

const ProfileImage = styled.img`
  @media ${DEVICE.mobileL} {
    width: 80px;
  }
`;

const BackArrow = styled.img`
  width: 6px;
  @media ${DEVICE.mobileL} {
    width: 6px;
  }
`;

const PhoneNumber = styled.div`
  color: #000000;
  font-size: 18px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
  }
`;

const UserName = styled.div`
font-size: 24px;
font-weight: bold;
margin-bottom: 20px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
  }
`;

const SearchSection = styled.div`
  flex-basis: 50%;
`;

const TableContainer = styled.div`
  @media ${DEVICE.laptop} {
    display: none;
  }
`;
const TableContainerSmall = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0px 0px 0px;
`;

const PaginationWrapperSmall = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
`;

const styleTTextSmall = { color: "#808080", fontSize: "12px" };
const styleTATextSmall = {
  color: "#0099CC",
  fontSize: "14px",
  cursor: "pointer",
};

const tableColumnsSmall = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "stack",
    columns: [
      {
        type: "text",
        key: "fileName",
        align: "left",
        styles: styleTTextSmall,
        maxChars: 18,
      },
      {
        type: "text",
        key: "uploadDay",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "uploadTime",
        align: "left",
        styles: styleTTextSmall,
      },
      {
        type: "text",
        key: "labUserName",
        prefix: "by",
        styles: styleTTextSmall,
      },
    ],
  },
  {
    type: "stack",
    columns: [
      {
        type: "actionButton",
        subType: "replace",
        handler: () => {},
        small: true,
      },
      {
        type: "actionButton",
        subType: "view",
        handler: () => {},
        small: true,
      },
      {
        type: "actionButton",
        subType: "whatsapp",
        handler: () => {},
        small: true,
      },
    ],
  },
];

const tableColumns = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "text",
    key: "file_name",
  },
  {
    type: "text",
    key: "patientCount",
  },
  {
    type: "text",
    key: "doctorCount",
  },
  {
    type: "patient",
  },
  {
    type: "text",
    key: "uploadDate",
    align: "left",
  },
  {
    type: "text",
    key: "labUserName",
    prefix: "Sent by",
  },
  {
    type: "actionButton",
    subType: "singleReportDetails",
    handler: () => { },
  },
  {
    type: "actionButton",
    subType: "view",
    handler: () => { },
  },
];

const tableData = [
  {
    reportId: 1,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
  {
    reportId: 2,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
  {
    reportId: 3,
    thumbnailUrl:
      "https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU",
    phone: "+919876543210",
    reportName: "Cbc-blood-report.pdf",
    date: "12-Sep-2022",
    dateTime: "12-Sep-2022",
    sentBy: "Sent by Lokanath",
  },
];

const SingleDoctor = () => {
  let { doctorPhone } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const reportsPerPage = 5;

  const [cookies, setCookie, removeCookie] = useCookies();
  const [reportsData, setReportsData] = useState<any[]>([]);
  const [fetchCount, setFetchCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reloadCount = useSelector((state: any) => state.reportsReload.count);

  // let pageNumber = searchParams.get("page");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);

  const totalPages = Math.ceil(totalCount / reportsPerPage);

  const [isInnerLoading, setIsInnerLoading] = useState<boolean>(false);
  const [pageToken, setPageToken] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [patientProfileData, setPatientProfileData] = useState<any>()
  const [isDoctorDataLoading, setIsDoctorDataLoading] = useState(true)

  useEffect(() => {
    fetchReportsData();
    fetchDoctorData();
  }, [fetchCount, reloadCount, currentPage]);

  useEffect(() => {
    if (pageNumber) {
      // setCurrentPage(parseInt(pageNumber));
      setCurrentPage(pageNumber);
    }
  }, [pageNumber]);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const convertDataToTableFormat = (inputData: any[]) => {
    const outputData = inputData.map((obj, i) => {
      return {
        key: obj["id"],
        appUserId: obj["id"] || "",
        fileName: obj["file_name"],
        patientCount: obj["patient_count"],
        doctorCount: obj["doctors_count"],
        uploadDate: moment(obj["upload_date"])
          .local()
          .format("DD-MMM-YYYY   hh:mm a"),
        uploadDay: moment(obj["upload_date"]).local().format("DD-MMM-YYYY"),
        uploadTime: moment(obj["upload_date"]).local().format("hh:mm a"),
      };
    });
    return outputData;
  };

  const fetchReportsData = () => {
    setIsLoading(true);
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/reports?doctor_id=${getSearchParam("name")}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response) => {
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.data 
        ) {
          const newReportsData = convertDataToTableFormat(
            response.data.data
          );
          setReportsData([...newReportsData]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchDoctorData = () => {
    setIsDoctorDataLoading(true)
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/${getSearchParam("name")}/profile`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (
          response.data &&
          response.data?.status == "success" &&
          response.data?.data
        ) {
          setPatientProfileData(response.data?.data);
        } else {
          console.log("Something went wrong!")
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setIsDoctorDataLoading(false)
      })
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }} onClick={() => {
        navigate('/doctors')
      }}>
        <BackArrow src={backArrowIcon} />
        <p style={{ color: '#7ABA56', fontSize: 14, marginLeft: 5 }}>Back</p>
      </div>
      <TopRow>
        <ProfileSection>
          <Container>
            <HeaderRow>
              <SectionHeader>Doctor's Profile</SectionHeader>
            </HeaderRow>
            <VerticalSep size={20} />
            {isDoctorDataLoading ? 
            <SimpleLoader />:
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <ProfileImage src={doctorsProfileImage} />
              </div>
              <HorizontalSep size={30} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <UserName>{capitalizeFirstLetter(patientProfileData?.name)}</UserName>
                <PhoneNumber>{patientProfileData?.phone_number}</PhoneNumber>
              </div>
            </div>}
          </Container>
        </ProfileSection>
      </TopRow>

      <Container>
        <HeaderRow>
          <SectionHeader>Reports</SectionHeader>
          <SearchSection>
            <HorizontalSep size={20} />
          </SearchSection>
        </HeaderRow>
        <TableContainer>
          {!isLoading ? (
            reportsData && reportsData.length ? (
              <>
                <CustomTable
                  tableData={reportsData}
                  tableColumns={tableColumns}
                  styles={{ marginTop: 30, maxHeight: "60vh" }}
                  isInnerLoading={isInnerLoading}
                  // scrollHandler={handleNextFetch}
                  // hasNext={pageToken ? true : false}
                />
              </>
            ) : (
              <NoData message={"No Reports"} />
            )
          ) : (
            <SimpleLoader />
          )}
        </TableContainer>
        <TableContainerSmall>
          {!isLoading ? (
            reportsData && reportsData.length ? (
                <CustomTable
                  tableData={reportsData}
                  tableColumns={tableColumnsSmall}
                  styles={{ marginTop: 30, maxHeight: "55vh" }}
                  isInnerLoading={isInnerLoading}
                  // scrollHandler={handleNextFetch}
                  // hasNext={pageToken ? true : false}
                />
            ) : (
              <NoData message={"No Reports"} />
            )
          ) : (
            <SimpleLoader />
          )}
        </TableContainerSmall>
      </Container>
      <VerticalSep size={20} />
    </>
  );
};

export default SingleDoctor;
