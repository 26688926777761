import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL, DEVICE } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  testName?: string | null | undefined;
  testPrice?: string | null | undefined;
  closeHandler: () => void;
}

const EditAddTest = ({
  open,
  closeHandler,
  testId,
  testName,
  testPrice,
}: EditTestProps) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [notValidInput, setNotValidInput] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testNameValue, setTestNameValue] = useState<string>(
    testName ? testName : ""
  );
  const [testPriceValue, setTestPriceValue] = useState<number | null>(
    testPrice ? parseInt(testPrice) : null
  );

  useEffect(() => {
    if (testName != testNameValue || testPrice != testPriceValue?.toString()) {
      if (testNameValue && testPriceValue) {
        setNotValidInput(false);
      } else {
        setNotValidInput(true);
      }
    } else {
      setNotValidInput(true);
    }
  }, [testNameValue, testPriceValue]);

  const handleSave = () => {
    return;
    if (!testId) {
      return;
    }
    if (!notValidInput) {
      return;
    }
    setIsLoading(true);
    var formData = new FormData();
    if (testNameValue) {
      formData.append("test_name", testNameValue);
    }
    if (testPriceValue) {
      formData.append("test_price", testPriceValue + "");
    }

    axios
      .post(`${API_URL}/lab_portal/reports/replace`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        // setErrorMessage("Something went wrong. Please try again.");
        // setMobileNumber("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // sx={{
      //   padding: "100px",
      //   [`@media ${DEVICE.mobileL}`]: {
      //     padding: "0px",
      //   },
      // }}
    >
      <DialogTitle
        sx={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        Add / Edit Test
      </DialogTitle>
      <DialogContent
        sx={{
          justifyContent: "flex-start",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <div style={{}}>
          {!isLoading ? (
            <TestForm>
              <VerticalSep size={10} />
              <TextFieldLabel>Test Name *</TextFieldLabel>
              <FormControl variant="outlined">
                <Input
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                  }}
                  onChange={(event) => {
                    setTestNameValue(event.target.value);
                  }}
                  value={testNameValue}
                  placeholder={""}
                />
              </FormControl>
              <VerticalSep size={20} />
              <TextFieldLabel>Price *</TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <div style={{ marginRight: "10px" }}>₹</div>
                <Input
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                    flexGrow: 1,
                  }}
                  type={"number"}
                  onChange={(event) => {
                    setTestPriceValue(parseInt(event.target.value));
                  }}
                  value={testPriceValue}
                  placeholder={""}
                />
              </FormControl>
              <VerticalSep size={10} />
            </TestForm>
          ) : (
            <SimpleLoader height="100%" size={20} />
          )}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <Button
          onClick={handleSave}
          disabled={notValidInput}
          sx={{ color: "#FFF" }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default EditAddTest;
